import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useGlobalContext } from '../contexts/GlobalContext';

const ShareProduct = ({ inventoryDetails, product_name, disc, folder, image, link }) => {
    const { img_url } = useGlobalContext();
    const shareUrl = link;
    const title = product_name || 'Default Title';
    const description = disc.replace(/<[^>]+>/g, '').slice(0, 160);
    const imageUrl = image;

    useEffect(() => {
        document.title = title;

        const metaTags = [
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: imageUrl },
            { property: 'og:url', content: shareUrl },
            { name: 'description', content: description },
            { name: 'twitter:image', content: imageUrl },
        ];

        metaTags.forEach(tag => {
            let metaTag = document.querySelector(`meta[property="${tag.property}"], meta[name="${tag.name}"]`);
            if (!metaTag) {
                metaTag = document.createElement('meta');
                if (tag.property) {
                    metaTag.setAttribute('property', tag.property);
                }
                if (tag.name) {
                    metaTag.setAttribute('name', tag.name);
                }
                document.head.appendChild(metaTag);
            }
            metaTag.setAttribute('content', tag.content);
        });

        // Optionally handle apple-touch-icon or favicon
        let appleIcon = document.querySelector('link[rel="apple-touch-icon"]');
        if (!appleIcon) {
            appleIcon = document.createElement('link');
            appleIcon.setAttribute('rel', 'apple-touch-icon');
            document.head.appendChild(appleIcon);
        }
        appleIcon.setAttribute('href', imageUrl);

    }, [title, description, imageUrl, shareUrl]);

    return (
        <Helmet>
            {/* Helmet can also be used here for SEO-related tags */}
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:url" content={shareUrl} />
            <meta name="description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
        </Helmet>
    );
}

export default ShareProduct;
// import React, { useEffect } from 'react'
// import { Helmet } from 'react-helmet';
// import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
// import { useGlobalContext } from '../contexts/GlobalContext';

// const ShareProduct = ({ inventoryDetails, product_name, disc, folder, image, link }) => {
//     const { img_url } = useGlobalContext()
//     // const { folder, image, link } = inventoryDetails;
//     const shareUrl = link;
//     const title = product_name || 'Default Title';
//     const description = disc.replace(/<[^>]+>/g, '').slice(0, 160);
//     const imageUrl = image; 
//     useEffect(() => {
//         document.title = title;

//         const metaTags = [
//             { property: 'og:title', content: title },
//             { property: 'og:description', content: description },
//             { property: 'og:image', content: imageUrl },
//             { property: 'og:url', content: shareUrl },
//             { name: 'description', content: description },
//             { name: 'twitter:image', content: imageUrl },
//             // { property: 'og:image:width', content: '1200' },
//             // { property: 'og:image:height', content: '630' },
//         ];

//         metaTags.forEach(tag => {
//             let metaTag = document.querySelector(`meta[property="${tag.property}"], meta[name="${tag.name}"]`);
//             if (!metaTag) {
//                 metaTag = document.createElement('meta');
//                 if (tag.property) {
//                     metaTag.setAttribute('property', tag.property);
//                 }
//                 if (tag.name) {
//                     metaTag.setAttribute('name', tag.name);
//                 }
//                 document.head.appendChild(metaTag);
//             }
//             metaTag.setAttribute('content', tag.content);
//         });


//         let appleIcon = document.querySelector('link[rel="apple-touch-icon"]');
//         if (!appleIcon) {
//             appleIcon = document.createElement('link');
//             appleIcon.setAttribute('rel', 'apple-touch-icon');
//             document.head.appendChild(appleIcon);
//         }
//         appleIcon.setAttribute('href', imageUrl);  
//         // Update or create favicon dynamically
//         // let favicon = document.querySelector('link[rel="icon"]');
//         // if (!favicon) {
//         //     favicon = document.createElement('link');
//         //     favicon.setAttribute('rel', 'icon');
//         //     document.head.appendChild(favicon);
//         // }
//         // favicon.setAttribute('href', imageUrl);  

//     }, [title, description, imageUrl, shareUrl]);

//     return (
//         <>
//             {/* <Helmet>
//                 <title>{title}</title>
//                 <meta property="og:title" content={title} />
//                 <meta property="og:description" content={description} />
//                 <meta property="og:image" content={imageUrl} />
//                 <meta property="og:url" content={shareUrl} />
//                 <link rel="apple-touch-icon" href={imageUrl} />
//                 <meta name="description" content={description} />
//                 <meta property="og:image:width" content={"1200"} />
//                 <meta property="og:image:height" content={630} />"1200";  // Specify width
//                 const imageHeight = "630"
//             </Helmet> */}
//             {/* <FacebookShareButton
//                 title={inventoryDetails?.product_name}
//                 url={`https://evyapari.com/product/${inventoryDetails.id}`}
//                 quote={'Check this out!'}
//                 hashtag={'#portfolio'}
//             >
//                 <FacebookIcon size={40} round={true} />
//             </FacebookShareButton>

//             <WhatsappShareButton
//                 url={`https://evyapari.com/product/${inventoryDetails.id}`}
//                 title={inventoryDetails?.product_name}
//             >
//                 <WhatsappIcon size={40} round={true} />
//             </WhatsappShareButton> */}


//         </>
//     )
// }

// export default ShareProduct
import React from 'react'
import Header from '../layout/Header'
import Banner from '../components/Banner'
import Footer from '../layout/Footer'
import Cart from '../components/Cart'
const CartPage = () => {
    return (
        <>
            <Header />
            <div className="mt-5 pt-5 space-top">

                <Cart />
            </div>

        </>
    )
}

export default CartPage
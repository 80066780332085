import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../layout/Header'
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import loginImage from '../assets/images/login.png';

const ForgotPassword = () => {
  const { api_url, setIsLoading } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [passErrors, setPassErrors] = useState({});
  const [passData, setPassData] = useState({
    phone_no: '',
  });

  const handleChange = (e) => {
    setPassData({ ...passData, [e.target.name]: e.target.value })
  }

  const Validation = () => {
    let errors = {};
    var emailPhoneRegex = /^(?:\d{10}|\w+.+@\w+\.\w{2,3})$/

    if (!passData.phone_no) {
      errors.phone_no = 'Please fill the required';
    } else if (!emailPhoneRegex.test(passData.phone_no)) {
      errors.phone_no = 'Wrong Formate'
    }

    return errors;
  }

  var emailPhoneRegex = /^(?:\d{10}|\w+.+@\w+\.\w{2,3})$/

  useEffect(() => {
    if (emailPhoneRegex.test(passData.phone_no)) {
      setPassErrors({ ...passErrors, phone_no: '' })
    }
  }, [passData.phone_no]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = Validation();
    setPassErrors(errors);

    if (Object.keys(errors).length === 0) {
      const data = { phone_no: passData.phone_no }
      setIsLoading(true)
      axios.post(`${api_url}/sendPasswordOtp`, data)
        .then((res) => {
          if (res.data.success === 1) {
            setIsLoading(false)
            toast.success(`${res.data.message}`, {
              theme: 'colored',
              autoClose: 1500,
            })
            let timer = setTimeout(() => {
              navigate('/otpverification', { state: { user_id: res.data.data.user_id, phone_no: passData.phone_no, otp: res.data.data.otp, prevPath: location.pathname } });
            }, 2000);
            return () => clearTimeout(timer);
          }
          else {
            setIsLoading(false)
            toast.error(`${res.data.message} `, {
              theme: 'colored',
              autoClose: 1500,
            })
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error('Axios Error', {
            theme: 'colored',
            autoClose: 1500,
          })
          console.log(err)
        })
    }
  }

  return (
    <>
      <Header />
      <main id='main' className='forgotmain mt-5 pt-5' >
        <main id='main' className=''>
          <section id="login" className='h-100'>
            <div className="container">
              <div className="row g-4 mt-md-5">
                <div className="col-md pt-3">
                  <img src={loginImage} alt='login' className="img-fluid d-none d-md-block" />
                </div>
                <div className="col-md">
                  <div className="border border-1 border-dark rounded-2 p-2 shadow-md user">
                    <h3 className="text-center pt-3">Forget Password</h3>
                    <form className="p-4">
                      <div className="form-group">
                        <label htmlFor="email">Mobile No.</label>
                        <input type="tel" onChange={handleChange} name='phone_no' value={passData.phone_no} id="currentPassword" className="form-control" maxLength={10} placeholder="+91 7888777887" />
                        <div className="text-danger">{passErrors.phone_no}</div>
                      </div>
                      <button onClick={(e) => handleSubmit(e)} className="mt-4 btn btn-danger d-block w-100">Get OTP</button>
                      <Link to='/login' className="mt-2 btn btn-white border-2 border-danger text-danger d-block w-100">Go Back</Link>
                      <h6 className="mt-3 mb-1">Create an account with <span className="fw-bold">e-Vyapari </span>
                        <span>
                          <Link to={'/signup'} className=" text-danger fw-bold text-decoration-none">SignUp</Link></span></h6>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </main>

      </main>


      {/* <Copyright /> */}
      <ToastContainer />
    </>
  )
}

export default ForgotPassword
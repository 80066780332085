import React, { useEffect } from 'react'
import Header from '../layout/Header'
import Banner from '../components/Banner'
import Footer from '../layout/Footer'
import { useGlobalContext } from '../contexts/GlobalContext'
import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ShareProduct from '../components/ShareProduct'

const Home = () => {
    const { img_url, schools, getSchools, convertToPlain, setShowSchoolModal, getAllInventory, allInventory, setIsAuthenticated } = useGlobalContext();
    const [searchParams, setSearchParams] = useSearchParams();
    // const shareUrl = `http://user.evyapari.com`;
    // const title = "E-Vyapari";
    // const description =
    //     "Discover a wide range of school books, bags, stationery, and accessories tailored for your school. Shop easily with fast delivery and the best prices at e-Vyapari.";
    // const imageUrl = `https://evyapari.com/logo193.png`; // Use a dynamic image URL if needed
    // console.log(folder, image, link, shareUrl, title, imageUrl, description, inventoryDetails)
    // useEffect(() => {
    //     // Set OG tags dynamically
    //     const metaTitle = document.createElement("meta");
    //     metaTitle.setAttribute("property", "og:title");
    //     metaTitle.content = title;

    //     const metaDescription = document.createElement("meta");
    //     metaDescription.setAttribute("property", "og:description");
    //     metaDescription.content = description;

    //     const metaImage = document.createElement("meta");
    //     metaImage.setAttribute("property", "og:image");
    //     metaImage.content = imageUrl;

    //     const metaUrl = document.createElement("meta");
    //     metaUrl.setAttribute("property", "og:url");
    //     metaUrl.content = shareUrl;

    //     document.head.appendChild(metaTitle);
    //     document.head.appendChild(metaDescription);
    //     document.head.appendChild(metaImage);
    //     document.head.appendChild(metaUrl);

    //     return () => {
    //         document.head.removeChild(metaTitle);
    //         document.head.removeChild(metaDescription);
    //         document.head.removeChild(metaImage);
    //         document.head.removeChild(metaUrl);
    //     };
    // }, [title, description, imageUrl, shareUrl]);
    useEffect(() => {
        const schoolCode = searchParams.get('school_code');
        // console.log('school_code:', schoolCode); 
        if (!sessionStorage.getItem('user_id') && !sessionStorage.getItem('guest_id')) {
            if ((searchParams && searchParams.get('token') && searchParams.get('user_id') && searchParams.get('username')) || schoolCode) {
                sessionStorage.setItem('token', searchParams.get('token'));
                sessionStorage.setItem('username', searchParams.get('username'));
                sessionStorage.removeItem('guest_id');
                sessionStorage.setItem('user_id', searchParams.get('user_id'));
    
                if (schoolCode !== null && schoolCode !== '0' && schoolCode !== '') {
                    // console.log('Setting school_code in sessionStorage:', schoolCode);  
                    sessionStorage.setItem('school_code', schoolCode);
                } else {
                    // console.log('school_code is invalid or empty:', schoolCode);  
                }
    
                setIsAuthenticated(true);
            } else {
                const timestamp = Date.now();
                const randomPart = Math.floor(1000000 + Math.random() * 9000000);
                const guestId = (timestamp % 9000000) + randomPart % 1000000;
                sessionStorage.setItem('guest_id', guestId);
                // console.log('Setting guest_id:', guestId);  
            }
        } else if (sessionStorage.getItem('guest_id')) {
            if ((searchParams && searchParams.get('token') && searchParams.get('user_id') && searchParams.get('username')) || schoolCode) {
                sessionStorage.setItem('token', searchParams.get('token'));
                sessionStorage.setItem('username', searchParams.get('username'));
                sessionStorage.removeItem('guest_id');
                sessionStorage.setItem('user_id', searchParams.get('user_id'));
    
                if (schoolCode !== null && schoolCode !== '0' && schoolCode !== '') {
                    // console.log('Setting school_code in sessionStorage:', schoolCode);  
                    sessionStorage.setItem('school_code', schoolCode);
                }
    
                setIsAuthenticated(true);
            }
        } else if (sessionStorage.getItem('user_id') && !sessionStorage.getItem('guest_id') && !sessionStorage.getItem('school_code')) {

            if (schoolCode !== null && schoolCode !== '0' && schoolCode !== '') {
                // console.log('Setting school_code in sessionStorage:', schoolCode); 
                sessionStorage.setItem('school_code', schoolCode);
            }
        }
    }, [searchParams]);  // Dependency array so it runs when searchParams changes
    

    return (
        <>
            {/* <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet> */}
            <Header />
            <div className='mt-5 pt-5'>

                <Banner />

            </div>
            <Footer />
            {/* <ShareProduct product_name={title} link={shareUrl} disc={description} image={imageUrl} /> */}
        </>
    )
}

export default Home
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { PassValidation } from './FormValidation';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import Header from '../layout/Header';
import Loading from '../components/loading/Loading'
import ProfileSidebar from '../components/ProfileSidebar';
import logo from '../assets/images/main-logo.svg';


const ChangePassword = () => {

  const { isLoading, setIsLoading, api_url, userId } = useGlobalContext();
  const navigate = useNavigate();
  const [passErrors, setPassErrors] = useState({});
  const [passData, setPassData] = useState({
    "user_id": userId,
    "current_password": '',
    "new_password": '',
    "cpassword": ''
  });

  // Show and Hide password
  const [passShow1, setPassShow1] = useState(true);
  const [passShow2, setPassShow2] = useState(true);
  const [passShow3, setPassShow3] = useState(true);
  const [type1, setType1] = useState('password')
  const [type2, setType2] = useState('password')
  const [type3, setType3] = useState('password')
  const togglePassword = (flag) => {
    if (flag === 1 && type1 === 'password') {
      setType1('text');
      setPassShow1(!passShow1)
    } else if (flag === 1 && type1 === 'text') {
      setType1('password');
      setPassShow1(!passShow1)
    }

    if (flag === 2 && type2 === 'password') {
      setType2('text');
      setPassShow2(!passShow2)
    } else if (flag === 2 && type2 === 'text') {
      setType2('password');
      setPassShow2(!passShow2)
    }

    if (flag === 3 && type3 === 'password') {
      setType3('text');
      setPassShow3(!passShow3)
    } else if (flag === 3 && type3 === 'text') {
      setType3('password');
      setPassShow3(!passShow3)
    }
  }

  const handleChange = (e) => {
    setPassData({ ...passData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = PassValidation(passData);
    setPassErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsLoading(true)
      axios.post(`${api_url}/changePassword`, passData)
        .then(res => {
          if (res.data.success === 1) {
            setIsLoading(false)
            toast.success(res.data.message, {
              theme: 'colored',
              autoClose: 2000,
            });
            let timer = setTimeout(() => {
              // sessionStorage.removeItem('token');
              navigate(-1);
            }, 3000);
            return () => clearTimeout(timer);
          } else {
            setIsLoading(false);
            setPassData({
              ...passData,
              "current_password": '',
              "new_password": '',
            })
            document.getElementById('cpassword').value = '';
            toast.error(res.data.message, {
              autoClose: 2000,
            });
          }
        })
        .catch(err => {
          console.log(err);
          toast.error('Axios Error', {
            autoClose: 2000,
          });
          setIsLoading(false);
        })
    }
  }

  return (
    <>
      {isLoading ? <Loading /> : null}
      <Header />
      <main id='main' className='mt-5 pt-5 space-top'>
        <div className="eVyapari-content w-100">
          <div className="text-center  mb-2 d-block d-md-none">
            <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
          </div>
        </div>
        <section id="change-password" className="pb-5 clearfix">
          <div className="container">
            <ProfileSidebar />
            {/*--------------Change Password-form---------*/}
            <div className="float-md-end bill-details w-75">
              <div className="float-end">
                <div className=" bg-white border border-1 p-4 rounded-4 border border-dark shadow-btm">
                  <div className="d-flex justify-content-between bg-white">
                    <div><h6 className="font-cart fw-bold">Change Password</h6></div>
                  </div>
                  <form className="bg-white" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-1">
                          <label htmlFor="cu-password" className="form-label text-secondary">Current Password</label>
                          <div className="input-group  ">
                            <input type={type1} id="cu-password" onChange={handleChange} name='current_password' value={passData.current_password} placeholder="Must have atleast 6 characters" className="form-control text-black border border-end-0" />

                            <span className="input-group-text bg-white cursor-pointer " onClick={() => togglePassword(1)}>
                              {passShow1 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}
                            </span>
                          </div>
                          <div className="text-danger">{passErrors.current_password ? passErrors.current_password : <span>&nbsp;</span>}</div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-1">
                          <label htmlFor="n-password" className="form-label text-secondary">New Password</label>
                          <div className="input-group  ">
                            <input type={type2} id="n-password" onChange={handleChange} name='new_password' value={passData.new_password} placeholder="Must have atleast 6 characters" className="form-control text-black border border-end-0" />
                            <span className="input-group-text bg-white cursor-pointer " onClick={() => togglePassword(2)}>
                              {passShow2 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}
                            </span>
                          </div>
                          <div className="text-danger">{passErrors.new_password ? passErrors.new_password : <span>&nbsp;</span>}</div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-3 ">
                          <label htmlFor="cpassword" className="form-label text-secondary">Confirm Password</label>
                          <div className="input-group  ">
                            <input type={type3} id="cpassword" onChange={handleChange} name='cpassword' value={passData.cpassword || ''} placeholder="********" className="form-control text-black border border-end-0" />
                            <span className="input-group-text bg-white cursor-pointer " onClick={() => togglePassword(3)}>
                              {passShow3 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}
                            </span>
                          </div>
                          <div className="text-danger">{passErrors.cpassword ? passErrors.cpassword : <span>&nbsp;</span>}</div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-2">
                      <div className="col-lg-5">
                        <Link to='/myprofile' className="btn btn-white border border-1 text-black w-100">Back</Link>
                      </div>
                      <div className="col-lg-7">
                        <button className="btn btn-danger border border-dark shadow-btm w-100" type="submit">Update</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>


      </main>
      {/* <Copyright /> */}
      <ToastContainer />
    </>
  )
}

export default ChangePassword
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import $ from "jquery"; // Import jQuery
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { InventoryProvider } from "./contexts/InventoryContext";
import { AppProvider } from "./contexts/GlobalContext";
import { OrderProvider } from "./contexts/OrderContext";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import "toastify";
import 'react-toastify/ReactToastify.css';
window.jQuery = $;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <InventoryProvider>
          <OrderProvider>
            <App />
            <ToastContainer />
          </OrderProvider>
        </InventoryProvider>
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

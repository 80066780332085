import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import loginImage from '../assets/images/login.png';

import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import Header from '../layout/Header';

const OtpLogin = () => {
  const { api_url, setIsLoading } = useGlobalContext();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const location = useLocation();

  const [otpData, setOtpData] = useState({
    otp: location.state?.otp,
    phone: location.state?.phone,
  });

  const handleChange = (e) => {
    setOtpData({ ...otpData, [e.target.name]: e.target.value })
  }

  const handleVerify = (e) => {
    e.preventDefault();

    let error = {};
    if (!otpData.otp) {
      error.otp = 'Please enter OTP';
    }
    setError(error);

    if (Object.keys(error).length === 0) {
      const data = {
        'user_id': location.state?.user_id,
        'otp': otpData?.otp,
        'otp_type': 'login'
      }
      setIsLoading(true)
      axios.post(`${api_url}/verifyOtp`, data)
        .then((res) => {
          if (res.data.success === 1) {
            setIsLoading(false)
            toast.success(`${res.data.message}`, {
              theme: 'colored',
              autoClose: 1500,
            })
            let timer = setTimeout(() => {
              sessionStorage.setItem('token', res.data.data.token);
              sessionStorage.setItem('username', res.data.data.name);
              sessionStorage.removeItem('guest_id')
              sessionStorage.setItem('user_id', res.data.data.user_id);
              res.data.data.school_code !== null && sessionStorage.setItem('school_code', res.data.data.school_code);
              navigate('/')
            }, 2000);
            return () => clearTimeout(timer);
          }
          else {
            setIsLoading(false)
            toast.error(`${res.data.message} `, {
              theme: 'colored',
              autoClose: 1500,
            })
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error('Axios Error', {
            theme: 'colored',
            autoClose: 1500,
          })
          console.log(err)
        })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};
    if (!otpData.otp) {
      error.otp = 'Please enter OTP';
    }
    setError(error);

    if (Object.keys(error).length === 0) {

    }
  }
  const [seconds, setSeconds] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(false);

  useEffect(() => {
    let interval = null;

    if (isTimerActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsTimerActive(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isTimerActive, seconds]);

  const handleResendOtp = () => {
    // Logic to resend OTP goes here
    console.log("OTP Resent!");

    // Reset the timer
    setSeconds(60);
    setIsTimerActive(true);
  };
  return (
    <>
      <Header />
      <main id='main' className='h-100 mt-5 pt-5'>
        <section id="" className=' h-100'>
          <div className="container">
            <div className="row g-4 mt-md-5">
              <div className="col-md pt-3">
                <img src={loginImage} alt='.' className="img-fluid d-none d-md-block" />
              </div>
              <div className="col-md">
                <div className="border border-1 border-dark rounded-2 p-2 shadow-md user">
                  <h3 className="text-center pt-3">Login</h3>
                  <form className="p-4">
                    <div className="form-group">
                      <label htmlFor="email">Email/Mobile No.</label>
                      <div className="row g-3">
                        <div className="col-8">
                          <input type="number" className="form-control" id="number" disabled value={otpData?.phone} readOnly placeholder="91 1236547890" name="number" />
                        </div>
                        <div className="col-4 d-flex align-items-center">

                          {isTimerActive ? (
                            <p className='text-center my-auto'>Resend OTP in <span className='text-danger'> {seconds}</span> sec</p>
                          ) : (
                            <button onClick={handleResendOtp} className="btn border-dark w-100 text-danger" disabled={isTimerActive}>
                              Resend OTP
                            </button>
                          )}
                          {/* <button type="submit" className="btn border-dark w-100 text-danger">Send OTP</button> */}
                        </div>
                      </div>
                    </div>
                    <div className="form-group pt-3">
                      <label htmlFor="number">Verify OTP</label>
                      <input type="number" id="number" onChange={handleChange} name='otp' value={otpData?.otp} placeholder={'_ _ _ _ _ _'} className="form-control" />
                      <div className="text-danger">{error.otp}</div>

                    </div>
                    <div className='mt-1'>

                      <Link to={'/forgotpassword'} className='text-dark text-decoration-none mt-2 fw-bold'>Forgot Password</Link>
                    </div>
                    <button type="submit" className="mt-4 btn btn-danger d-block w-100" onClick={handleVerify}>Login</button>
                    <button type="submit" className="my-3 btn border-dark shadow-btm w-100 text-danger fw-bold">My School</button>
                    <h6 className="mt-3 mb-1">Create an account with <span className="fw-bold">e-Vyapari</span> <span><a href="register.html" className="text-danger fw-bold">SignUp</a></span></h6>
                  </form>
                </div>
              </div>
            </div>


          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  )
}

export default OtpLogin
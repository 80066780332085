import Step1 from '../components/Step1'

import Step2 from '../components/Step2'
import Step3 from '../components/Step3'
import { useGlobalContext } from '../contexts/GlobalContext'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import loginImage from '../assets/images/register.png';

import Loading from '../components/loading/Loading'
// import Copyright from '../Components/Copyright'
import { useState } from 'react'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
// import TermsConditions from '../Components/TermsConditions'
import Header from '../layout/Header'

const Register = () => {
    const { currentStep, isLoading, setIsLoading, setShowSchoolModal } = useGlobalContext();

    //Step 1
    // const Step1 = () => {
    //     const { registerData, changeHandler, setCurrentStep } = useGlobalContext();
    //     const { first_name, last_name } = registerData;
    //     const step1_errors = {}
    //     const [errors, setErrors] = useState({});
    //     const nameRegex = /^[a-zA-Z]*$/;

    //     if (first_name === '') {
    //         step1_errors.first_name = "First name can't be empty"
    //     } else if (!(nameRegex.test(first_name))) {
    //         step1_errors.first_name = "Name can only contain alphabets"
    //     }
    //     if (last_name === '') {
    //         step1_errors.last_name = "Last name can't be empty"
    //     } else if (!(nameRegex.test(last_name))) {
    //         step1_errors.last_name = "Name can only contain alphabets"
    //     }

    //     const handleClick = () => {
    //         setErrors(step1_errors);

    //         if (Object.keys(step1_errors).length === 0) {
    //             setCurrentStep(2)
    //         }
    //     };

    //     return (
    //         <>
    //             <div>
    //                 <div className="form-floating my-3">
    //                     <input type="text" id="first_name" name="first_name" value={registerData.first_name} onChange={(e) => changeHandler(e)} className="form-control" placeholder='first_name' />
    //                     <label className="form-label" htmlFor="first_name">First Name</label>
    //                     <div className='text-danger s-font2'>{errors.first_name}</div>
    //                 </div>
    //                 <div className="form-floating my-3">
    //                     <input type="text" id="last_name" name="last_name" value={registerData.last_name} onChange={(e) => changeHandler(e)} className="form-control" placeholder='last_name' />
    //                     <label className="form-label" htmlFor="last_name">Last Name</label>
    //                     <div className='text-danger s-font2'>{errors.last_name}</div>
    //                 </div>
    //             </div>
    //             <div className="overflow-auto">
    //                 <div className="float-end">
    //                     <button type="button" className="btn btn-danger" id="nextBtn" onClick={handleClick}>Next</button>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // }
    //Step 2
    // const Step2 = () => {
    //     const { registerData, changeHandler, setCurrentStep, districts, stateData, handleStateChange } = useGlobalContext();
    //     //Validation
    //     const { phone_no, state, district } = registerData;
    //     const step2_errors = {};
    //     const [errors, setErrors] = useState({});
    //     var phoneRegex = /^\d{10}$/;

    //     if (phone_no === '') {
    //         step2_errors.phone_no = "Mobile Number Required";
    //     } else if (!(phoneRegex.test(phone_no))) {
    //         step2_errors.phone_no = "Enter 10 Digit Number";
    //     }
    //     if(state==='')
    //     {
    //         step2_errors.state = "State Required";
    //     }
    //     if(district==='')
    //     {
    //         step2_errors.district = "District Required";
    //     }

    //     const handleState = (e) => {
    //         handleStateChange(e);
    //         changeHandler(e);
    //     }

    //     const handleClick = () => {
    //         setErrors(step2_errors);

    //         if (Object.keys(step2_errors).length === 0) {
    //             setCurrentStep(3)
    //         }
    //     };

    //     return (
    //         <>
    //             <div className="tab">
    //                 <div className="form-floating my-3">
    //                     <input type="tel" id="phone_no" name="phone_no" value={registerData.phone_no} onChange={(e) => changeHandler(e)} maxlength="10" minlength="10" className="form-control" placeholder='' />
    //                     <label className="form-label" for="phone_no">Mobile Number</label>
    //                     <div className='text-danger s-font2'>{errors.phone_no}</div>
    //                 </div>
    //                 <div className="form-floating my-3">
    //                     <select className="form-select py-3" onChange={(e) => handleState(e)} name='state' value={registerData.state} aria-label="Default select example">
    //                         <option value="Select State">--Select State--</option>
    //                         {
    //                             stateData.map((state, i) => {
    //                                 return (
    //                                     <option key={i} value={state.state_name}>{state.state_name}</option>
    //                                 )
    //                             })
    //                         }
    //                     </select>
    //                     <div className="text-danger mb-3">{errors.state}</div>
    //                 </div>
    //                 <div className="form-floating my-3">
    //                     <select className="form-select py-3" onChange={(e) => changeHandler(e)} name='district' value={registerData.district} aria-label="Default select example">
    //                         <option>--Select District--</option>
    //                         {
    //                             districts.map((district, i) => {
    //                                 return (
    //                                     <option key={i} value={district}>{district}</option>
    //                                 )
    //                             })
    //                         }
    //                     </select>
    //                     <div className="text-danger mb-3">{errors.district}</div>
    //                 </div>
    //             </div>

    //             <div className="overflow-auto">
    //                 <div className="float-end">
    //                     <button type="button" className="btn btn-danger me-2" id="prevBtn" onClick={() => setCurrentStep(1)}>Previous</button>
    //                     <button type="button" className="btn btn-danger" id="nextBtn" onClick={handleClick}>Next</button>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // }
    //Step 3
    // const Step3 = () => {
    //     const navigate = useNavigate();
    //     const { registerData, changeHandler, setCurrentStep, isLoading, setIsLoading, api_url } = useGlobalContext();
    //     const [verified, setVerified] = useState(false);
    //     const [passShow1, setPassShow1] = useState(true);
    //     const [passShow2, setPassShow2] = useState(true);
    //     const [type1, setType1] = useState('password');
    //     const [type2, setType2] = useState('password');
    //     const location = useLocation();
    //     const togglePassword = (flag) => {
    //         if (flag === 1 && type1 === 'password') {
    //             setType1('text');
    //             setPassShow1(!passShow1)
    //         } else if (flag === 1 && type1 === 'text') {
    //             setType1('password');
    //             setPassShow1(!passShow1)
    //         }

    //         if (flag === 2 && type2 === 'password') {
    //             setType2('text');
    //             setPassShow2(!passShow2)
    //         } else if (flag === 2 && type2 === 'text') {
    //             setType2('password');
    //             setPassShow2(!passShow2)
    //         }
    //     }


    //     // Form Validatioon
    //     var emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
    //     var passwordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    //     const { email, password } = registerData;

    //     const [errors, setErrors] = useState({});
    //     const step3_errors = {};

    //     // recaptcha function
    //     function onClickCaptcha(value) {
    //         setVerified(!verified);
    //     };

    //     const handleRegister = (e) => {
    //         e.preventDefault();
    //         let cpassword = document.getElementById('cpassword').value;

    //         // if (email === '') {
    //         //     step3_errors.email = "Enter Email";
    //         // }
    //         if (email !== '' && !emailRegex.test(email)) {
    //             step3_errors.email = "Invalid Email Formate";
    //         }

    //         if (password === '') {
    //             step3_errors.password = "Password Required";
    //         }
    //         else if (password.length < 6) {
    //             step3_errors.password = "Password must contain 6 character";
    //         }

    //         //confirm password
    //         if (!cpassword) {
    //             step3_errors.cpassword = "Please Re-enter Password";
    //         } else if (cpassword !== password) {
    //             step3_errors.cpassword = "Password Did Not Match";
    //         }

    //         setErrors(step3_errors);


    //         // .........................................................................................................//
    //         if (Object.keys(step3_errors).length === 0) {
    //             setIsLoading(true);
    //             axios.post(`${api_url}/register`, registerData,
    //                 {
    //                     headers: { 'Content-Type': 'application/json' }
    //                 }
    //             )
    //                 .then(res => {
    //                     if (res.data.success === 1) {
    //                         setIsLoading(false);
    //                         toast.success(res.data.message, {
    //                             autoClose: 1500,
    //                             theme: 'colored'
    //                         });
    //                         let RegData = res.data.data;

    //                         let timer = setTimeout(() => {
    //                             sessionStorage.setItem('token', RegData.token);
    //                             sessionStorage.setItem('username', RegData.name);
    //                             sessionStorage.removeItem('guest_id')
    //                             sessionStorage.setItem('user_id', RegData.user_id);
    //                             navigate('/otpverification', { state: { user_id: res.data.data.user_id, otp: res.data.data.otp, prevPath: location.pathname } });
    //                         }, 2000);
    //                         return () => clearTimeout(timer);
    //                     } else {
    //                         toast.error(res.data.message, {
    //                             autoClose: 1500,
    //                             theme: 'colored'
    //                         })
    //                         setIsLoading(false);
    //                     }
    //                 })
    //                 .catch(err => {
    //                     console.log("error is there:", err)
    //                     toast.error('Axios Error 😭😭', {
    //                         autoClose: 1500,
    //                     });
    //                     setIsLoading(false);
    //                 })
    //         }
    //         // .........................................................................................................//
    //         console.log(errors);
    //     };
    //     return (
    //         <>
    //             {isLoading ? <Loading /> : null}
    //             <div className="tab">
    //                 <div className="form-floating">
    //                     <input type="email" id="form3Example4" name="email" value={registerData.email} onChange={(e) => changeHandler(e)} className="form-control" placeholder='' />
    //                     <label className="form-label" for="form3Example4">Email</label>
    //                     <div className='text-danger s-font2'>{errors.email}</div>
    //                 </div>
    //                 <div className="form-floating my-3">
    //                     <input type={type1} id="password" name="password" value={registerData.password} onChange={(e) => changeHandler(e)} className="form-control" placeholder='' />
    //                     <label className="form-label" for="password">Choose a Strong Password</label>
    //                     <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={() => togglePassword(1)}>{passShow1 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow1 ? 'Show' : 'Hide'}</span>
    //                     <div className='text-danger s-font2'>{errors.password}</div>
    //                 </div>
    //                 <div className="form-floating my-3">
    //                     <input type={type2} id="cpassword" name="cpassword" className="form-control" placeholder='' />
    //                     <label className="form-label" for="cpassword">Confirm Password</label>
    //                     <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={() => togglePassword(2)}>{passShow2 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow2 ? 'Show' : 'Hide'}</span>
    //                     <div className='text-danger s-font2'>{errors.cpassword}</div>
    //                 </div>
    //             </div>
    //             <ReCAPTCHA
    //                 sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
    //                 onChange={onClickCaptcha}
    //             />

    //             <div className="form-check">
    //                 <input className="form-check-input" checked type="checkbox" value="" id="flexCheckDefault" />
    //                 <label className="form-check-label" for="flexCheckDefault">
    //                     I agree to accept the terms and conditions
    //                     <span className='cursor-pointer text-decoration-none text-danger' data-bs-toggle="modal" data-bs-target="#ReadMoreModal"> READ.</span>
    //                 </label>
    //             </div>
    //             <TermsConditions />

    //             <div className="overflow-auto mt-3">
    //                 <div className="float-end">
    //                     <button type="button" className="btn btn-danger me-2" id="prevBtn" onClick={() => setCurrentStep(2)}>Previous</button>
    //                     <button type="button" className="btn btn-danger" onClick={(e) => handleRegister(e)} id="nextBtn">Register</button>
    //                     {/* <button type="button" className="btn btn-danger" onClick={(e) => handleRegister(e)} disabled={!verified} id="nextBtn">Register</button> */}
    //                 </div>
    //             </div>
    //         </>
    //     )
    // }


    const ShowStep = (step) => {
        // eslint-disable-next-line default-case
        switch (step) {
            case 1:
                return <Step1 />
            case 2:
                return <Step2 />
            case 3:
                return <Step3 />
        }
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            <Header />
            <main id='main' className='registermain mb-0 mt-5 pt-4'>
                <section id="register">
                    <div className="px-2 pb-3 pt-4 px-md-5 text-center text-lg-start">
                        <div className="container">
                            <div className="row g-4 mt-md-5">
                                <div className="col-lg-6 d-lg-block d-none">
                                    <div className="mb-5 mb-lg-0">
                                        <img src={loginImage} alt='.' className="img-fluid d-none d-md-block" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="border border-1 border-dark rounded-2 p-2 shadow-md user">
                                        <h3 className="text-center pt-3">Create Account</h3>
                                        <form className="p-4" id="regForm" method='post'>
                                            {ShowStep(currentStep)}

                                            {
                                                sessionStorage.getItem('school_code') === null ?

                                                    <button type='button' onClick={() => setShowSchoolModal(true)} className="my-3 btn border-dark shadow-btm w-100 text-danger fw-bold">My School</button>
                                                    :
                                                    <Link to={'/myschool'} className="my-3 btn border-dark shadow-btm w-100 text-danger fw-bold">
                                                        My School
                                                    </Link>
                                            }   <h6 className="mt-3 mb-1">Already have an account <span><Link to={'/login'} className="text-danger fw-bold">Login</Link></span> here</h6>
                                        </form>
                                    </div>
                                </div>


                                {/* <div className="col-lg-6 mb-5 mb-lg-0">
                                    <div className="card mx-auto f-shadow">
                                        <h2 className="mt-3 text-center text-danger fw-bold">SignUp</h2>
                                        <div className="card-body py-3 px-md-5">
                                            <form id="regForm" method='post' className="text-start">

                                                {ShowStep(currentStep)}
                                            </form>

                                            <h6 className="mt-3 mb-1">Already have an account<span><Link to="/login" className="text-danger fw-bold"> Login</Link></span> here</h6>


                                           
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* <Copyright /> */}
            <ToastContainer />
        </>
    )
}
// }

export default Register
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const OrderItem = (props) => {
    const { srno, invoice_number, order_total, order_date, grand_total, order_status, tracking_status, item } = props;
    const navigate = useNavigate();
    return (
        <>
            {/* {JSON.stringify(item)} */}

            <div className="card border-0 shadow rounded-4 pt-4 px-2 mb-4">
                <div className="row">
                    <div className="col-12">

                        <h6 className="ps-3 d-flex align-items-center text-center ord-opt"> <i className="ti ti-package text-light bg-dark fs-2 rounded-2 me-2" />  <div dangerouslySetInnerHTML={{ __html: tracking_status }} />
                        </h6>

                    </div>

                </div>
                <div className="card-body d-flex align-items-center flex-wrap text-start">

                    <div className="mx-3 mt-1">

                        <h5 className=" fw-bold text-start invoicenumber">{invoice_number}</h5>
                        <div className="text-start fs-6 fw-semibold">Total Amount : ₹{grand_total}</div>


                    </div>
                    <div className="ms-auto pe-3 pt-3">
                        {
                            order_status === 1 ?

                                <h6 className="btn d-flex align-items-center text-center mb-0 ord-opt" onClick={() => navigate(`/myschool`)}><i className="ti ti-credit-card-refund text-end text-light bg-danger rounded-3 p-1 me-2 fs-4" />Re-Order</h6>

                                :
                                <h6 className="btn d-flex align-items-center text-center mb-0 ord-opt" onClick={() => {navigate('/orderdetails', { state: { invoice_number: invoice_number } });window.scrollTo({ top: 0, behavior: 'instant' })}} ><i className="ti ti-list-details text-end text-light bg-danger rounded-3 p-1 me-2 fs-4" />
                                    Order Details</h6>

                        }


                    </div>
                </div>
            </div>

        </>
    )
}

export default OrderItem
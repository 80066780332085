import React, { useEffect, useMemo, useState } from 'react'
import orderBook from '../assets/images/order-book.png'
import proThree from '../assets/images/pro-three.png'
import productFour from '../assets/images/product-four.png'
import proOne from '../assets/images/pro-one.png'
import storeFront from '../assets/images/storefront.png'
import CrousalCard from './CrousalCard'
import axios from 'axios'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useInventoryContext } from '../contexts/InventoryContext'
import { useGlobalContext } from '../contexts/GlobalContext'
import ProductCard from './ProductCard'
import CarouselSlider from './CarouselSlider/CarouselSlider'
import logo from '../assets/images/main-logo.svg';
import Review from './Review'
import { useOrderContext } from '../contexts/OrderContext'
import {
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
} from 'react-share';
import ShareProduct from './ShareProduct'
import ProductShareButton from './ProductShareButton'
import Loading from './loading/Loading'
const Product = () => {
    const { setIsLoading, wishlistData, api_url, img_url, convertToPlain, handleAddToCart, WishlistLength, handleAddToWishlist, getAllWishlistItems, handleBuyNow } = useGlobalContext();
    const { orders, getMyOrders } = useOrderContext();

    const { allInventory, getAllInventory, inventoryDetails, setInventoryDetails, getInventoryDetails, inventoryImages, searchKeyword, setSearchKeyword } = useInventoryContext()
    const [click, setClick] = useState(false)
    const [pincode, setPincode] = useState();
    const [availability, setAvailability] = useState(null);
    const [productReviews, setProductReviews] = useState([]);
    const [productRating, setProductRating] = useState(0);
    const [sizeMediumChanged, setSizeMediumChanged] = useState(false)
    const { product_id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const checkPincodeAvailability = async (mypincode) => {
        const data = {
            "pincode": mypincode
        }
        setIsLoading(true);
        try {
            let res = await axios.post(`${api_url}/checkPincodeAvailability`, data);
            if (res.data.success === 1) {
                setAvailability(res.data.message);
            }
        } catch (error) {
            toast.error('Something Wrong Happened', {
                autoClose: 2000,
                theme: 'colored'
            })
        } finally {
            setIsLoading(false);
        }
    }

    const [colorClass, setColorClass] = useState(inventoryDetails.class_title)

    const [inventoryColorClasses, setInventoryColorClasses] = useState([]);

    // console.log('inventoryColorClasses', inventoryColorClasses)
    // console.log('size_class_inventory', inventoryDetails?.size_class_inventory)

    useEffect(() => {
        productReviews.length !== 0 && getProductRating();
    }, [productReviews])

    useEffect(() => {
        getProductReviews();
    }, []);
    useEffect(() => {
        setTimeout(() => {
            getAllWishlistItems()
        }, 3000);
    }, [click, setClick]);

    //getProductReviews
    const getProductReviews = async () => {
        try {
            const res = await axios.post(`${api_url}/getProductReviews`, { 'product_id': inventoryDetails?.product_id })
            if (res.data.success === 1) {
                setProductReviews(res.data.data)
                // toast.success(res.data.message, {
                //     autoClose: 1500,
                //     theme: "colored",
                // });
                // console.log("getProductReviews",res.data)
            } else {
                toast.error('Something Went Wrong', {
                    autoClose: 1500,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.error("Axios Error", {
                autoClose: 1500,
                theme: "colored",
            });
        }
    }

    //getProductRating
    const getProductRating = () => {
        let prating = 0;
        productReviews.forEach((ele, i) => {
            prating += ele.rating;
        })
        prating = (prating / productReviews.length).toFixed(1);
        setProductRating(prating)
    }




    useEffect(() => {
        getAllInventory(null, null, null, null, inventoryDetails?.last_cat_id);
    }, [inventoryDetails]);

    const [mainImage, setMainImage] = useState({})

    //Accessing the quantity value from Quantity Picker
    let access_qty;
    const accessQantity = (child_qty) => {
        access_qty = child_qty;
    }

    //handleColorClassChange
    const handleColorClassChange = (e) => {
        let ele = document.getElementById('color_class_label');
        ele.classList.remove('select_color_class');
        setColorClass(e.target.innerHtml)
        navigate(`/product/${e.target.value}`)
    }

    //handleSizeMediumChange
    const handleSizeMediumChange = async (e) => {
        let ele = document.getElementById('color_class_label');
        ele.classList.add('select_color_class');
        setSizeMediumChanged(true)
        // if (inventoryDetails?.class_title) {
        //   setInventoryDetails((prev) => ({ ...prev, 'class_title': '--Select Class--' }))
        // }
        // if (inventoryDetails?.product_color) {
        //   setInventoryDetails((prev) => ({ ...prev, 'product_color': '--Select Color--' }))
        // }

        setColorClass('');

        const data = {
            'product_name': inventoryDetails?.product_name,
            'size_medium_id': e.target.value
        }
        try {
            const res = await axios.post(`${api_url}/getInventoryColorClasses`, data);
            if (res.data.success === 1) {
                setInventoryColorClasses(res.data.data);
            } else {
                toast.error('Something Wrong Happened', {
                    autoClose: 2000,
                    theme: 'colored'
                })
            }

        } catch (error) {
            console.log(error)
        }
    }

    // console.log(productRating)


    const [Addtocart, setAddtocart] = useState(false)

    const [count, setCount] = useState(1)
    const handleMinus = (e) => {
        e.preventDefault()
        if (count > 1) {
            setCount(count - 1)
        }
        else
            setAddtocart(false)

    }
    const handleSearch = () => {
        // getPageInventory(url);
        if (location.pathname !== '/marketplace') {
            // navigate('/marketplace')
            navigate({
                pathname: '/marketplace',
                search: `?search=${searchKeyword}`,
            });
        }
        // navigate('/marketplace')
        // navigate('/marketplace', { state: {'searchKeyword' : searchKeyword} });
    }

    // https://evyapari.com/admin/public/api/inventoryDetail/217



    //     pincode
    // : 
    // "246736"
    // https://evyapari.com/admin/public/api/checkPincodeAvailability


    useEffect(() => {

        getInventoryDetails(product_id)
    }, [location])


    const [name, setname] = useState(null)
    useEffect(() => {
        const dataExistInWishList = wishlistData.find((item) => parseInt(item.id) === parseInt(product_id));
        setname(dataExistInWishList)
        // console.log(dataExistInWishList, 'daadssa')
    }, [inventoryDetails, wishlistData, product_id, WishlistLength, handleAddToWishlist, setClick, click])
    const [active, setActive] = useState()
    // const productCarouselItems = allInventory.map((card, i) => {
    //     const { last_cat_id, id, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, itemExistInCart, itemExistInWishlist } = card;
    //     return (

    //         <ProductCard
    //             key={i}
    //             id={id}
    //             product_id={product_id}
    //             image={`${img_url}/${folder}/${image}`}
    //             title={product_name}
    //             mrp={mrp}
    //             discounted_price={discounted_price}
    //             shipping_charges={shipping_charges}
    //             type={type}
    //             text={convertToPlain(description)}
    //             Card={card}
    //             itemExistInCart={itemExistInCart}
    //             itemExistInWishlist={itemExistInWishlist}
    //             last_cat_id={last_cat_id}
    //         />

    //     )
    // })


    const productCarouselItems = useMemo(() => {
        return allInventory.map((card, i) => {
            const { last_cat_id, id, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, itemExistInCart, itemExistInWishlist } = card;
            return (
                <ProductCard
                    key={i}
                    id={id}
                    product_id={product_id}
                    image={`${img_url}/${folder}/${image}`}
                    title={product_name}
                    mrp={mrp}
                    discounted_price={discounted_price}
                    shipping_charges={shipping_charges}
                    type={type}
                    text={convertToPlain(description)}
                    Card={card}
                    itemExistInCart={itemExistInCart}
                    itemExistInWishlist={itemExistInWishlist}
                    last_cat_id={last_cat_id}
                />
            );
        });
    }, [allInventory, convertToPlain, img_url]);
    const hText = inventoryDetails?.description?.match(/<h\d>(.*?)<\/h\d>/)
    const displayText = hText ? hText[1] : inventoryDetails?.description;
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const encodeWhatsAppMessage = (product) => {
        const message = `
          *${product.name}*\n
          ${product.description}\n\n
          Check it out here: ${product.productUrl}\n
          ![Image](${product.imageUrl})
        `;
        return `https://wa.me/?text=${encodeURIComponent(message)}`;
    };
    const product = {
        name: 'Awesome Product',
        description: 'This is an awesome product that you must have!',
        imageUrl: 'https://example.com/product-image.jpg',
        productUrl: 'https://example.com/product-page'
    };
    const message = 'Hi, pls visit my Hashnode profile';
    const imageUrl = `${img_url}/${mainImage.folder}/${mainImage.image}`;
    const link = `https://evyapari.com/product/${product_id}`;
    const onSharePress = () => {
        const shareData = {
            title: 'Check this out!',
            text: `${message}\n${link}`,
            url: imageUrl, // Direct URL for sharing image or content
        };

        try {
            if (navigator.share) {
                navigator.share(shareData)
                    .then(() => console.log('Successfully shared!'))
                    .catch((error) => console.error('Error sharing:', error));
            } else {
                console.log('Share not supported on this browser');
            }
        } catch (error) {
            console.error('Error in sharing:', error);
        }
    };
    const content = isExpanded ? displayText : `${displayText?.slice(0, 50)}...`;
    if (!inventoryDetails) {
        return <Loading />;
    }
    return (
        <>
            {/* {JSON.stringify(name)} */}
            {/* {JSON.stringify(wishlistData)} */}
            {/* {JSON.stringify(inventoryImages)} */}
            {/* {JSON.stringify(inventoryDetails)} */}
            <main>
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-4 d-block d-md-none">
                        <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                    </div>
                </div>


                <section id="search-product" className="py-5">
                    <div className="container">
                        <div className=" row">
                            <div className="col-lg-4 col-0"></div>
                            <div className="col-lg-5">
                                <div className="input-group mb-3 shadow-md rounded-3 input-search">
                                    <input type="text" className="form-control  border-end-0" onChange={(e) => setSearchKeyword(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") handleSearch(e) }} value={searchKeyword !== null ? searchKeyword : ''} inputMode="search" aria-label="Search" placeholder="Search Items" />
                                    <div className="input-group-append">
                                        <span className="input-group-text search-icon  h-100" onClick={() => handleSearch()}
                                            style={{ cursor: 'pointer' }}>
                                            <i className="fa-solid fa-magnifying-glass p-2 bg-dark text-light rounded-3" />
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="input-group shadow-md rounded-3 " >
                                    <input type="text" className="form-control  border-end-0" onChange={(e) => setSearchKeyword(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") handleSearch() }} value={searchKeyword !== null ? searchKeyword : ''} placeholder="Search Items" />
                                    <div className="input-group-append">
                                        <span className="input-group-text search-icon  h-100 cursor-pointer" onClick={() => handleSearch()}>
                                            <i className="fa-solid fa-magnifying-glass p-2 text-danger  text-light rounded-3" />
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="col-lg-3 ">
                                <div className="d-flex ms-auto me-0 justify-content-end gap-3">
                                    <Link to={'/wishlist'} className="btn btn-light shadow-btm px-4 wishlist rounded-2"> <i className="fa-regular fa-heart text-dark me-2" />Wishlist</Link>



                                </div>
                            </div> */}
                            {/* {console.log(inventoryDetails, 'invef')} */}
                        </div>
                    </div></section>
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                        onClick={onSharePress}
                        style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}
                    >
                        Share Now
                    </button>
                </div> */}
                {/* product section start */}
                <div section id="product" className="py-5">
                    <div className="container">
                        <div className='d-flex flex-column flex-md-row gap-4'>
                            <div className='order-2 side-width d-flex flex-md-column flex-row gap-2 carousel order-md-0'>
                                {inventoryImages &&
                                    inventoryImages.map((image, i) => {
                                        return (
                                            <div key={i} className="border border-2 border-dark  rounded-4 p-0 mb-1 item1">
                                                <img onClick={() => setMainImage(image)} src={`${img_url}/${image.folder}/${image.image}`} className="d-block img-fluid mx-auto rounded-4 img-size" alt={image.alt} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="d-flex w-100 order-1">
                                {
                                    Object.keys(mainImage).length === 0 ?
                                        (
                                            inventoryImages &&
                                            inventoryImages.map((image, i) => {
                                                return (
                                                    image.dp_status === 1 &&
                                                    <div key={i} className="card d-flex border border-2 p-3 border-dark shadow-btm  rounded-24 h-100 w-100">
                                                        <img src={`${img_url}/${image.folder}/${image.image}`} className="d-block img-fluid mx-auto rounded-3 zoom_img" alt={image.alt} />
                                                    </div>
                                                )
                                            })
                                        )
                                        :
                                        <div className="card d-flex border border-2 p-3 border-dark shadow-btm  rounded-24 h-100 w-100">
                                            <img src={`${img_url}/${mainImage.folder}/${mainImage.image}`} className="d-block img-fluid mx-auto rounded-4 h-100 w-100" alt='' />
                                        </div>
                                }

                            </div>

                            <div className='d-flex flex-column py-2 w-100 position-relative order-3'>

                                <div>
                                    <div className='d-flex justify-content-between'>

                                        <h3>{inventoryDetails?.product_name} </h3>

                                    </div>
                                    <p className="text-secondary fs-5 ">  <div dangerouslySetInnerHTML={{ __html: displayText?.length > 40 ? `${displayText.slice(0, 40) + '... '}` : displayText }} /></p>
                                    <ul className="list-inline price">
                                        <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                        <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                        <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                        <li className="list-inline-item"><i className="fa-solid fa-star text-warning" /></li>
                                        <li className="list-inline-item"><i className="fa-regular fa-star text-warning" /></li>
                                    </ul>
                                    <div className="price"><span className="text-decoration-line text-secondary me-2 fw-bold fs-3">₹ {inventoryDetails?.mrp}</span> <span className="fw-bold fs-3 "> ₹ {inventoryDetails?.discounted_price} </span> </div>
                                    <p>Delivery Charges ₹ {inventoryDetails?.shipping_charges} </p>
                                    <div className="d-flex">


                                    </div>
                                    <div className="d-flex gap-2 w-100">
                                        <div className='w-100'>
                                            <label htmlFor="" className='fw-bold py-2 pt-3'>Medium/Size</label>

                                            <select className="form-select border border-dark shadow-btm btn-light w-100" name='size_medium' onChange={handleSizeMediumChange} aria-label="Default select example">
                                                <option disabled>Select</option>

                                                {
                                                    inventoryDetails.size_medium &&
                                                    inventoryDetails.size_medium.map((ele, i) => {
                                                        return (
                                                            <option key={i} value={ele.size_medium_id} selected={ele.size_medium_title === inventoryDetails?.product_size}>{ele.size_medium_title}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {/* <div className="dropdown medium">
                                            <div className="fw-bold py-2 pt-3">
                                                Medium
                                            </div>
                                      
                                            <button className="btn border border-dark shadow-btm btn-light dropdown-toggle px-4" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Choose Medium
                                            </button>
                                            <ul className="dropdown-menu">
                                                {inventoryDetails?.size_medium ? inventoryDetails?.size_medium?.map((item, index) =>
                                                (<li key={index} onClick={(e) => console.log(item?.size_medium_id)}><a className="dropdown-item" href="#">{item?.size_medium_title}</a></li>
                                                )) : ''}
                                            </ul>
                                        </div> */}
                                        <div className='w-100'>
                                            <label htmlFor="" id='color_class_label' className='fw-bold py-2 pt-3'>Standard/Color</label>
                                            <select className="form-select border border-dark shadow-btm btn-light dropdown-toggle" name='class_inventory_id' value={colorClass} onChange={handleColorClassChange} aria-label="Default select example">
                                                {
                                                    sizeMediumChanged === false ?
                                                        <>
                                                            {/* <option value={inventoryDetails?.id}>{inventoryDetails?.class_title} {inventoryDetails?.product_color}</option> */}
                                                            <option disabled>Select</option>
                                                            {
                                                                inventoryDetails &&
                                                                inventoryDetails?.color_class &&
                                                                inventoryDetails?.color_class.map((ele, i) => {
                                                                    return (
                                                                        // (inventoryDetails?.class_title !== ele.color_class_title ||
                                                                        //   inventoryDetails?.product_color !== ele.color_class_title)  
                                                                        // &&
                                                                        <option key={i} value={ele.inventory_id}
                                                                            selected={inventoryDetails?.class_title === ele.color_class_title ||
                                                                                inventoryDetails?.product_color === ele.color_class_title || ele.allInventory === product_id}>
                                                                            {ele.color_class_title}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <>

                                                            <option value='' >--Select Class--</option>
                                                            {

                                                                inventoryColorClasses.map((ele, i) => {

                                                                    return (
                                                                        // (inventoryDetails?.class_title !== ele.color_class_title ||
                                                                        //     inventoryDetails?.product_color !== ele.color_class_title)
                                                                        // &&
                                                                        <option key={i} value={ele.inventory_id}>
                                                                            {ele.color_class_title}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                }
                                            </select>
                                            {/* {JSON.stringify(inventoryDetails.color_class)} */}
                                        </div>
                                        {/* <div className="dropdown medium">
                                            <div className="fw-bold py-2 pt-3">
                                                Standard
                                            </div>
                                            <button className="btn border border-dark shadow-btm btn-light dropdown-toggle px-4" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Select Class
                                            </button>
                                            <ul className="dropdown-menu">
                                                {inventoryDetails?.color_class ? inventoryDetails?.color_class?.map((item, index) =>
                                                (<li key={index} onClick={(e) => console.log(item?.color_class_id)}><a className="dropdown-item" href="#">{item?.color_class_title}</a></li>
                                                )) : ''}


                                            </ul>
                                        </div> */}


                                    </div>
                                </div>

                                <div className="d-flex my-3 mt-4 pt-1">
                                    <input className="form-control me-2 shadow-btm " type="search" placeholder="Enter Pin Code" aria-label="Search" onChange={(e) => setPincode(e.target.value)} value={pincode} />
                                    <button className="btn btn-md-dark btn-danger shadow-btm" onClick={() => checkPincodeAvailability(pincode)} type="submit">Check</button>
                                </div>
                                {
                                    availability !== null &&
                                    <div className='ms-3 fw-bold my-auto mb-2'>
                                        {
                                            availability ? <span className='text-success'>Available</span> : <span className='text-danger'>Not Available</span>
                                        }
                                    </div>
                                }
                                <div className="price">
                                    <div className="row g-2">
                                        <div className="col-md-2">
                                            <div className=''>
                                                {name ? <button onClick={() => { navigate('/wishlist') }} className={`btn  me-2 btn-danger h-100 w-100`} ><i className={`text-white fa-solid  fa-heart`} /></button>
                                                    :
                                                    <button onClick={() => { handleAddToWishlist(inventoryDetails?.id); setClick(!click) }} className={`btn me-2 h-100 w-100  btn-dark`} ><i className={`fa-regular  fa-heart`} /></button>}

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            {Addtocart ?
                                                <div className=' w-100 btn btn-dark'>
                                                    <button className='bg-transparent text-light border-0 fs-6 mx-3' onClick={handleMinus}>-</button>
                                                    {count}
                                                    <button className='bg-transparent text-light border-0 fs-6 mx-3' onClick={(e) => setCount(count + 1)}>+</button>

                                                </div>
                                                : <button type="button" className="btn btn-dark mb-2 shadow-btm w-100 me-2" onClick={(e) => handleAddToCart(product_id)} data-bs-toggle="button" autoComplete="off">Add To Cart</button>}

                                        </div>
                                        <div className="col-md-6">
                                            <button type="button" className="btn btn-danger shadow-btm px-5  w-100 " data-bs-toggle="button" autoComplete="off" aria-pressed="true" onClick={() =>
                                                handleBuyNow(inventoryDetails?.id, access_qty)
                                            }>Buy Now</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex gap-2 mt-2'>
                                    <FacebookShareButton
                                        // title={inventoryDetails?.product_name}
                                        url={`https://user.evyapari.com/product/${inventoryDetails.id}`}
                                        quote={`Check this out! ${inventoryDetails?.product_name}` }
                                        hashtag={`Check this out!  ${inventoryDetails?.product_name}   #portfolio`}
                                    >
                                        <FacebookIcon size={40} round={true} />
                                    </FacebookShareButton>

                                    <WhatsappShareButton
                                        url={`https://evyapari.com/product/${inventoryDetails.id}`}
                                        title={inventoryDetails?.product_name}
                                    >
                                        <WhatsappIcon size={40} round={true} />
                                    </WhatsappShareButton>
                                </div>
                                <div className='d-flex mt-3 gap-2'>
                                    {
                                        Object.keys(mainImage).length === 0 ?
                                            (
                                                inventoryImages &&
                                                inventoryImages.map((image, i) => {
                                                    return (
                                                        image.dp_status === 1 &&
                                                        <ShareProduct disc={inventoryDetails.description} inventoryDetails={inventoryDetails} product_name={inventoryDetails?.product_name} folder={image.folder} link={`https://evyapari.com/product/${inventoryDetails?.id}`} image={`${img_url}/${image.folder}/${image.image}`} />


                                                    )
                                                })
                                            )
                                            :
                                            ''
                                    }
                                    {/* <FacebookShareButton
                                        title={`${inventoryDetails?.product_name}`}
                                        url={`https://evyapari.com/seeallfeature/${inventoryDetails.id}`}
                                        quote={'abc'}
                                        hashtag={'#portfolio...'}
                                    >
                                        <FacebookIcon size={40} round={true} />
                                    </FacebookShareButton>

                                    <WhatsappShareButton
                                        quote={'Abc'}
                                        title={`${inventoryDetails?.product_name}`}
                                        url={`https://evyapari.com/seeallfeature/${inventoryDetails.id}`}
                                        hashtag={'#portfolio...'}
                                    >
                                        <WhatsappIcon size={40} round={true} />
                                    </WhatsappShareButton> */}
                                    {/* {JSON.stringify(inventoryDetails)} */}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {/* <ProductShareButton product={product} /> */}

                {/* Properties section */}
                <section id="properties" className="py-3 text-md-center">
                    <div className="container">
                        <div className="border border-1 border-dark rounded-2 p-2 px-3 shadow-md">
                            <div className="row align-items-center ">
                                <div className="text-b col-lg-3 col-md-6 col-12 fw-bold">Sold By</div>
                                <div className="col-lg-3 col-md-6 col-6">
                                    <div className="text-n">
                                        <img src={storeFront} alt="storefrontn" />{inventoryDetails?.vendor_name}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-6 text-n order-2 order-md-1 text-n">{inventoryDetails?.total_vendor_inventory}&nbsp;Products</div>
                                <div className=" col-lg-3 col-md-6 col-6 order-1 order-md-2 d-flex justify-content-end"><button onClick={() => { navigate('/vendorshop', { state: { vendor_id: inventoryDetails.vendor_id } }); window.scrollTo({ top: 0, behavior: 'instant' }) }} className="shop-btn  btn border border-1 px-md-5 p-2  shadow-md text-n">View Shop</button></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* *********  bottom-on-products****** */}
                <section id="bottom-on-products">
                    <div className="container">
                        {/* List Tabs */}
                        <div className="border border-1 border-dark rounded-2 pt-md-3 pb-md-2 p-4 shadow-btm">
                            <div className="row">
                                <div className="col-md-3 col-6">
                                    <ul className="list list-tabs " role="tablist">
                                        <li className="list-unstyled">
                                            <a className="list-link active product-bold  text-decoration-none text-dark fw-bold" data-bs-toggle="tab" href="#overview">Overview</a>
                                        </li>
                                        <li className="list-unstyled pt-3">
                                            <a className="list-link product-normal text-decoration-none text-dark" data-bs-toggle="tab" href="#Table">Table of Content</a>
                                        </li>
                                        <li className="list-unstyled pt-3">
                                            <a className="list-link product-normal text-decoration-none text-dark" data-bs-toggle="tab" href="#additional">Additional Information</a>
                                        </li>
                                        <li className="list-unstyled pt-3">
                                            <a className="list-link product-normal text-decoration-none text-dark" data-bs-toggle="tab" href="#reviews">Reviews</a>
                                        </li>
                                        <li className="list-unstyled pt-3">
                                            <a className="list-link product-normal text-decoration-none text-dark" data-bs-toggle="tab" href="#book">Book PDF</a>
                                        </li>
                                    </ul>
                                </div>
                                {/* Tab Panes */}
                                <div className="col-md-9 col-6">
                                    <div className="tab-content ">
                                        <div id="overview" className="tab-pane active">
                                            <ul className="list-unstyled">
                                                <li className="fw-bold product-bold">Product Description</li>
                                                <li className="product-normal">
                                                    {/* {inventoryDetails?.description}
                                                    {displayText} */}
                                                    {/* <div dangerouslySetInnerHTML={{ __html: displayText?.length > 50 ?  `${displayText}<button className='cursor-pointer'>view more</button>`:'' }} /> */}
                                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                                    {displayText?.length > 50 && (
                                                        <Link className='text-danger fs-6' onClick={toggleExpand}>
                                                            {isExpanded ? 'view less' : 'view more'}
                                                        </Link>
                                                    )}

                                                </li>
                                            </ul>
                                        </div>
                                        <div id="Table" className="tab-pane fade">
                                            <div className="d-flex justify-content-between">
                                                <ul className="list-unstyled">
                                                    <li className="fw-bold product-bold">Product Details</li>
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.author !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Author : </span> {inventoryDetails?.author}
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.book_format !== null && (<li>
                                                                <span className="fw-bold detail-same">Book Format : </span>
                                                                {inventoryDetails?.book_format}
                                                            </li>)
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.printer_details !== null && (<li>
                                                                <span className="fw-bold detail-same">Printer Details : </span> {inventoryDetails?.printer_details}

                                                            </li>)
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.publish_year !== null && (<li>
                                                                <span className="fw-bold detail-same">Publish Year : </span>
                                                                {inventoryDetails?.publish_year}
                                                            </li>)
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.brand_title !== null && (<li>
                                                                <span className="fw-bold detail-same">Brand : </span>  {inventoryDetails?.brand_title}
                                                            </li>)
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.youtube_url !== null && (<li>
                                                                <span className="fw-bold detail-same">Youtube Url: </span> {inventoryDetails.youtube_url}
                                                            </li>)
                                                        )
                                                    }


                                                </ul>
                                            </div>
                                        </div>
                                        <div id="additional" className="tab-pane fade">
                                            <div className="d-flex gap-1 flex-wrap">
                                                <ul className="list-unstyled">
                                                    <h6 className="fw-bold product-bold">Product Details</h6>
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.class_title !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Class : </span>
                                                                    {inventoryDetails?.class_title}
                                                                </li>
                                                            )
                                                        )
                                                    }


                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.edition !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Edition : </span>
                                                                    {inventoryDetails?.edition}

                                                                </li>
                                                            )
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.min_qyt !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Min Quantity : </span>
                                                                    {inventoryDetails?.min_qyt}

                                                                </li>
                                                            )
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.hsncode !== null && (

                                                                <li>
                                                                    <span className="fw-bold detail-same">HSN Code : </span>
                                                                    {inventoryDetails?.hsncode}

                                                                </li>
                                                            )
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.mrp !== null && (
                                                                <li>

                                                                    <span className="fw-bold detail-same">Mrp : </span> ₹ {inventoryDetails?.mrp}
                                                                </li>
                                                            )
                                                        )
                                                    }

                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.discounted_price !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Discounted Price: </span>
                                                                    ₹ {inventoryDetails?.discounted_price}

                                                                </li>

                                                            )
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.sales_price !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Sales Price : </span>
                                                                    ₹ ₹{inventoryDetails.sales_price}

                                                                </li>

                                                            )
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.shipping_charges !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Shipping Charges : </span>
                                                                    ₹ {inventoryDetails?.shipping_charges}

                                                                </li>
                                                            )
                                                        )
                                                    }

                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.qty_unit !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Quantity Unit </span>
                                                                    {inventoryDetails?.qty_unit}
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                </ul>
                                                <ul className="list-unstyled pt-4">
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.author !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Author : </span>  {inventoryDetails?.author}

                                                                </li>
                                                            )
                                                        )
                                                    }


                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.net_weight !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Net Weight : </span>
                                                                    {inventoryDetails?.net_weight} grams
                                                                </li>
                                                            )
                                                        )
                                                    }

                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.gst_title !== null && (
                                                                <li><span className="fw-bold detail-same">GST : </span>     {inventoryDetails?.gst_title} %</li>
                                                            )
                                                        )
                                                    }


                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.net_quantity !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Quantity : </span>
                                                                    {inventoryDetails?.net_quantity} pcs
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.barcode !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Barcode : </span>
                                                                    {inventoryDetails?.barcode}

                                                                </li>
                                                            )
                                                        )
                                                    }

                                                    {
                                                        inventoryDetails && (
                                                            inventoryDetails.discount !== null && (
                                                                <li>
                                                                    <span className="fw-bold detail-same">Discount : </span>
                                                                     {inventoryDetails?.discount} %

                                                                </li>
                                                            )
                                                        )
                                                    }

                                                </ul>
                                            </div>
                                        </div>

                                        <div id="reviews" className="tab-pane fade">
                                            {
                                                productReviews.length !== 0 ?
                                                    <div className="border rounded p-3">
                                                        <div className="card-title fs-normal fs-5 mb-3">Product Ratings and Reviews</div>
                                                        <hr />
                                                        <p>Real Images and videos from customers</p>
                                                        <div className="d-flex flex-wrap gap-2 justify-content-start">
                                                            <div className="review-img-contain">
                                                                <img src="../assets/images/review_images/img1.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                                            </div>
                                                            <div className="review-img-contain">
                                                                <img src="../assets/images/review_images/img2.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                                            </div>
                                                            <div className="review-img-contain">
                                                                <img src="../assets/images/review_images/img3.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                                            </div>
                                                            <div className="review-img-contain">
                                                                <img src="../assets/images/review_images/img4.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                                            </div>
                                                            <div className="review-img-contain">
                                                                <img src="../assets/images/review_images/img5.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                                            </div>
                                                        </div>
                                                        <hr />

                                                        {
                                                            productReviews.slice(0, 2).map((review, i) => {
                                                                return (
                                                                    <Review
                                                                        key={i}
                                                                        img_url={img_url}
                                                                        review_image={review.image}
                                                                        rating={review.rating}
                                                                        user_name={review.user_name}
                                                                        review_comment={review.review_comment}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                        <div className="mt-2 view-all-products"><Link to="" className="fs-5 text-decoration-none" data-bs-toggle="offcanvas" data-bs-target="#review-offcanvas">View All Reviews <i className="bi bi-chevron-right"></i></Link></div>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="fw-bold content">No Reviews Yet</div>
                                                        {
                                                            orders?.order_status === 4 &&
                                                            <Link to={'/givereview'} state={{ 'product_id': inventoryDetails.product_id, 'item_type': inventoryDetails.item_type, 'vendor_id': inventoryDetails.vendor_id }} className='px-3'><button className='btn btn-danger'>Give Review</button></Link>
                                                        }
                                                    </>
                                            }
                                            {/* <ul className="list-unstyled">
                                                <li className="fw-bold content">No Reviews Yet</li>
                                            </ul> */}
                                        </div>
                                        <div id="book" className="tab-pane fade">
                                            <ul className="list-unstyled">
                                                <li className="fw-bold content">PDF View of the book</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Accordion for smaller screens */}
                                    {/* <div className="accordion d-md-none" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOverview">
                                                    Overview
                                                </button>
                                            </h2>
                                            <div id="collapseOverview" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="fw-bold product-bold">
                                                            Product Description
                                                        </li>
                                                        <li className="product-normal">
                                                            As Per New Revised Syllabus For 2025 Himachal Board
                                                            Exams
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTable">
                                                    Table of Content
                                                </button>
                                            </h2>
                                            <div id="collapseTable" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="d-flex justify-content-between">
                                                        <ul className="list-unstyled">
                                                            <li className="fw-bold product-bold">
                                                                Product Details
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Author:</span>
                                                                JMD
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Book Format:</span>
                                                                Paperback
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Printer Details:</span>
                                                                Maanglik Printers Jalandhar
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Publish Year:</span>
                                                                2024
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Brand:</span>
                                                                120
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdditional">
                                                    Additional Information
                                                </button>
                                            </h2>
                                            <div id="collapseAdditional" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="d-flex justify-content-between">
                                                        <ul className="list-unstyled">
                                                            <h6 className="fw-bold product-bold">
                                                                Product Details
                                                            </h6>
                                                            <li>
                                                                <span className="fw-bold detail-same">Classes:</span>
                                                                Class 10+2
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Edition:</span>
                                                                Latest
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Min Quantity:</span>
                                                                01
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">HSN Code:</span>
                                                                4901
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">BMrp:</span>
                                                                ₹330.00
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Discounted Price:</span>
                                                                ₹297.00
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Shipping Charges:</span>
                                                                ₹20
                                                            </li>
                                                        </ul>
                                                        <ul className="list-unstyled pt-4">
                                                            <li>
                                                                <span className="fw-bold detail-same">Author:</span>
                                                                JMD
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Net Weight:</span>
                                                                525.00 grams
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Quantity Unit:</span>
                                                                04
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Barcode:</span>
                                                                97893899351
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">Discount:</span>
                                                                10.00
                                                            </li>
                                                            <li>
                                                                <span className="fw-bold detail-same">GST:</span> 0
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseReviews">
                                                    Reviews
                                                </button>
                                            </h2>
                                            <div id="collapseReviews" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="fw-bold content">No Reviews Yet</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBook">
                                                    Book PDF
                                                </button>
                                            </h2>
                                            <div id="collapseBook" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="fw-bold content">PDF View of the book</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Related Product */}
                {allInventory?.length > 3 ? <CarouselSlider items={productCarouselItems} title='Related Products' auto_play={true} display_dots={false} /> : <section>
                    <div className="container">

                        <div className="row g-3 mt-3">

                            {
                                allInventory &&
                                allInventory.map((card, i) => {
                                    const { last_cat_id, id, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, itemExistInCart, itemExistInWishlist } = card;
                                    return (
                                        <div key={i} className="col-md-4 col-sm-4 col-6">
                                            <ProductCard
                                                id={id}
                                                product_id={product_id}
                                                image={`${img_url}/${folder}/${image}`}
                                                title={product_name}
                                                mrp={mrp}
                                                discounted_price={discounted_price}
                                                shipping_charges={shipping_charges}
                                                type={type}
                                                text={convertToPlain(description)}
                                                Card={card}
                                                itemExistInCart={itemExistInCart}
                                                itemExistInWishlist={itemExistInWishlist}
                                                last_cat_id={last_cat_id}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>}
                {/* <CarouselSlider items={productCarouselItems} title='Related Products' auto_play={true} display_dots={false} /> */}
                {/* <CrousalCard title='Related Products' mostOrderBook={homeInventory} />
                <CrousalCard title='Related Products' mostOrderBook={homeInventory} /> */}

            </main>

        </>
    )
}

export default Product
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { PassValidation } from './FormValidation';

import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import loginImage from '../assets/images/login.png';

import Header from '../layout/Header';

const ResetForgotPassword = () => {
  const { api_url, setIsLoading } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [passErrors, setPassErrors] = useState({});
  const [passData, setPassData] = useState({
    new_password: ''
  });
  const [passShow1, setPassShow1] = useState(true);
  const [passShow2, setPassShow2] = useState(true);
  const [type1, setType1] = useState('password')
  const [type2, setType2] = useState('password')
  const togglePassword = (flag) => {
    if (flag === 1 && type1 === 'password') {
      setType1('text');
      setPassShow1(!passShow1)
    } else if (flag === 1 && type1 === 'text') {
      setType1('password');
      setPassShow1(!passShow1)
    }

    if (flag === 2 && type2 === 'password') {
      setType2('text');
      setPassShow2(!passShow2)
    } else if (flag === 2 && type2 === 'text') {
      setType2('password');
      setPassShow2(!passShow2)
    }
  }

  const handleChange = (e) => {
    setPassData({ ...passData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = PassValidation(passData);
    setPassErrors(errors);

    if (Object.keys(errors).length === 0) {
      const data = {
        user_id: location.state.user_id,
        new_password: passData.new_password
      }
      setIsLoading(true)
      axios.post(`${api_url}/resetPassword`, data)
        .then((res) => {
          if (res.data.success === 1) {
            setIsLoading(false)
            toast.success(`${res.data.message} `, {
              theme: 'colored',
              autoClose: 1500,
            })
            let timer = setTimeout(() => {
              navigate('/login', { state: { user_id: location.state.user_id } });
            }, 2000);
            return () => clearTimeout(timer);
          }
          else {
            setIsLoading(false)
            toast.error(`${res.data.message} `, {
              theme: 'colored',
              autoClose: 1500,
            })
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error('Axios Error', {
            theme: 'colored',
            autoClose: 1500,
          })
          console.log(err)
        })

      // try {
      //   let res = axios.post(`${api_url}/resetPassword`, data);
      //   if (res.data.success === 1) {
      //     toast.success(`${res.data.message} 🤩`, {
      //       theme: 'colored',
      //       autoClose: 1500,
      //     })
      //     let timer = setTimeout(() => {
      //       navigate('/login');
      //     }, 2000);
      //     return () => clearTimeout(timer);
      //   }
      //   else {
      //     toast.error(`${res.data.message} `, {
      //       theme: 'colored',
      //       autoClose: 1500,
      //     })
      //   }
      // } catch (error) {
      //   toast.error('Axios Error', {
      //     theme: 'colored',
      //     autoClose: 1500,
      //   })
      // }
    }
  }

  return (
    <>
      <Header />
      <main id='main' className='mt-5 pt-5'>
        <section id="login" >
          <div className="container">
            <main id='main' className=''>
              <section id="login" className='h-100'>
                <div className="container">
                  <div className="row g-4 mt-md-5">
                    <div className="col-md pt-3">
                      <img src={loginImage} alt='login' className="img-fluid d-none d-md-block" />
                    </div>
                    <div className="col-md">
                      <div className="border border-1 border-dark rounded-2 p-2 shadow-md user bg-white">
                        <h3 className="text-center pt-3">Create New Password</h3>
                        <form className="p-4" onSubmit={handleSubmit}>
                          <div className="form-group pt-3">
                            <label htmlFor="pwd">New Password</label>
                            <div className="input-group">
                              <input type={type1} id="password" placeholder="********" className="form-control" onChange={handleChange} name='new_password' value={passData.new_password} />

                              <span className="input-group-text bg-white cursor-pointer" onClick={() => togglePassword(1)}> {passShow1 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} </span>
                            </div>
                            <div className="text-danger">{passErrors.new_password}</div>
                          </div>
                          <div className="form-group pt-3">
                            <label htmlFor="pwd">Confirm Password</label>
                            <div className="input-group">
                              <input type={type2} className="form-control" onChange={handleChange} name='cpassword' placeholder="********" value={passData.cpassword} />

                              <span className="input-group-text bg-white cursor-pointer" onClick={() => togglePassword(2)}> {passShow2 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} </span>
                            </div>
                            <div className="text-danger">{passErrors.cpassword}</div>
                          </div>
                          <button type="submit" className="mt-4 btn btn-danger d-block w-100">Update Password</button>
                          <Link to='/login' className="mt-2 btn btn-white border-2 border-danger text-danger d-block w-100">Go Back</Link>
                          <h6 className="mt-3 mb-1">Create an account with <span className="fw-bold">e-Vyapari </span>
                            <span>
                              <Link to={'/signup'} className=" text-danger fw-bold text-decoration-none">SignUp</Link></span></h6>
                        </form>
                      </div>
                    </div>
                  </div>

                </div>
              </section>
            </main>
           
          </div>
        </section>
      </main>

      {/* <Copyright /> */}
      <ToastContainer />
    </>
  )
}

export default ResetForgotPassword
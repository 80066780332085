import React, { useRef, useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Loading from './loading/Loading';
import TermsConditions from './TermsConditions';


const Step3 = () => {
    const navigate = useNavigate();
    const { registerData, changeHandler, setCurrentStep, isLoading, setIsLoading, api_url } = useGlobalContext();
    const [verified, setVerified] = useState(false);
    const [passShow1, setPassShow1] = useState(true);
    const [passShow2, setPassShow2] = useState(true);
    const [type1, setType1] = useState('password');
    const [type2, setType2] = useState('password');
    const location = useLocation();
    const recaptcha = useRef();
    const togglePassword = (flag) => {
        if (flag === 1 && type1 === 'password') {
            setType1('text');
            setPassShow1(!passShow1)
        } else if (flag === 1 && type1 === 'text') {
            setType1('password');
            setPassShow1(!passShow1)
        }

        if (flag === 2 && type2 === 'password') {
            setType2('text');
            setPassShow2(!passShow2)
        } else if (flag === 2 && type2 === 'text') {
            setType2('password');
            setPassShow2(!passShow2)
        }
    }

    // console.log("path:", location.pathname)

    // Form Validatioon
    var emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
    var passwordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    const { email, password } = registerData;

    const [errors, setErrors] = useState({});
    const step3_errors = {};

    // recaptcha function
    function onClickCaptcha(value) {
        setVerified(!verified);
    };

    const handleRegister = (e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue()
        let cpassword = document.getElementById('cpassword').value;

        // if (email === '') {
        //     step3_errors.email = "Enter Email";
        // }
        if (email !== '' && !emailRegex.test(email)) {
            step3_errors.email = "Invalid Email Formate";
        }

        if (password === '') {
            step3_errors.password = "Password Required";
        }
        else if (password.length < 6) {
            step3_errors.password = "Password must contain 6 character";
        }

        //confirm password
        if (!cpassword) {
            step3_errors.cpassword = "Please Re-enter Password";
        } else if (cpassword !== password) {
            step3_errors.cpassword = "Password Did Not Match";
        }

        setErrors(step3_errors);


        // .........................................................................................................//
        if (Object.keys(step3_errors).length === 0) {
            if (!captchaValue) {
                alert('Please verify the reCAPTCHA!')
            }
            else {
                setIsLoading(true);
                // console.log(registerData);
                axios.post(`${api_url}/register`, registerData,
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                )
                    .then(res => {
                        if (res.data.success === 1) {
                            if (res.data.data.exist === true) {
                                setIsLoading(false);
                                toast.error(res.data.message, {
                                    autoClose: 1500,
                                    theme: 'colored'
                                })
                                let timer = setTimeout(() => {
                                    navigate('/login')
                                }, 2000);
                                return () => clearTimeout(timer);
                            }
                            else {
                                setIsLoading(false);
                                toast.success(res.data.message, {
                                    autoClose: 1500,
                                    theme: 'colored'
                                });
                                let timer = setTimeout(() => {
                                    navigate('/otpverification', { state: { user_id: res.data.data.user_id, otp: res.data.data.otp, prevPath: location.pathname } });
                                }, 2000);
                                return () => clearTimeout(timer);
                            }
                        } else {
                            toast.error(res.data.message, {
                                autoClose: 1500,
                                theme: 'colored'
                            })
                            setIsLoading(false);
                        }
                    })
                    .catch(err => {
                        // console.log("error is there:", err)
                        toast.error('Axios Error 😭😭', {
                            autoClose: 1500,
                        });
                        setIsLoading(false);
                    })
            }

        }
        // .........................................................................................................//
        console.log(errors);
    };
    return (
        <>
            {isLoading ? <Loading /> : null}
            <div className="tab text-start">
                <div className="form-group text-start">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" value={registerData.email} onChange={(e) => changeHandler(e)} id="email" placeholder="Enter Email" name="email" />
                    <div className='text-danger s-font2'>{errors.email}</div>

                </div>
                <div className="form-group pt-3 text-start">
                    <label htmlFor="pwd">Choose Password</label>
                    <div className="input-group">
                        <input type={type1} id="password" name="password" value={registerData.password} onChange={(e) => changeHandler(e)} placeholder="Must have atleast 6 characters" className="form-control" />

                        <span className="input-group-text bg-white cursor-pointer" onClick={() => togglePassword(1)}> {passShow1 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} </span>
                    </div>

                </div>
                <div className='text-danger s-font2'>{errors.password}</div>
                <div className="form-group pt-3 text-start">
                    <label htmlFor="pwd">Confirm Password</label>
                    <div className="input-group">
                        <input type={type2} id="cpassword" name="cpassword" onChange={(e) => changeHandler(e)} placeholder="Must have atleast 6 characters" className="form-control" />

                        <span className="input-group-text bg-white cursor-pointer" onClick={() => togglePassword(2)}> {passShow2 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} </span>

                    </div>
                    <div className='text-danger s-font2'>{errors.cpassword}</div>
                </div>
                <div className="form-check text-start">
                    <input className="form-check-input " checked type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" for="flexCheckDefault">
                        I agree to accept the terms and conditions
                        <span className='cursor-pointer text-decoration-none text-danger' data-bs-toggle="modal" data-bs-target="#ReadMoreModal"> READ.</span>
                    </label>
                </div>
                {/* <div>
                    <div className="form-group pt-3">
                        <label htmlFor="pwd">Choose Password</label>
                        <input type="password" id="password" name="password" placeholder="********" className="form-control" />
                        <span className="input-group-text bg-white cursor-pointer" onClick={togglePassword}> {passShow1 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} </span>
                    </div>
                    <div className="form-group pt-3">
                        <label htmlFor="pwd">Confirm Password</label>
                        <input type="password" id="password" name="password" placeholder="********" className="form-control" />
                    </div>
                </div> */}

                {/* <div className="form-floating">
                    <input type="email" id="form3Example4" name="email" value={registerData.email} onChange={(e) => changeHandler(e)} className="form-control" placeholder='' />
                    <label className="form-label" for="form3Example4">Email</label>
                    <div className='text-danger s-font2'>{errors.email}</div>
                </div>
                <div className="form-floating my-3">
                    <input type={type1} id="password" name="password" value={registerData.password} onChange={(e) => changeHandler(e)} className="form-control" placeholder='' />
                    <label className="form-label" for="password">Choose a Strong Password</label>
                    <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={() => togglePassword(1)}>{passShow1 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow1 ? 'Show' : 'Hide'}</span>
                    <div className='text-danger s-font2'>{errors.password}</div>
                </div>
                <div className="form-floating my-3">
                    <input type={type2} id="cpassword" name="cpassword" className="form-control" placeholder='' />
                    <label className="form-label" for="cpassword">Confirm Password</label>
                    <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={() => togglePassword(2)}>{passShow2 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow2 ? 'Show' : 'Hide'}</span>
                    <div className='text-danger s-font2'>{errors.cpassword}</div>
                </div> */}
            </div>

            <TermsConditions />

            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
            <div className="row">
                <div className="col">
                    <button type="button" id="prevBtn" onClick={() => setCurrentStep(2)} className="mt-4 btn border-dark fw-bold w-100">Back</button>
                </div>
                <div className="col">
                    <button type="button" onClick={(e) => handleRegister(e)} id="nextBtn" className="mt-4 btn btn-danger w-100 ">Register</button>
                </div>
            </div>



            {/* <div className="overflow-auto mt-3">
                <div className="float-end">
                    <button type="button" className="btn btn-danger me-2" id="prevBtn" onClick={() => setCurrentStep(2)}>Previous</button>
                    <button type="button" className="btn btn-danger" onClick={(e) => handleRegister(e)} id="nextBtn">Register</button>
                    <button type="button" className="btn btn-danger" onClick={(e) => handleRegister(e)} disabled={!verified} id="nextBtn">Register</button>
                </div>
            </div> */}
        </>
    )
}
export default Step3
import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import Product from '../components/Product'

const ProductPage = () => {
    return (
        <>
            <Header /><div className="mt-5 pt-5">
                <Product /></div>
            <Footer />
        </>
    )
}

export default ProductPage
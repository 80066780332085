import React from 'react'
import classroom from '../assets/images/school_banner_2.jpeg'
const SchoolCard = ({ school_name, school_image, school_banner, key, school_code }) => {
    // const tesst = 'Saraswati Vidya Mandir Gagal'
    const itemStyle = { height: 'calc(100% - 20px) ' }
    return (

        <div className="item mx-1 mt-2 " key={key} style={itemStyle} >
            {/* {tesst.length} */}
            <div className="card border-dark shadow-btm rounded-4 h-100">
                <div className="card-body">
                    <div className="overflow-hidden mx-auto d-flex align-items-center w-100 rounded-4" style={{ height: '140px', maxWidth: '242px' }}>
                        {
                            school_banner !== null ?
                                <img src={school_image} className="d-block img-fluid mx-auto rounded-4" width='100%' height='100%' alt="school_banner" />
                                :
                                <img src={classroom} className="d-block img-fluid mx-auto rounded-4" width='100%' height='100%' alt="school_banner" />
                        }
                    </div>
                    {/* <h5 className="text-center fw-bold mt-2">{school_name.length }</h5> */}
                    <h5 className="text-center fw-bold mb-0 mt-3">School code : {school_code}</h5>
                    <h5 className="text-center fw-semibold  mt-0">{school_name}</h5>

                </div>
            </div>

        </div>

    )
}

export default SchoolCard
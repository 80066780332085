import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import ProductCard from '../components/ProductCard'

import Header from '../layout/Header'
import Footer from '../layout/Footer'
import { useGlobalContext } from '../contexts/GlobalContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useInventoryContext } from '../contexts/InventoryContext'
import CarouselSlider from '../components/CarouselSlider/CarouselSlider'
import logo from '../assets/images/main-logo.svg';
const VendorShop = () => {
    const [vendorDetails, setVendorDetails] = useState({});
    const { setIsLoading, api_url, img_url, categoryData, allInventory, getAllInventory } = useGlobalContext();
    const { vendorInventory, getVendorInventory, getLastCategories, lastCategories, vendorPageData, setLastCategories } = useInventoryContext();
    const handleLastCategory = (subcat3_id) => {
        // getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'), null, null, null, subcat3_id)
    }
    const [SelectActivesubcat, setSelectActivesubcat] = useState(null);
    const [Activecat2, setActiveSubcat2] = useState([]);

    const clearFilters = () => {

    }
    const params = useParams();
    const location = useLocation();

    //getVendor
    const getVendor = async () => {
        const data = {
            'vendor_id': location.state?.vendor_id
        }
        setIsLoading(true)
        try {
            let res = await axios.post(`${api_url}/vendorDetails`, data);
            if (res.data.success === 1) {
                setVendorDetails(res.data.data)
            }
        } catch (error) {
            toast.error('Something Wrong Happened', {
                theme: "colored",
                autoClose: 2000,
            });
        } finally {
            setIsLoading(false)
        }
    }

    const handleNextPage = () => {
        getVendorInventory(vendorPageData.next_page_url, location.state?.vendor_id)
        window.scrollTo(0, 600)
    }

    const handlePreviousPage = () => {
        getVendorInventory(vendorPageData.prev_page_url, location.state?.vendor_id)
        window.scrollTo(0, 600)
    }

    // console.log(vendorInventory);
    // console.log(allInventory.length)
    useEffect(() => {
        getVendor();
        getVendorInventory(`${api_url}/vendorInventory`, location.state?.vendor_id);
    }, [])

    const carouselItems = vendorInventory.map((card, i) => {
        const { id, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
        return (
            vendor_id === location.state?.vendor_id ?
                <ProductCard
                    key={i}
                    id={id}
                    product_id={product_id}
                    image={`${img_url}/${folder}/${image}`}
                    title={product_name}
                    mrp={mrp}
                    discounted_price={discounted_price}
                    shipping_charges={shipping_charges}
                    type={type}
                    text={(description)}
                    Card={card}
                    itemExistInCart={itemExistInCart}
                    itemExistInWishlist={itemExistInWishlist}
                />
                : null
        )
    })
    const [selectedSubcategories, setSelectedSubcategories] = useState(null);
    const [selectedcategories, setSelectedcategories] = useState(null);
    const [openSubcatId, setOpenSubcatId] = useState(null);
    const [currentSubcat2, setCurrentSubcat2] = useState([]);
    const handleSubcatClick = (subcat_id, subcat2) => {
        setCurrentSubcat2(subcat2); // Update current subcat2 based on clicked subcat
        setOpenSubcatId(subcat_id); // Optionally set the open subcat
    };
    const handleCardClick = (subcat, cat_id) => {
        // Set the selected subcategories based on the clicked card
        setSelectedcategories(cat_id)
        setSelectedSubcategories(subcat);
        setLastCategories([]);
    };
    return (
        <>
            <Header />
            <main id='main' className='space-top mt-5'>
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-4 d-block d-md-none">
                        <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                    </div>
                </div>
                {/* <!-- section-1-banner start --> */}
                <section id="shop-banner" className="border-bottom border-dark">
                    <div id="carouselExampleCaptions" className="carousel overflow-hidden" style={{ maxHeight: '500px !important' }}>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner ">
                            <div className="carousel-item active">
                                <div className='banner_img_contain'>
                                    {
                                        vendorDetails?.site_background_img ?
                                            <img src={`${img_url}/${vendorDetails?.folder}/${vendorDetails?.site_background_img}`} className="object-fit-fill" width='100%' height='100%' alt="banner" />
                                            :
                                            <img src={require('../assets/images/Landscape Image.png')} className="object-fit-fill" width='100%' height='100%' alt="banner" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- section-2-shoptitle-start --> */}
                <section id="shop-title" className="py-sm-5 py-2">
                    <div className="container py-4">
                        <div className="row mx-1 g-1">
                            <div className="col-3">
                                <img id="shop-icon" src={require('../assets/images/book shop.png')} alt="shop-icon" className=" img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-6 my-auto">
                                <h2 className='fw-bold mb-1'>{vendorDetails?.username}</h2>

                                <div className=" text-danger">
                                    <span><strong className="me-2">{vendorDetails?.total_vendor_inventory}</strong>Products</span>
                                </div>

                            </div>
                            <div className="col-3 my-auto">
                                {/* <button className='btn btn-danger'><span className='d-sm-inline d-none'> +</span> Follow Us</button> */}
                            </div>
                        </div>

                    </div>

                </section>
                {/* <section id="shop-title" className="">
                    <div className="container ">
                        <div className="row g-3 mt-5">
                            {categoryData.length !== 0 &&
                                categoryData.map((ctg, i) => {
                                    const { cat_name, cat_id, subcat } = ctg;
                                    let imageToRender;
                                    switch (i) {
                                        case 0:
                                            imageToRender = <img src={require('../assets/images/travelaccessories.png')} alt="" className='w-100 categorycardimg h-100' />;
                                            break;

                                        case 1:
                                            imageToRender = <img src={require('../assets/images/booksimg.png')} alt="" className='w-100 categorycardimg h-100' />;
                                            break;

                                        case 2:
                                            imageToRender = <img src={require('../assets/images/Knwoledge 1.png')} alt="" className='w-100 categorycardimg h-100' />;
                                            break;

                                        default:
                                            imageToRender = null;
                                            break;
                                    }
                                    return (
                                        <div className="col-4 h-100">
                                            <Link to="" className=" btn rounded-5 w-100 p-0 h-100" key={cat_id} onClick={() => handleCardClick(subcat, cat_id)} >
                                                <div className="card">
                                                    <div className="bg d-flex flex-column justify-content-between align-items-center">
                                                        <div className='top-catagory-card-img mx-auto overflow-hidden h-100'>
                                                            {imageToRender}
                                                        </div>
                                                        <button
                                                            className="nav-link fw-bold"
                                                            href="#"

                                                        >
                                                            {i === 0 ? 'Travel Accessories' : cat_name}
                                                        </button>

                                                 
                                                    </div>
                                                    <div className="blob"></div>

                                                </div>
                                            </Link>
                                        </div>)
                                })}
                        </div>

                    </div>

                </section>

                <section id="book-cat">
                    <div class="container mb-5 mt-2">


                        <div>
                            {selectedSubcategories && (
                                <div className="d-flex border border-2 border-dark rounded-3 mt-3 align-items-center">
                                    <div className="selected-subcategories py-2">
                                        {selectedSubcategories.map((subcat) => (
                                            <div
                                                className={`btn border border-2 market-btn ms-2 mb-2 ${openSubcatId === subcat.subcat_id ? 'text-danger border-danger active' : 'border-dark'}`}
                                                key={subcat?.subcat_id}
                                                onClick={() => handleSubcatClick(subcat.subcat_id, subcat.subcat2)}
                                            >
                                                {subcat?.subcat_name}

                                            </div>
                                        ))}

                                    </div>
                                </div>
                            )}

                            {selectedSubcategories && (
                                selectedSubcategories.map((subcat) => (
                                    <div key={subcat.subcat_id}>
                                        {openSubcatId === subcat.subcat_id && currentSubcat2.length > 0 && (
                                            <div className="d-flex border border-2 mt-3 border-dark rounded-3 align-items-center position-relative">
                                                <div className="selected-subcategories py-2 px-3">
                                                    <h5 className='text-danger'>
                                                        {subcat?.subcat_name}
                                                    </h5>
                                                    <div className='d-flex gap-2 flex-wrap'>

                                                        {currentSubcat2.map((subcat2Item) => (
                                                            <div className={`btn border border-2 ms-2 mb-2 market-btn ${subcat2Item.subcat2_id === SelectActivesubcat ? 'text-danger  border-danger active' : ' border-dark'}`} key={subcat2Item.subcat2_id} onClick={() => { getLastCategories(selectedcategories, subcat.subcat_id, subcat2Item.subcat2_id); setSelectActivesubcat(subcat2Item.subcat2_id) }} >{subcat2Item.subcat2_name}</div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <button className='position-absolute end-0 top-0 btn btn-sm btn-dark p-0 px-1' onClick={() => { getLastCategories(null, null, null); clearFilters(); setOpenSubcatId(null) }}><i class="ti ti-x"></i></button>
                                            </div>
                                        )}
                                    </div>

                                ))
                            )}
                            {
                                lastCategories.length !== 0 &&
                                <div className=" border border-dark rounded-3 mt-3 d-flex border-bottom-3 flex-wrap position-relative">
                                    {
                                        lastCategories.map((cat, i) => {
                                            const { id, title } = cat;
                                            return (
                                                <div className=' border-gray  py-1 '>
                                                    <span className={`btn border border-2 ms-2 mb-2 market-btn ${Activecat2 === id ? 'text-danger  border-danger active' : 'border-dark'} `} onClick={() => { handleLastCategory(id); setActiveSubcat2(id) }}>{title}</span>
                                                </div>

                                            )
                                        })
                                    }
                                    <button className='position-absolute end-0 top-0 btn btn-sm btn-dark p-0 px-1' onClick={() => clearFilters()}><i class="ti ti-x"></i></button>
                                </div>
                            }
                        </div>
                    </div>
                </section> */}


                {/* New Arrivals */}
                {/* <CarouselSlider items={carouselItems} title='New Arrivals' auto_play={false} display_dots={true} /> */}

                {/* <!-- product and category section --> */}
                <section id="product" className="my-5">

                    <div className="container">


                        {
                            vendorInventory.length !== 0 &&
                            <div className="row g-3">
                                {
                                    vendorInventory &&
                                    vendorInventory.map((card, i) => {
                                        const { id, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
                                        return (
                                            // vendor_id === location.state?.vendor_id ?
                                                <div key={i} className="col-lg-3 col-md-4 col-6">
                                                    <ProductCard
                                                        id={id}
                                                        product_id={product_id}
                                                        image={`${img_url}/${folder}/${image}`}
                                                        title={product_name}
                                                        mrp={mrp}
                                                        discounted_price={discounted_price}
                                                        shipping_charges={shipping_charges}
                                                        type={type}
                                                        text={(description)}
                                                        Card={card}
                                                        itemExistInCart={itemExistInCart}
                                                        itemExistInWishlist={itemExistInWishlist}
                                                    />
                                                </div>
                                                // : null
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>

                </section>


                {/* <!-- //pagination --> */}
                <div className='d-flex justify-content-end me-5'>
                    <ul className='pagination'>
                        {
                            vendorPageData.prev_page_url ? (
                                <li className='page-item'>
                                    <button className='page-link' onClick={handlePreviousPage}>
                                        <span className='text-danger'>Previous</span>
                                    </button>
                                </li>
                            ) : null
                        }
                        {
                            vendorPageData.next_page_url ? (
                                <li className='page-item'>
                                    <button className='page-link' onClick={handleNextPage}>
                                        <span className='text-danger'>Next</span>
                                    </button>
                                </li>
                            ) : null
                        }
                    </ul>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default VendorShop
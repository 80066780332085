import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
const ProfileCards = (props) => {
    const [showModal, setShowModal] = useState(false);
    const handleDeleteClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const { imgsrc, title, text, link } = props;
    return (
        <>
            <div>
                {link === 'deleteaccount' ?
                    <><Link to="#" onClick={handleDeleteClick} className="text-decoration-none text-dark card shadow-btm rounded-3 border-dark mb-3">

                        <div className="card-body d-flex ">
                            <i className={imgsrc}></i>
                            <div className="ms-2 d-flex align-items-center justify-content-between w-100">
                                <div>

                                    <h5 className='text-start mb-0'> {title}</h5>
                                    <p className='text-start'>{text}</p>
                                </div>
                                <i className="ms-auto text-set ti ti-caret-right-filled fs-5"></i>
                            </div>
                        </div>
                    </Link><Modal show={showModal} onHide={handleCloseModal} centered>
                            <Modal.Header className='bg-danger' closeButton>
                                <Modal.Title className='text-white fw-bold'>Confirm Account Deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='fs-6'>

                                    Are you sure you want to delete your account?
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Cancel
                                </Button>
                                <Link to={`/${link}`} onClick={() => { handleCloseModal(); window.scrollTo({ top: 0, behavior: 'instant' }); }}>
                                    <Button variant="danger">
                                        Delete Account
                                    </Button>
                                </Link>
                            </Modal.Footer>
                        </Modal></>
                    : <Link to={`/${link}`} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className="text-decoration-none text-dark card shadow-btm rounded-3 border-dark mb-3">

                        <div className="card-body d-flex ">
                            <i className={imgsrc}></i>
                            <div className="ms-2 d-flex align-items-center justify-content-between w-100">
                                <div>

                                    <h5 className='text-start mb-0'> {title}</h5>
                                    <p className='text-start'>{text}</p>
                                </div>
                                <i className="ms-auto text-set ti ti-caret-right-filled fs-5"></i>
                            </div>
                        </div>
                    </Link>}
            </div>
        </>
    )
}

export default ProfileCards
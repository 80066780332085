import React, { useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { useGlobalContext } from '../contexts/GlobalContext'
import * as Icon from 'react-bootstrap-icons';
import { useInventoryContext } from '../contexts/InventoryContext'
import notfound from '../assets/images/imagenofound.png'

const ProductCard = (props, { key }) => {

    const { last_cat_id, id, product_id, image, title, mrp, discounted_price, text, type, shipping_charges, itemExistInCart, itemExistInWishlist } = props;
    const [showModal, setShowModal] = useState(false);
    const { handleAddToWishlist, removeWishlistItem, handleBuyNow, setWishlistLength } = useGlobalContext();
    const { handleAddToCart, setPageInventory, setHomeInventoryNew, setHomeInventory, setAllInventory } = useInventoryContext();
    // const [existInCart, setExistInCart] = useState(itemExistInCart)
    const navigate = useNavigate();
    const location = useLocation();


    const clickSeeAllFeature = () => {
        // alert(id)
        navigate(`/seeallfeature/${id}`, { state: { 'last_cat_id': last_cat_id } })
        if (location.pathname.includes('/seeallfeature')) {
            setShowModal(false);
        }
        window.scrollTo(0, 0)
    }

    //for quick view
    const AddToCart = (id) => {
        handleAddToCart(id);
        setShowModal(false)
    }
    const handlewish = (product_id) => {

        handleAddToWishlist(id)
        setHomeInventory(cartItems =>
            cartItems.map(CartItem =>
                CartItem.id === product_id ? { ...CartItem, itemExistInWishlist: true } : CartItem
            )
        );
        setHomeInventoryNew(cartItems =>
            cartItems.map(category => ({
                ...category,
                products: category.products.map(product =>
                    product.id === product_id
                        ? { ...product, itemExistInWishlist: true }
                        : product
                )
            }))
        );
        setAllInventory(cartItems =>
            cartItems.map(CartItem =>
                CartItem.id === product_id ? { ...CartItem, itemExistInWishlist: true } : CartItem
            )
        );
        setPageInventory(cartItems =>
            cartItems.map(CartItem =>
                CartItem.id === product_id ? { ...CartItem, itemExistInWishlist: true } : CartItem
            )
        );

        // setWishlistLength(prev => prev + 1);

    }
    const tess = 'S.V.M. HIGH SCHOOL NIRMAND'
    const itemStyle = { height: 'calc(100% - 20px)!important ' }
    return (
        <>

            <div className="item px-2 h-100" style={itemStyle}>
                <div className="card border-dark shadow-md rounded-4 h-100 p-4 product-cart" >
                    <div className="card-body p-0">
                        <Link to={`/product/${id}`} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none text-dark'>
                            <div className="image-height-card overflow-hidden mx-auto">

                                <img
                                    src={image}
                                    className="d-block object-fit-fill img-fluid mx-auto rounded-5"
                                    alt=""
                                />
                            </div>
                            <h6 className="card-title d-md-block d-none text-start  fw-bold  mb-0">
                                {title.slice(0, 50)}{title > title.slice(0, 53) ? "..." : null}
                            </h6>
                            <h6 className="card-title d-block d-md-none text-start fw-bold mt-4 mb-0">
                                {title.slice(0, 30)}{title > title.slice(0, 33) ? "..." : null}
                            </h6>
                            <p className="card-text text-md-start mb-0 d-flex align-items-start text-start">
                                {/* {text.slice(0, 40)}{text > text.slice(0, 43) ? "..." : null} */}
                                {/* {text} */}
                                <span className='d-flex align-items-start justify-content-start text-start ms-0' dangerouslySetInnerHTML={{
                                    __html: text.replace(/<[^>]+>/g, '').length > 30
                                        ? text.replace(/<[^>]+>/g, '').slice(0, 30) + '...'
                                        : text.replace(/<[^>]+>/g, '')
                                }} />
                            </p>
                            <ul className="list-inline text-start my-0 py-0">
                                <li className="list-inline-item">
                                    <i className="fa-solid fa-star text-warning"></i>
                                </li>
                                <li className="list-inline-item">
                                    <i className="fa-solid fa-star text-warning"></i>
                                </li>
                                <li className="list-inline-item">
                                    <i className="fa-solid fa-star text-warning"></i>
                                </li>
                                <li className="list-inline-item">
                                    <i className="fa-solid fa-star text-warning"></i>
                                </li>
                                <li className="list-inline-item">
                                    <i className="fa-regular fa-star text-warning"></i>
                                </li>
                            </ul>
                            <div className="price-card text-start">
                                <span className=" text-secondary"
                                >{
                                        mrp &&
                                        <span>  ₹   <del className='text-secondary h6'>{mrp}</del></span>
                                    }</span
                                >

                                <span className="fw-bold">  ₹ {discounted_price}
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div
                        className="card-footer border-0 mt-0 d-flex gap-2 p-0 bg-transparent"
                    >
                        {
                            itemExistInWishlist ?

                                <NavLink to={'/wishlist'} className="btn  wishlist-btn btn-danger btn-sm d-flex align-items-center justify-content-center"><i className="fa-solid text-white fa-heart fs-5" /></NavLink>
                                :
                                <button onClick={() => handlewish(id)} className="btn wishlist-btn rounded-3 btn-dark btn-sm d-flex align-items-center justify-content-center"><i className="fa-regular fa-heart fs-5" /></button>

                        }

                        {
                            itemExistInCart ?
                                <>
                                    <NavLink to="/cart" className="btn btn-dark btn-sm d-flex  wishlist-btn btn-danger align-items-center justify-content-center"><i className="fa-solid fa-cart-shopping text-white fs-5" /></NavLink>

                                </>
                                :
                                <>
                                    <button onClick={(e) => { handleAddToCart(id); }} className="btn wishlist-btn btn-dark btn-sm d-flex align-items-center justify-content-center"><i className="fa-solid fa-cart-shopping text-light fs-5" /></button>
                                </>
                        }
                        <button onClick={() => handleBuyNow(id)} className="btn btn-dark btn-sm d-flex align-items-center justify-content-center w-100 rounded-2 fs-5 buy">Buy Now</button>

                    </div>
                </div>
            </div>



        </>
    )
}

export default ProductCard
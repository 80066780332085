import React from 'react'
import Header from '../layout/Header'
import Login from '../components/Login'

const LoginPage = () => {
    return (
        <>
            <Header />
            <div className="mt-5 pt-5">
            <Login/></div>
        </>
    )
}

export default LoginPage
import React from 'react'

function TermsConditions() {
    return (
        <>
            <div className="modal modal-lg fade" id="ReadMoreModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-danger text-white">
                            <h5 className="modal-title" id="exampleModalLabel">Terms and Condition</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p style={{ textAlign: 'left' }}>
                                All information, products and services displayed on the <u><b><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com</a></b></u>
                                website constitute an invitation to offer. Your order for purchase constitutes your
                                offer which shall be subject to the terms and conditions as listed below.<br />
                                <b>V4 MASTERS e-vyapari</b> has the right to accept or reject your offer without assigning any
                                reason thereof.<br /><br />
                                In order to use the <b><u><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com </a></u></b>website belonging to V4 MASTERS e-
                                vyapari., you have accepted the following terms and conditions given below.<br />
                                V4 MASTERS e-vyapari reserves the right to add, delete, alter or modify these terms and
                                conditions at any time.<br /><br />
                                You are therefore advised to read carefully these terms and conditions every time you use
                                the <u><b><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com</a></b></u> website.<br /><br />
                                <b>1.</b> We have made every effort to display the color of our products that appear on the site as
                                accurately as possible. However, as the actual colors you see will depend on your monitor,
                                we cannot guarantee that your monitor's display of color will be accurate.<br /><br />
                                <b>2.</b> The User certifies that he/she is at least 18 (eighteen) years of age <b>OR</b> has the consent of
                                a parent or legal guardian.<br /><br />
                                <b>3. V4 MASTERS e-vyapari</b>  will not be responsible for any damage suffered by users from
                                use of the services on evyapari.com. This without limitation includes loss of
                                revenue or data resulting from delays, non-deliveries, missed deliveries, or service
                                interruptions as may occur because of any act / omission of the vendor. This disclaimer of
                                liability also applies to any damages or injury caused by any failure of performance,
                                negligence, defect, deletion, error, omission, interruption, delay in operation or
                                transmission, computer virus, communication line failure, theft or destruction
                                or unauthorized access to, alteration of, or use of record, whether for breach of contract,
                                tortuous behavior, or under any other cause of action.<br /><br />
                                4. <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><b><u>www.evyapari.com</u></b></a> will take "Full Responsibility" of your Order, if the Order has
                                been shipped to any of the major cities, where our sellers use “Courier Companies" (e.g.
                                Trackon, Fed Ex, Delhivery, First Flight, India post or hand to hand pick up delivery) for
                                your order.<br /><br />
                                5. The prices and availability of products are subject to change without prior notice at the
                                sole discretion of <b>V4 MASTERS e-vyapari </b>or the Seller.<br /><br />
                                6. Request for cancellations of orders once placed on www.evyapari.com shall not
                                be entertained.<br /><br />
                                7.  <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><b><u>www.evyapari.com</u></b></a>  reserves the right to refuse or cancel any order placed for a
                                product that is listed at an incorrect price or for any other reason. This shall be regardless.<br /><br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsConditions
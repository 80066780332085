import React, { useEffect, useState } from 'react'

import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import Header from '../layout/Header';
import Loading from '../components/loading/Loading';
import ProfileSidebar from '../components/ProfileSidebar';
import logo from '../assets/images/main-logo.svg';

const MyAddress = () => {
   const { isLoading, schoolCode, setIsLoading, api_url, userId, userInformation, getUserInformation, allShippingAddress, setAllShippingAddress, getAllShippingAddress } = useGlobalContext();


   //get All Shipping Addresses
   useEffect(() => {
      getUserInformation();
      getAllShippingAddress();
   }, []);

   // Set Default Address
   const SetDefaultAddress = (address_id) => {
      const data = {
         user_id: userId,
         address_id: address_id
      }
      setIsLoading(true)
      axios.post(`${api_url}/defaultAddress`, data)
         .then(res => {
            if (res.data.success === 1) {
               setIsLoading(false)
               toast.success('Default Address is set Successfully', {
                  autoclose: 1500
               })
               setAllShippingAddress(AllAddress =>
                  AllAddress.map(Address =>
                     Address.id === address_id ? { ...Address, default_address: 1 } : { ...Address, default_address: 0 }
                  )
               )
            } else {
               setIsLoading(false)
               toast.success('Something Went Wrong', {
                  autoclose: 1500
               })
            }
         })
         .catch(err => {
            setIsLoading(false);
            toast.error('Axios Error', {
               autoclose: 1500
            })
            console.log(err)
         })

   }


   const handleAddressRemove = (address_id) => {
      const data = {
         address_id: address_id,
      }
      setIsLoading(true)
      axios.post(`${api_url}/removeAddress`, data)
         .then(res => {
            if (res.data.success === 1) {
               setIsLoading(false)
               toast.success(res.data.message, {
                  autoclose: 1000,
                  theme: 'colored'
               })
               setAllShippingAddress(AllAddress =>
                  AllAddress.filter(Address =>
                     Address.id !== address_id
                  )
               )
            } else {
               setIsLoading(false)
               toast.error(res.data.message, {
                  autoclose: 1500
               })
            }
         })
   }

   // useEffect(() => {
   //    if(!isAuthenticated) {
   //       navigate('/')
   //    }
   // }, [])


   return (
      <>
         {isLoading ? <Loading /> : null}
         <Header />
         <main id='main' className='mt-5 pt-5 space-top'>
            <div className="eVyapari-content w-100">
               <div className="text-center  mb-4 d-block d-md-none">
                  <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
               </div>
            </div>
            <section id="my-address" className="pb-5 clearfix text-start">
               <div className="container">
                  <ProfileSidebar />

                  {/*--------------Add New Address---------*/}
                  <div className="float-md-end bill-details w-75 text-start">
                     <div className="row">
                        <div className="col-md-12">
                           <div className="text-md-end ps-2">
                              <Link to={'/addaddress'} className="btn btn-danger border-dark shadow-btm px-5 py-2 rounded-3 mt-2 btn-font-size mb-2"><i className="ti ti-plus me-3" />Add New Address </Link>
                           </div>
                        </div>
                        <div className="col-md-12">
                           {
                              (allShippingAddress.length !== 0) ?
                                 allShippingAddress.map((address, i) => {
                                    const { id, user_id, name, phone_no, alternate_phone, school_name, default_address, village, city, address_type, state, district, post_office, pincode } = address;
                                    let address_id = id;
                                    return (
                                       <div key={i} className="col-md-12">
                                          <div className="mt-3 p-4 rounded-4 border-dark shadow-btm border border-dark ms-md-5">
                                             <div className="d-flex justify-content-between">

                                                <div className="form-check ps-2 text-start pb-2 ">
                                                   {/* <div
                                                      className="ms-3 text-dark d-flex"
                                                      onClick={() => SetDefaultAddress(address_id)}
                                                   >

                                                      <div className="form-check text-start cursor-pointer">
                                                         <input
                                                            type="checkbox"
                                                            value={id}
                                                            onClick={() => SetDefaultAddress(address_id)}
                                                            className="form-check-input border-danger fs-6 border-2"

                                                            id="check2"
                                                            name="condition"
                                                            title="Choose Shipping Address"
                                                            defaultValue="something"
                                                         />
                                                         &nbsp;Set As default Address
                                                      </div>
                                                   </div> */}

                                                   {
                                                      default_address === 0 &&
                                                      <>
                                                         <div
                                                            className="ms-1 text-dark d-flex"
                                                            // onClick={() => SetDefaultAddress(address_id)}
                                                         >

                                                            <div className="form-check text-start cursor-pointer">
                                                               <input
                                                                  type="checkbox"

                                                                  onClick={() => SetDefaultAddress(address_id)}
                                                                  className="form-check-input cursor-pointer border-danger fs-6 border-2"

                                                                  id="check2"
                                                                  name="condition"
                                                                  title="Choose Default Address"
                                                                  defaultValue="something"
                                                               />
                                                               &nbsp;Set As default Address
                                                            </div>
                                                         </div>

                                                         {/* <input type="checkbox" onClick={() => SetDefaultAddress(address_id)} className="form-check-input border-danger border-2" id="check2" title='Set As default Address' name="condition" defaultValue="something" /> */}
                                                      </>
                                                   }
                                                   {default_address === 1 && <div
                                                      className="ms-1 text-dark d-flex"

                                                   >

                                                      <div className="form-check text-start cursor-pointer">
                                                         <input
                                                            type="checkbox"
                                                            checked

                                                            className="form-check-input cursor-pointer border-danger fs-6 border-2"

                                                            id="check2"
                                                            name="condition"
                                                            title="Choose Default Address"
                                                            defaultValue="something"
                                                         />
                                                         &nbsp;Default Address
                                                      </div>
                                                   </div>}
                                                </div>
                                                <div>

                                                   {address_type === 1 ? ' ' : ` Pickup Address`}
                                                   {address_type === 1 && default_address === 1 && 'Default Address'}
                                                   {address_type === 2 && default_address === 1 && ' | Default Address'}

                                                </div>
                                             </div>
                                             <div className="d-flex text-align-center">
                                                <i className="ti ti-home-filled fs-1 text-danger me-3 ps-5" />
                                                <div>
                                                   <h6 className="fw-bold mb-0">{address_type === 1 ? 'Home' : `School (${schoolCode})  `}</h6>
                                                   <p>{name},</p>
                                                </div>
                                             </div>
                                             <div className="d-flex justify-content-between">
                                                <ul className="list-unstyled">
                                                   <li>{phone_no}{alternate_phone && `, ${alternate_phone}`}</li>
                                                   <li>{`${village}`}{address.address && `, Near ${address.address}`}</li>
                                                   <li><span className=" text-black fw-bold">Pin code:</span> {pincode}</li>
                                                   <li><span className="text-dark fw-bold">Post Office:</span> {post_office}</li>
                                                   <li className="text-dark fw-bold">{city && `${city}, `}{`${district}, ${state}`}</li>
                                                </ul>
                                                <div className="edit d-flex gap-2 flex-wrap justify-content-end">
                                                   {address_type === 1 &&
                                                      <>
                                                         <button data-bs-toggle="modal" data-bs-target={`#delete_address_modal${address_id}`} className="btn btn-dark border-dark shadow-btm rounded-3 px-3"> Remove </button>
                                                         <Link to={`/editaddress/${address_id}`} className="btn btn-danger border-dark shadow-btm rounded-3 px-3"><i className="ti ti-edit pe-3" />Edit </Link>
                                                      </>
                                                   }

                                                </div>
                                             </div>
                                          </div>
                                          {/* modal */}
                                          <div className="modal fade" id={`delete_address_modal${address_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                             <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                   <div className="modal-header bg-danger text-white">
                                                      <h5 className="modal-title" id="exampleModalLabel">Confirm Deletion</h5>
                                                      <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                                   </div>
                                                   <div className="modal-body">
                                                      <div className="form-floating mb-3">
                                                         <ul className="list-unstyled">
                                                            <li>{address.name}</li>
                                                            <li>{address.village + ", " + address.address}</li>
                                                            <li>{"Pincode: " + address.pincode + ", Post Office: " + address.post_office}</li>
                                                            {/* <li>{address.district + ", " + address.state + ", " + address.country}</li> */}
                                                            <li>{address.city}</li>
                                                            <li>{address.district + ", " + address.state}</li>
                                                            <li>Phone number: {address.phone_no}{address.alternate_phone && <span>{+ ", " + address.alternate_phone} </span>} </li>
                                                         </ul>
                                                      </div>
                                                   </div>
                                                   <div className="modal-footer d-flex justify-content-evenly">
                                                      <button type="button" className="btn border border-2 border-danger text-danger py-1 px-5" data-bs-dismiss="modal">No</button>
                                                      <button type="button" className="btn btn-danger py-1 px-5" data-bs-dismiss="modal" onClick={() => handleAddressRemove(address.id)}>Yes</button>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          {/* modal */}
                                       </div>
                                    )
                                 })
                                 :
                                 <h4 className='text-danger'>No Address Found !</h4>
                           }
                        </div>
                        <div className="col-md-12">
                           {
                              userInformation &&
                              <div className="col-md-12">
                                 <div className="mt-3 p-4 rounded-4 border-dark shadow-btm border border-dark ms-md-5">
                                    <div className="d-flex justify-content-between">
                                    </div>
                                    <div className="d-flex text-align-center">
                                       <i className="ti ti-home-filled fs-1 text-danger me-3 ps-5" />
                                       <div>
                                          <h6 className="fw-bold mb-0">Billing Address</h6>
                                          <p>{`${userInformation.name}`},</p>
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                       <ul className="list-unstyled">
                                          <li>{userInformation.phone_no && ` ${userInformation.phone_no}`}{userInformation.alternate_phone && `, ${userInformation.alternate_phone}`}</li>
                                          <li>{userInformation.village && userInformation.village}{userInformation.address && `, Near ${userInformation.address}`}</li>

                                          <li><span className=" text-black fw-bold">Pin code:</span> {userInformation.pincode}</li>
                                          <li><span className="text-dark fw-bold">Post Office:</span> {userInformation.post_office}</li>
                                          <li className="text-dark fw-bold">{userInformation.city && `${userInformation.city}, `}{userInformation.district && `, ${userInformation.district}`}{userInformation.state && `, ${userInformation.state}`}</li>
                                       </ul>
                                       <div className="edit">


                                          <Link to="/myinformation" className="btn btn-danger border-dark shadow-btm rounded-3 px-3"><i className="ti ti-edit pe-3" />Edit </Link>


                                       </div>
                                    </div>
                                 </div>

                              </div>

                           }
                        </div>

                     </div>
                  </div>
               </div>
            </section>


         </main>
      </>
   )
}

export default MyAddress
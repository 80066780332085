import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { useGlobalContext } from '../contexts/GlobalContext';
import logo from '../assets/images/main-logo.svg';
import { Link } from 'react-router-dom';

const PopularSchool = () => {
    const { districts, setIsLoading, stateData, handleStateChange, showSchoolModal, img_url, schools, getSchools, schoolData, convertToPlain, setShowSchoolModal, getAllInventory, allInventory } = useGlobalContext();

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [filteredSchoolssearch, setFilteredSchoolsseacrh] = useState([]);
    useEffect(() => {
        getSchools()

    }, [])
    useEffect(() => {

        setFilteredSchools(schools)
        handleSearch()
    }, [schools])
    const [studentRegisterData, setStudentRegisterData] = useState({

        'state': "",
        'district': "",

    })
    useEffect(() => {
        if (studentRegisterData?.district !== '0') {
            const filtered = filteredSchoolssearch.filter((school) =>
                school?.distt?.toLowerCase().includes(studentRegisterData?.district?.toLowerCase())
            );
            setFilteredSchools(filtered);
        }
        else
            setFilteredSchools(filteredSchoolssearch);
        console.log(studentRegisterData?.district)
    }, [studentRegisterData, filteredSchoolssearch, searchTerm, studentRegisterData.district])
    const handleSearch = () => {
        const filtered = schools.filter((school) =>
            school?.school_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            school?.school_code?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredSchoolsseacrh(filtered);
    };
    const handleInputs = (event) => {
        let key = event.target.name;
        let value = event.target.value;

        setStudentRegisterData(prev => ({ ...prev, [key]: value }));

    }

    const handleState = (e) => {
        setStudentRegisterData(prev => ({ ...prev, [e.target.name]: e.target.value }))
        handleStateChange(e);
    }
    return (
        <>
            {/* {console.log(filteredSchoolssearch)} */}
            <Header />
            <main className='mt-5 pt-5 space-top'>
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-4 d-block d-md-none">
                        <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                    </div>
                </div>
                <section id="popular_schl">
                    <div className="container mt-5">
                        <div className="input-group w-50 mx-auto border border-dark rounded-3 shadow-md">
                            <input type="text" className="form-control  border-end-0" value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") handleSearch() }} placeholder="Search Schools" />
                            <div className="input-group-append">
                                <span className="input-group-text search-icon cursor-pointer"
                                >
                                    <i className="fa-solid fa-magnifying-glass p-1 bg-dark text-light rounded-3" onClick={handleSearch} />
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center gap-3 my-4">
                            <div className="input-group border border-dark rounded-3 shadow-md w-25">
                                <select className="form-select" onChange={(e) => handleState(e)} name='state' value={studentRegisterData.state}>
                                    <option defaultValue='' value=''>Select State</option>
                                    {
                                        stateData.map((state, i) => {
                                            return (
                                                <option key={i} value={state.state_name}>{state.state_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="input-group border border-dark rounded-3 shadow-md w-25">
                                <select className="form-select" onChange={handleInputs} name='district' value={studentRegisterData.district}>
                                    <option defaultValue={0} value={0}>Select District</option>

                                    {
                                        districts.map((district, i) => {
                                            return (
                                                <option key={i} value={district}>{district}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row h-100">
                            {schools && filteredSchools?.map((item, i) => {
                                const { school_name, school_banner, folder, school_code } = item;
                                return (
                                    <div className="col-md-4 h-100" key={i}>
                                        {/* {JSON.stringify(item)} */}
                                        <div className="card border border-dark shadow-btm rounded-4 mt-4 h-100">
                                            <div className="card-body d-flex">
                                                <div className='overflow-hidden rounded-3 ' style={{ maxWidth: '129px', width: '100%', height: '148px' }}>

                                                    {
                                                        school_banner !== null ?
                                                            <img src={`${img_url}/${folder}/${school_banner}`} alt="school banner" className="card-img my-auto h-100 w-100 " />
                                                            : <img src={require(`../assets/images/school_banner_2.jpeg`)} className="card-img  w-100 my-auto h-100" alt='.' />
                                                    }
                                                </div>

                                                <div className="ms-3 mt-5">
                                                    <p className="fw-bold text-start">{school_name}</p>
                                                    <h4 className="m-0 text-start">{school_code}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {filteredSchools.length < 1 ? <h4 className='text-danger text-center'>Not Found</h4> : ''}

                        </div>
                    </div>
                </section>
            </main >

        </>
    )
}

export default PopularSchool
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-bootstrap";
import $ from "jquery";
import "./assets/css/style.css";
import Header from "./layout/Header";
import Home from "./pages/Home";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ProductPage from "./pages/ProductPage";
import CartPage from "./pages/CartPage";
import WishListPage from "./pages/WishListPage";
import Checkout from "./pages/Checkout";
import { useGlobalContext } from "./contexts/GlobalContext";
import LoginPage from "./pages/LoginPage";
import Register from "./pages/Register";
import OtpVarification from "./pages/OtpVarification";
import OtpLogin from "./pages/OtpLogin";
import MySchool from "./pages/MySchool";
import MyProfile from "./pages/MyProfile";
import MyProfileInformation from "./pages/MyProfileInformation";
import OrderHistory from "./pages/OrderHistory";
import OrderHistory2 from "./pages/OrderHistory copy";
import MyAddress from "./pages/MyAddress";
import ChangePassword from "./pages/ChangePassword";
import AddNewAddress from "./pages/AddAddress";
import MySchoolPopup from "./components/MySchoolPopup";
import ForgotPassword from "./pages/ForgotPassword";
import ResetForgotPassword from "./pages/ResetForgotPassword";
import PopularSchool from "./pages/PopularSchool";
import PlaceOrder from "./pages/PlaceOrder";
import EditAddress from "./pages/EditAddress";
import BottomNavigationBar from "./components/BottomNavigationBar";
import MyOrders from "./pages/MyOrders";
import MyWishlist from "./pages/MyWishlist";
import VendorShop from "./pages/VendorShop";
import MarketPlace from "./pages/MarketPlace";
import OrderDetails from "./pages/OrderDetails";
import GiveReview from "./pages/GiveReview";
import PaymentStatus from "./pages/PaymentStatus";
import { useEffect } from "react";
import DelteuserAccout from "./pages/DelteuserAccout";
import Privacypolicy from "./pages/Privacypolicy";
import Privacypolicy2 from "./pages/Privacypolicy copy";
import { Helmet } from "react-helmet";
function App() {
  const { isLoading, isAuthenticated } = useGlobalContext();
  const { pathname } = useLocation();
  useEffect(() => {
    // Remove the 'modal-open' class from the body
    document.body.classList.remove("modal-open");
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";

    // Remove the modal backdrop if it exists
    const backdrop = document.querySelector(".modal-backdrop.fade.show");
    document.querySelector(".poition-fixed").style.paddingRight = "";
    // console.log(backdrop, "back");
    if (backdrop && pathname !== "/login") {
      backdrop.remove();
    }
  }, [pathname]);
  const location = useLocation();

  useEffect(() => {
    if (window.Android && typeof window.Android.updateHistory === "function") {
      window.Android.updateHistory(location.pathname);
    }
  }, [pathname, location]);
  const handleBackPress = () => {
    if (window.Android && typeof window.Android.goBack === "function") {
      window.Android.goBack(); // Call the goBack method in the Android WebAppInterface
    }
  };
  const { img_url } = useGlobalContext();
  // const { folder, image, link } = inventoryDetails;

  return (
    <>
      {isLoading ? (
        <>
          <div
            style={{ height: "100vh" }}
            className="d-flex position-fixed top-0 bottom-0 start-0 end-0 align-items-center justify-content-center"
          >
            <div className="spinner-border text-dark" role="status"></div>
          </div>
        </>
      ) : null}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/marketplace" element={<MarketPlace />} />
        <Route path="/paymentstatus/:order_id" element={<PaymentStatus />} />

        <Route path="/myschool" element={<MySchool />} />
        <Route path="/placeorder/:invoice_number" element={<PlaceOrder />} />
        <Route path="/vendorshop" element={<VendorShop />} />
        <Route path="/product/:product_id" element={<ProductPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/orderdetails" element={<OrderDetails />} />
        <Route path="/givereview" element={<GiveReview />} />

        {/* <Route path="/wishlist" element={<WishListPage />} /> */}
        <Route path="/wishlist" element={<MyWishlist />} />
        <Route path="/checkout" element={<Checkout />} />
        {!isAuthenticated ? (
          <Route path="/login" element={<LoginPage />} />
        ) : (
          <Route path="/login" element={<Navigate to="/" />} />
        )}

        <Route path="/signup" element={<Register />} />
        <Route path="/otpverification" element={<OtpVarification />} />
        <Route path="/otplogin" element={<OtpLogin />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/myinformation" element={<MyProfileInformation />} />
        <Route path="/orderhistory" element={<OrderHistory />} />
        <Route path="/cancelorderhistory" element={<OrderHistory2 />} />
        <Route path="/myaddress" element={<MyAddress />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/addaddress" element={<AddNewAddress />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetforgotpassword" element={<ResetForgotPassword />} />
        <Route path="/popularschool" element={<PopularSchool />} />
        <Route path="/editaddress/:address_id" element={<EditAddress />} />
        <Route path="/myorders" element={<MyOrders />} />
        {isAuthenticated ? (
          <Route path="/deleteaccount" element={<DelteuserAccout />} />
        ) : (
          <Route path="/" element={<Navigate to="/" />} />
        )}
        {/* <Route path="/deleteaccount" element={<DelteuserAccout />} /> */}
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termsandcondition" element={<Privacypolicy2 />} />
      </Routes>
      <BottomNavigationBar />
      <MySchoolPopup />
    </>
  );
}

export default App;

import React, { useEffect } from 'react'
import { hdata } from '../data/Cdata'

import { Link } from 'react-router-dom'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import ProfileSidebar from '../components/ProfileSidebar'
import { useOrderContext } from '../contexts/OrderContext';
import { useGlobalContext } from '../contexts/GlobalContext'


const OrderHistory = () => {
  const { orders, getMyOrders } = useOrderContext();
  const { handleAddToWishlist } = useGlobalContext();

  useEffect(() => {
    getMyOrders();
  }, []);
  return (
    <>
      <Header />
      <main id='main' className='mt-5 pt-5'>
        <section id="order-detail" className="pb-5 clearfix text-start">
          <div className="container">
            {/* <div className="my-5 d-flex justify-content-between my-acccount">
              <h4 className="text-start"><a href="my-account.html"><i className="ti ti-circle-arrow-left me-2" /></a>My Account</h4>
              <div className="btn-group bg-light">
                <a href="order-history.html" type="button" className="btn btn-danger active rounded-3 border border-1 border-dark px-5">Orders</a>
                <a href="order-history-cancelled.html" type="button" className="btn btn-white text-dark">Cancelled Orders</a>
              </div>
            </div> */}
            <ProfileSidebar />
            {/*--------------Order-history---------*/}
            {/* {JSON.stringify(orders)} */}
            <div className="float-md-end bill-details w-75 list-area text-sm-start text-start">
              {orders.length > 0 && orders.map((item, i) => {
                // const isDelivered = item.tracking_status.includes('Delivered');

                return (
                  <div className="card border-0 shadow rounded-4 pt-4 px-2 mb-4">
                    {JSON.stringify(orders)}
                    <div className="row">
                      <div className="col-12">
                        <h6 className="ps-3 d-flex align-items-center text-center ord-opt"><i className="ti ti-circle-x text-light bg-dark fs-3 p-1 rounded-2 me-2" />Delivered</h6>
                      </div>
                      <div className="col-12">
                        <h6 className="ps-3 short">On Sat, 22 Apr 2023</h6>
                      </div>
                    </div>
                    <div className="card-body d-flex align-items-center flex-wrap text-start">
                      <img className="rounded-4 mx-sm-0 mx-auto" src="assets/images/order-history.png" alt="cart-img" />
                      <div className="mx-3 mt-1">
                        <h5 className="mb-0 ord-tit">JMD Computer Science</h5>
                        <h6 className="short">Short Cut To Sure Success</h6>
                        <h6 className="text-dark ps-2">₹ 297</h6>
                      </div>
                      <div className="d-flex ms-auto pe-3 pt-5 gap-4">
                        <h6 className="d-flex align-items-center text-center mb-0 ord-opt"><i className="ti ti-heart text-end text-danger rounded-3 p-1 me-1 fs-4" onClick={() => { handleAddToWishlist(orders?.id) }} />Add to Wishlist</h6>
                        <h6 className="d-flex align-items-center text-center mb-0 ord-opt"><i className="ti ti-credit-card-refund text-end text-light bg-danger rounded-3 p-1 me-3 fs-4" />Re-Order</h6>
                      </div>
                    </div>
                  </div>

                  // <div className="card border-0 shadow rounded-4 pt-4 px-2 mb-4">
                  //   <div className="row">
                  //     <div className="col-12">
                  //       <h6 className="ps-3 d-flex align-items-center text-center ord-opt"><i className="ti ti-package text-light bg-dark fs-2 rounded-2 me-2" /> <span className={isDelivered ? "text-dark fw-bold" : "text-warning fw-bold"} dangerouslySetInnerHTML={{ __html: item.tracking_status }} />
                  //       </h6>

                  //     </div>
                  //     <div className="col-12">
                  //       <h6 className="ps-3 short">On Sat, 22 Apr 2023</h6>
                  //     </div>
                  //   </div>
                  //   <div className="card-body d-flex align-items-center flex-wrap text-start">
                  //     <img className="rounded-4 mx-sm-0 mx-auto" src="assets/images/order-history.png" alt="cart-img" />
                  //     <div className="mx-3 mt-1">
                  //       <h5 className="mb-0 ord-tit">JMD Computer Science</h5>
                  //       <h6 className="short">Short Cut To Sure Success</h6>
                  //       <ul className="list-inline fs-4 mb-0">
                  //         <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                  //         <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                  //         <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                  //         <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                  //         <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                  //       </ul>
                  //       <h6 className="text-danger short">Write a Review</h6>
                  //     </div>
                  //     <div className="ms-auto pe-3 pt-5">
                  //       <h6 className="d-flex align-items-center text-center mb-0 ord-opt"><i className="ti ti-credit-card-refund text-end text-light bg-danger rounded-3 p-1 me-3 fs-4" />Re-Order</h6>
                  //     </div>
                  //   </div>
                  // </div>

                );
              })}


              {/* <div className="card border-0 shadow rounded-4 pt-4 px-2 mb-4">
                <div className="row">
                  <div className="col-12">
                    <h6 className="ps-3 d-flex align-items-center text-center ord-opt"><i className="ti ti-package text-light bg-dark fs-2 rounded-2 me-2" />Delivered</h6>
                  </div>
                  <div className="col-12">
                    <h6 className="ps-3 short">On Sat, 22 Apr 2023</h6>
                  </div>
                </div>
                <div className="card-body d-flex align-items-center flex-wrap text-start">
                  <img className="rounded-4 mx-sm-0 mx-auto" src="assets/images/order-history.png" alt="cart-img" />
                  <div className="mx-3 mt-1">
                    <h5 className="mb-0 ord-tit">JMD Computer Science</h5>
                    <h6 className="short">Short Cut To Sure Success</h6>
                    <ul className="list-inline fs-4 mb-0">
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                    </ul>
                    <h6 className="text-danger short">Write a Review</h6>
                  </div>
                  <div className="ms-auto pe-3 pt-5">
                    <h6 className="d-flex align-items-center text-center mb-0 ord-opt"><i className="ti ti-credit-card-refund text-end text-light bg-danger rounded-3 p-1 me-3 fs-4" />Re-Order</h6>
                  </div>
                </div>
              </div>
              <div className="card border-0 shadow rounded-4 pt-4 px-2 mb-4">
                <div className="row">
                  <div className="col-12">
                    <h6 className="ps-3 d-flex align-items-center text-center ord-opt"><i className="ti ti-package text-light bg-dark fs-2 rounded-2 me-2" />Delivered</h6>
                  </div>
                  <div className="col-12">
                    <h6 className="ps-3 short">On Sat, 22 Apr 2023</h6>
                  </div>
                </div>
                <div className="card-body d-flex align-items-center flex-wrap text-start">
                  <img className="rounded-4 mx-sm-0 mx-auto" src="assets/images/order-history.png" alt="cart-img" />
                  <div className="mx-3 mt-1">
                    <h5 className="mb-0 ord-tit">JMD Computer Science</h5>
                    <h6 className="short">Short Cut To Sure Success</h6>
                    <ul className="list-inline fs-4 mb-0">
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                      <li className="list-inline-item me-0"><i className="ti ti-star" /></li>
                    </ul>
                    <h6 className="text-danger short">Write a Review</h6>
                  </div>
                  <div className="ms-auto pe-3 pt-5">
                    <h6 className="d-flex align-items-center text-center mb-0 ord-opt"><i className="ti ti-credit-card-refund text-end text-light bg-danger rounded-3 p-1 me-3 fs-4" />Re-Order</h6>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* <section id="order-history">
          <div className="container">

            <div className='mt-2'>
              <Link to="/myprofile" className='text-decoration-none color-pink'>My Profile</Link>
              <span> <i className="bi bi-chevron-right f-sm"></i> Order History</span>
            </div>
            <h4 className="py-4">Your Order History</h4>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr className="text-center">
                    <th scope="col">NO.</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Product Image</th>
                    <th scope="col">Class</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Delivery Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    hdata.map((item, i) => {
                      return (
                        <tr key={i} className="text-center">
    
                          <th scope="row">{i + 1}</th>
                          <td className="text-start w-25">{item.title}</td>
                          <td className="text-center">
                            <div className="history-img-contain mx-auto"><img src={item.image} alt="" /></div>
                          </td>
                          <td>{item.class}</td>
                          <td>₹ <del>140.00</del> {item.price}</td>
                          <td>{item.qty}</td>
                          <td>{item.date}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  )
}

export default OrderHistory
import React, { useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext'

const Step1 = () => {
    const { registerData, changeHandler, setCurrentStep } = useGlobalContext();
    const { first_name, last_name } = registerData;
    const step1_errors = {}
    const [errors, setErrors] = useState({});
    const nameRegex = /^[a-zA-Z]*$/;

    if (first_name === '') {
        step1_errors.first_name = "First name can't be empty"
    }
    // else if (!(nameRegex.test(first_name))) {
    //     step1_errors.first_name = "Name can only contain alphabets"
    // }
    if (last_name === '') {
        step1_errors.last_name = "Last name can't be empty"
    }
    // else if (!(nameRegex.test(last_name))) {
    //     step1_errors.last_name = "Name can only contain alphabets"
    // }

    const handleClick = () => {
        setErrors(step1_errors);

        if (Object.keys(step1_errors).length === 0) {
            setCurrentStep(2)
        }
    };

    return (
        <>
            <div className='text-start'>
                <div className="form-group text-start">
                    <label htmlFor="name">First Name</label>
                    <input type="text" className="form-control" id="name" placeholder="First Name" name="first_name" value={registerData.first_name} onChange={(e) => changeHandler(e)} />
                    <div className='text-danger s-font2'>{errors.first_name}</div>
                </div>
                <div className="form-group pt-3 text-start">
                    <label htmlFor="name">Last Name</label>
                    <input type="text" className="form-control" id="name" placeholder="Last Name" name="last_name" value={registerData.last_name} onChange={(e) => changeHandler(e)} />
                    <div className='text-danger s-font2'>{errors.last_name}</div>

                </div>


            </div>

            <button type='button' id="nextBtn" onClick={handleClick} className="mt-4 btn btn-danger d-block w-100">Next</button>


        </>
    )
}

export default Step1
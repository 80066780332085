import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useGlobalContext } from '../contexts/GlobalContext'

const ProfileSidebar = () => {
    const { pathname } = useLocation()
    const {isAuthenticated } =  useGlobalContext()
    return (
        <>
            {/* {console.log(pathname, 'fad')} */}

            <div className="my-acccount my-md-5 mb-5 d-flex justify-content-between">
                <h4><Link to={'/myprofile'} className='text-decoration-none text-dark'><i className="ti ti-circle-arrow-left me-2" /></Link> <span className='d-none d-md-inline'> My Account</span></h4>
                {/* {(pathname === '/orderhistory' || pathname === '/cancelorderhistory') && <div className="btn-group bg-light">
                    <Link to={'/orderhistory'} type="button" className={`btn ${pathname === '/orderhistory' ? 'btn-danger active border border-1 border-dark ' : 'btn-white'} rounded-3  px-5`}>Orders</Link>
                    <Link to={'/cancelorderhistory'} type="button" className={`btn ${pathname === '/cancelorderhistory' ? 'btn-danger active border border-1 border-dark ' : 'btn-white'} rounded-3  `}>Cancelled Orders</Link>
                </div>} */}

            </div>
            <div className="information d-md-block d-none float-md-start pe-md-4 list-area text-sm-start text-center pb-4 w-25">
                <ul className="p-2  list-unstyled text-start">
                    {isAuthenticated ?  <li className={`p-2 border border-2 border-dark rounded-4 shadow-btm ${pathname === '/myinformation' ? 'bg-dark' : 'bg-light'}  `}>
                        <Link to={'/myinformation'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none'>
                            <div className={`d-flex align-items-center ${pathname === '/myinformation' ? 'text-light' : 'text-dark'} `}>
                                <i className={`ti ti-user fs-2 me-4 ps-4 ${pathname === '/myinformation' ? 'text-light' : 'text-danger'} `} />
                                <h6 className=" fw-bold mb-0">My Personal Information</h6>
                            </div>
                        </Link>
                    </li> :
                     <li className={`p-2 border border-2 border-dark rounded-4 shadow-btm ${pathname === '/myinformation' ? 'bg-dark' : 'bg-light'}  `}>
                     <Link to={'/login'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none'>
                         <div className={`d-flex align-items-center ${pathname === '/myinformation' ? 'text-light' : 'text-dark'} `}>
                             <i className={`ti ti-user fs-2 me-4 ps-4 ${pathname === '/myinformation' ? 'text-light' : 'text-danger'} `} />
                             <h6 className=" fw-bold mb-0">My Personal Information</h6>
                         </div>
                     </Link>
                 </li>}
                   
                    <li className={`mt-3 p-2 border border-2 border-dark rounded-4 shadow-btm ${pathname === '/myaddress' || pathname === '/addaddress' ? 'bg-dark' : 'bg-light'}  `}>
                        <Link to={'/myaddress'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none'>
                            <div className={`d-flex align-items-center ${pathname === '/myaddress' || pathname === '/addaddress' ? 'text-light' : 'text-dark'} `}>
                                <i className={`ti ti-home  fs-2 me-4 ps-4 ${pathname === '/myaddress' || pathname === '/addaddress' ? 'text-light' : 'text-danger'} `} />

                                <h6 className=" fw-bold mb-0">My Address</h6>
                            </div>
                        </Link>
                    </li>
                    <li className={`mt-3 p-2 border border-2 border-dark rounded-4 shadow-btm ${pathname === '/changepassword' ? 'bg-dark' : 'bg-light'}  `}>
                        <Link to={'/changepassword'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none'>
                            <div className={`d-flex align-items-center ${pathname === '/changepassword' ? 'text-light' : 'text-dark'} `}>
                                <i className={`ti ti-lock-square-rounded fs-2 me-4 ps-4 ${pathname === '/changepassword' ? 'text-light' : 'text-danger'} `} />
                                <h6 className="fw-bold mb-0">Change Password</h6>
                            </div>
                        </Link>
                    </li>
                    <li className={`mt-3 p-2 border border-2 border-dark rounded-4 shadow-btm ${pathname === '/orderhistory' || pathname === '/cancelorderhistory' ? 'bg-dark' : 'bg-light'}  `}>
                        <Link to={'/orderhistory'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none'>
                            <div className={`d-flex align-items-center ${pathname === '/orderhistory' || pathname === '/cancelorderhistory' ? 'text-light' : 'text-dark'} `}>
                                <i className={`ti ti-package  fs-2 me-4 ps-4 ${pathname === '/orderhistory' || pathname === '/cancelorderhistory' ? 'text-light' : 'text-danger'} `} />
                                <h6 className=" fw-bold mb-0">Order History</h6>
                            </div>
                        </Link>
                    </li>

                </ul>
            </div>
        </>
    )
}

export default ProfileSidebar
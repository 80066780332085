import React from 'react'
import Header from '../layout/Header'
import ProductCart from '../components/ProductCart'

const Checkout = () => {
    return (
        <>
            <Header/>
            <div className="mt-5 pt-5">
            <ProductCart/>
            </div>
        </>
    )
}

export default Checkout
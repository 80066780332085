var zipcode_pattern = /[0-9]{6}/;
var phoneRegex = /^\d{10}$/;
var passwordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
var nameRegex = /^[a-zA-z]+([a-zA-Z]+)*$/;
var emailPhoneRegex = /^(?:\d{10}|\w+.+@\w+\.\w{2,3})$/
// var emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
var emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
// [A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}

const AddressValidation = (addressData) => {
    const { name, phone_no, pincode,email } = addressData;
    let addressErrors = {};
    if(!name) {
        addressErrors.name = 'Name is required';
    }
    // else if(!nameRegex.test(name)) {
    //     addressErrors.name = 'Not a Valid name';
    // }

    if(!phone_no) {
        addressErrors.phone_no = 'Mobile Number is required';
    }else if (!phoneRegex.test(phone_no)) {
        addressErrors.phone_no = 'Wrong Formate';
    }
    if (email && !emailRegex.test(email)) {
        addressErrors.email = "Invalid Email Formate";
    }
    // if (!phoneRegex.test(alternate_phone)) {
    //     addressErrors.alternate_phone = 'Incorrect Formate';
    // }

    if (!zipcode_pattern.test(pincode)) { addressErrors.pincode = 'Accepts only 6 Integers' }

    const empty_info = {
        phone_no: 'Mobile Number is required',
        state: 'State not selected',
        district: 'District not selected',
        post_office: 'Fill the Post Office',
        village: 'Enter Villa or Ward Number',
        city: 'City required',
        pincode: 'Pincode required',
        // address: 'Enter Land Mark',
        // email: 'email required',
        // school_code: 'School Code Required',
        // classno: 'Enter your class',
    }

    for (let key in empty_info) {
        if (addressData[key] === '') {
            addressErrors[key] = empty_info[key]
        }
    }

    return addressErrors;
}

//Student Registration Form Validation
const StudentRegValidations = (studentRegisterData) => {
    const { first_name, phone_no, fathers_name, state, district, password, email, confirm_password  } = studentRegisterData;
    let stuRegErrors = {};
    if(!first_name) {
        stuRegErrors.first_name = 'First Name is required';
    }
    if(!fathers_name) {
        stuRegErrors.fathers_name = 'Fathers Name is required';
    }
    if(!state) {
        stuRegErrors.state = 'State is required';
    }
    if(!district) {
        stuRegErrors.district = 'District is required';
    }
    if(!phone_no) {
        stuRegErrors.phone_no = 'Mobile Number is required';
    }else if (!phoneRegex.test(phone_no)) {
        stuRegErrors.phone_no = 'Wrong Formate';
    }
    if (email && !emailRegex.test(email)) {
        stuRegErrors.email = "Invalid Email Formate";
    }
    if (password === '') {
        stuRegErrors.password = "Password Required";
    }
    else if (password.length < 6) {
        stuRegErrors.password = "Password must contain 6 character";
    }
    
    //confirm password
    if (!confirm_password) {
        stuRegErrors.confirm_password = "Please Re-enter Password";
    } else if (confirm_password !== password) {
        stuRegErrors.confirm_password = "Password Did Not Match";
    }

    return stuRegErrors;
}

// Login Form Validation
const LoginValidation = (loginData) => {
    const loginErrors = {};
    const { email, password } = loginData;

    if (!email) {
        loginErrors.email = "Email or Mobile Number required";
    }
    if (!password) {
        loginErrors.password = "password required";
    }
    return loginErrors;
};

//Information form validation
const InfoValidation = (infoData) => {
    const { first_name, last_name,email,phone_no} = infoData;
    const infoErrors = {};
    let password = infoData?.password;

    if (password && password.length < 6) {
        infoErrors.password = "Password must contain 6 character";
    }

    if (email && !emailRegex.test(email)) {
        infoErrors.email = "Invalid Email Formate";
    }
    if(!phone_no) {
        infoErrors.phone_no = 'Mobile Number is required';
    }else if (!phoneRegex.test(phone_no)) {
        infoErrors.phone_no = 'Wrong Formate';
    }
    const empty_info = {
        first_name: 'First Name required',
        last_name: 'Last Name required',
        phone_no: 'Mobile Number is required',
        state: 'State not selected',
        district: 'District not selected',
        post_office: 'Fill the Post Office',
        village: 'Enter Villa or Ward Number',
        city: 'City required',
        pincode: 'Pincode required',
        password: 'Password Required'
        // address: 'Enter Land Mark',
        // email: 'email required',
        // school_code: 'School Code Required',
        // classno: 'Enter your class',
    }

    for (let key in empty_info) {
        if (infoData[key] === null || infoData[key] === "") {
            infoErrors[key] = empty_info[key]
        }
    }

    return infoErrors;
}

//Change Password Validations
const PassValidation = (values) => {
    const { current_password, new_password ,cpassword } = values;
    // const cpassword = document.getElementById('cpassword').value;
    const errors = {};

    if (current_password === '') {
        errors.current_password = 'Enter your current password';
    }
    if (!new_password) {
        errors.new_password = 'Enter your new password'
    }
    if (!cpassword) {
        errors.cpassword = 'Re-enter new password'
    } else if (cpassword !== new_password) {
        errors.cpassword = 'Password did not match'
    }

    return errors;
}
export { AddressValidation, LoginValidation, InfoValidation, PassValidation, StudentRegValidations };
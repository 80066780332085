import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext';
import Header from '../layout/Header';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/images/main-logo.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import Loading from '../components/loading/Loading';

function PlaceOrder() {
    const { userId, api_url, cartItems, getCartItems, getAllShippingAddress, allShippingAddress, isLoading, setIsLoading } = useGlobalContext();

    const [orderAddress, setOrderAddress] = useState({});
    const [orderedItems, setOrderedItems] = useState([]);
    const [orderPreviewDetails, setOrderPreviewDetails] = useState({});
    const [showAddressModal, setShowAddressModal] = useState(false)

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();


    //orderPreview
    const orderPreview = async () => {
        const data = {
            'user_id': userId,
            'invoice_number': params.invoice_number,
        }
        try {
            setIsLoading(true);
            let res = await axios.post(`${api_url}/orderPreview`, data)
            if (res.data.success === 1) {
                setOrderPreviewDetails(res.data.data);
                setOrderAddress(res.data.data.order_address);
                setOrderedItems(res.data.data.ordered_items);
            }
            else {
                toast.error('Something Went Wrong', {
                    autoClose: 1500,
                    theme: 'colored'
                });
            }
        } catch (error) {
            toast.error('Axios Error', {
                autoClose: 1500,
                theme: 'colored'
            });
            console.log(error)
        } finally {
            setIsLoading(false)
        }


        // axios.post(`${api_url}/orderPreview`, data)
        //     .then((res) => {
        //         if (res.data.success === 1) {
        //             setOrderPreviewDetails(res.data.data);
        //             setOrderAddress(res.data.data.order_address);
        //             setOrderedItems(res.data.data.ordered_items);
        //         }
        //         else {
        //             toast.error('Something Went Wrong', {
        //                 autoClose: 1500,
        //                 theme: 'colored'
        //             });
        //         }
        //     })
        //     .catch((error) => {
        //         toast.error('Axios Error', {
        //             autoClose: 1500,
        //             theme: 'colored'
        //         });
        //         console.log(error)
        //     })
    }

    useEffect(() => {
        orderPreview();
        getAllShippingAddress();
    }, [])

    const handlePlaceOrder = () => {
        const objectForSending = {
            'user_id': userId,
            'order_id': orderPreviewDetails.invoice_number,
            'total_amount': orderPreviewDetails.order_total,
        }

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `${api_url}/payment`;

        // Sample 1
        const hiddenFieldSample1 = document.createElement('input');
        hiddenFieldSample1.type = 'hidden';
        hiddenFieldSample1.name = 'user_id';
        hiddenFieldSample1.value = objectForSending.user_id;
        form.appendChild(hiddenFieldSample1);

        // Sample 2
        const hiddenFieldSample2 = document.createElement('input');
        hiddenFieldSample2.type = 'hidden';
        hiddenFieldSample2.name = 'order_id';
        hiddenFieldSample2.value = objectForSending.order_id;
        form.appendChild(hiddenFieldSample2);

        // Sample 3
        const hiddenFieldSample3 = document.createElement('input');
        hiddenFieldSample3.type = 'hidden';
        hiddenFieldSample3.name = 'total_amount';
        hiddenFieldSample3.value = Number(orderPreviewDetails.grand_total);
        form.appendChild(hiddenFieldSample3);

        document.body.appendChild(form);
        form.submit();

        // var win = window.open(`${api_url}/payment`, '_blank');
        // win.focus();
        // axios.post(`${api_url}/payment`, data);
        //     .then((res) => {
        //         navigate(`${api_url}/payment`)
        //     })
        //     .catch((error) => {
        //         toast.error('Axios Error', {
        //             autoClose: 1500,
        //             theme: 'colored'
        //         });
        //         console.log(error)
        //     })              
    }

    //Address Selection
    const handleAddressSelection = (address_id) => {
        const data = {
            'user_id': userId,
            'invoice_number': location.state?.invoice_number,
            'address_id': address_id,
        }
        axios.post(`${api_url}/updateOrderShippingAddress`, data)
            .then((res) => {
                if (res.data.success === 1) {
                    setOrderAddress(res.data.data);
                    setShowAddressModal(false)
                    // window.location.reload();
                    orderPreview()
                }
            })
            .catch((error) => {
                toast.error('Axios Error', {
                    autoClose: 1500,
                    theme: 'colored'
                });
                console.log(error)
            })
    }

    //Calculations
    var totalMrp = 0, totalDiscountedPrice = 0, totalCartQty = 0, totalDeliveryCharges = 0, totalDiscount = 0;
    orderedItems.forEach((item) => {
        const { id, product_name, qty, mrp, item_type, discounted_price, shipping_charges, product_color, product_size, class_title, description, image, folder, alt } = item
        let product_quantity = Number(qty);
        let product_id = id;
        totalMrp += item.mrp * product_quantity;
        totalDiscountedPrice += item.discounted_price * product_quantity;
        totalDiscount = (totalMrp - totalDiscountedPrice)
        totalCartQty += product_quantity;
        totalDeliveryCharges += Number(shipping_charges) * product_quantity
    })

    var orderItems_HTML = '';
    useEffect(() => {
        // getCartItems();
    }, []);


    // console.log(orderedItems)


    if (orderedItems.length > 0) {
        orderItems_HTML =
            <>
                <div className="place-order-table-div border  border-dark rounded-3 overflow-hidden mb-5 p-0">

                    <table className="place-order-table border rounded-3 overflow-hidden">
                        <thead className='fw-bold'>
                            <tr>
                                <th>S.No.</th>
                                <th>Product</th>
                                <th>Class/Size</th>
                                <th>Unit Price</th>
                                <th>Qty</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderedItems.map((item, i) => {
                                    const { product_name, vendor_name, qty, mrp, class_title, discounted_price } = item
                                    let product_quantity = Number(qty);
                                    return (
                                        <tr key={i}>
                                            <th scope="row">{i + 1}</th>
                                            <td>
                                                <span>{product_name}</span>
                                                {
                                                    vendor_name !== null ?
                                                        <span>(<strong>Sold By</strong> {vendor_name})</span>
                                                        :
                                                        <span>(<strong>Sold By</strong>Sold By Evyapari)</span>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    class_title !== null && class_title
                                                }
                                            </td>
                                            <td><del>₹{mrp}</del> ₹{discounted_price}</td>
                                            <td>{product_quantity}</td>
                                            <td>{product_quantity * discounted_price}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

            </>
    }
    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Header />
            <main id='main' className='mt-5 pt-5 space-top'>
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-4 d-block d-md-none">
                        <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                    </div>
                </div>
                <div className="container">
                    <Link onClick={() => navigate(-1)} className='text-decoration-none text-dark'><i className="ti ti-circle-arrow-left me-2 fs-2" /></Link>
                </div>
                <div className="container checkout">
                    <h4 className="mt-md-5 mt-3">Order Review</h4>
                    <div className="row">
                        <div className="col-md-7 col-12 order-md-0 order-1">
                            <div className="card order-review border border-dark shadow-btm rounded-3">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center px-2 pt-2 ">
                                        <h5> {orderAddress?.address_type ?
                                            orderAddress?.address_type === 1 ?
                                                'Home'
                                                :
                                                'School' : ''
                                        }</h5>
                                        <button className="btn btn-sm btn-danger " onClick={() => setShowAddressModal(true)}>Change</button>
                                    </div>
                                    <div className="ps-2 pb-2 text-start">
                                        <div>{orderAddress?.name} <span className="ms-3"> {orderAddress?.phone_no}{orderAddress?.alternate_phone && `, ${orderAddress.alternate_phone}`} </span></div>
                                        <div> {orderAddress?.address_type === 2 && <span>School Name: {orderAddress?.school_name}</span>}</div>
                                        <div>{orderAddress?.village && orderAddress.village} <span className="ms-3"> {orderAddress?.pincode && `${orderAddress.pincode}`} </span></div>
                                        <div>{orderAddress?.address && ` Near ${orderAddress.address}`}</div>
                                        <div>{orderAddress?.post_office && `Post Office: ${orderAddress.post_office}`}</div>
                                        <div> <span className="">{orderAddress?.city && `${orderAddress.city},`}{orderAddress?.district && ` ${orderAddress.district}`}{orderAddress?.state && `, ${orderAddress.state}`} </span></div>
                                    </div>
                                </div>

                            </div>
                            <div className="mt-4">
                                <h6><span className="fw-bold">Purchase Order Number:</span> {orderPreviewDetails.invoice_number}</h6>
                            </div>
                            <div className="mt-2">
                                <h6><span className="fw-bold">Order Date : </span>{orderPreviewDetails.order_date}</h6>
                            </div>
                        </div>
                        {/* <div className="col-md-3"></div> */}
                        <div className="col-md-5 order-md-1 order-0">

                            <div className="float-md-end w-100 bill-details checkout mb-4">
                                <div className="card border-2 border-dark rounded-3 py-3">

                                    <div className="card-body">


                                        <h6 className="text-center checkout-card">Total Bill Checkout</h6>
                                        <div className="border-bottom border-secondary p-2">

                                        </div>
                                        <div className="d-flex border-bottom border-secondary fw-bold p-2">
                                            <span className="checkout-card pb-2">Subtotal (qty {totalCartQty})</span>
                                            <span className="ms-auto">₹{orderPreviewDetails.order_total_without_shipping}</span>
                                        </div>
                                        <div className="d-flex border-bottom border-secondary fw-bold p-2">
                                            <span className="checkout-card pb-2">Shipping Charges</span>
                                            <span className="ms-auto">+ ₹{orderPreviewDetails.shipping_charge}</span>
                                        </div>
                                        <div className="d-flex border-bottom border-secondary fw-bold p-2">
                                            <span className="checkout-card pb-2">Discount</span>
                                            <span className="ms-auto">- ₹{orderPreviewDetails.discount}</span>
                                        </div>
                                        <div className="d-flex fw-bold p-2">
                                            <span className="checkout-card  pb-2">Total amount to be Paid</span>
                                            <span className="ms-auto">₹{orderPreviewDetails.grand_total}</span>
                                        </div>
                                        {
                                            orderPreviewDetails?.mode_of_pay === 1 ?
                                                <Link to='' className="checkout-card btn btn-danger w-100 pb-2" onClick={() => handlePlaceOrder('online')}>Place Order</Link>
                                                :
                                                <Link to='' className="checkout-card btn btn-danger w-100 pb-2" onClick={() => handlePlaceOrder('cod')}>Place COD Order</Link>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <h4>Order Summary</h4>
                    </div>
                    {orderItems_HTML}

                </div>



                <Modal size="lg" autoFocus={false} centered show={showAddressModal} onHide={() => setShowAddressModal(false)}>
                    <Modal.Header className='bg-danger text-white' closeButton>
                        <Modal.Title><h5>SHIPPING ADDRESS</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bg-white">
                            <h5>Change the Address</h5>
                            {
                                allShippingAddress.map((address, i) => {
                                    const { id, user_id, name, default_address, phone_no, school_name, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;
                                    let address_id = id;
                                    return (
                                        <div key={i} id='address' className='px-4'>
                                            <div className="form-check">
                                                {/* <input type="radio" className="form-check-input rounded-2 border-danger border-2" name="selectaddress" id="flexRadioDefault1" /> */}
                                                <button onClick={() => handleAddressSelection(address_id)} className='bg-transparent w-100 border-0 text-start'>
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <div className="d-flex gap-2">
                                                                <div>
                                                                    <span>{name} </span>
                                                                    {
                                                                        address_type === 1 ?
                                                                            <span className="badge rounded-0 bg-success">Home</span>
                                                                            :
                                                                            <span className="badge rounded-0 bg-warning">School</span>
                                                                    }

                                                                </div>
                                                                <div className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</div>
                                                            </div>
                                                            <div>
                                                                <ul className="list-unstyled">
                                                                    <li>{`${village}, ${address.address}`}</li>
                                                                    <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                                    <li>{`${city}, ${district}, ${state}`}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                                <hr />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </main >

            {/* <Footer /> */}

        </>
    )
}

export default PlaceOrder
import React, { useRef, useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { StudentRegValidations } from './FormValidation';
import { Form, Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TermsConditions from './TermsConditions';


const MySchoolPopup = () => {
    const { districts, setIsLoading, img_url, stateData, handleStateChange, showSchoolModal, setShowSchoolModal } = useGlobalContext();
    const navigate = useNavigate();
    const location = useLocation();
    const recaptcha = useRef();
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const [verified, setVerified] = useState(false);
    const [setbtn, setsetbtn] = useState(false);
    const [school_Code, setSchool_Code] = useState({
        school_code: ""
    });
    const [show, setShow] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [showRegModal, setShowRegModal] = useState(false);
    // const [showSchoolModal, setShowSchoolModal] = useState(false);
    const [showReadModal, setShowReadModal] = useState(false);

    const [err, setErr] = useState(null);
    const [schoolData, setSchoolData] = useState(null)
    const { api_url } = useGlobalContext();
    const [stuRegErrors, setStuRegErrors] = useState({})
    const handleChange = (event) => {
        event.preventDefault();
        setSchool_Code({ school_code: "" });
        inputRef2.current.style.display = 'none';
        setShow(false);
        inputRef.current.focus()
    }
    const [studentRegisterData, setStudentRegisterData] = useState({
        'type': 2,
        'school_code': school_Code.school_code,
        'first_name': "",
        'last_name': "",
        'fathers_name': "",
        'phone_no': "",
        'state': "",
        'district': "",
        'password': "",
        'email': "",
        'confirm_password': "",
    })
    const [otpData, setOtpData] = useState({
        "user_id": "",
        "otp": ""
    });

    const handleState = (e) => {
        setStudentRegisterData(prev => ({ ...prev, [e.target.name]: e.target.value }))
        handleStateChange(e);
    }

    // Register Student
    const registerStudent = (event) => {
        event.preventDefault();
        const captchaValue = recaptcha.current.getValue()
        let errors = StudentRegValidations(studentRegisterData)
        setStuRegErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (!captchaValue) {
                toast.error('Please verify Captcha', {
                    autoClose: 1000,
                })
            }
            else {
            setIsLoading(true)
            axios.post(`${api_url}/registerStudent`, studentRegisterData)
                .then((res) => {
                    if (res.data.success === 1) {
                        setIsLoading(false)
                        toast.success(res.data.message, {
                            autoClose: 1500,
                        });
                        setShowRegModal(false);

                        // setShowOtpModal(true);
                        setOtpData({ ...otpData, user_id: res.data.data.user_id });
                        const timer = setTimeout(() => {
                            navigate('/otpverification', { state: { user_id: res.data.data.user_id, phone_no: studentRegisterData.phone_no, otp: res.data.data.otp, prevPath: location.pathname } });
                        }, 2000)
                        return () => clearTimeout(timer);
                    }
                    else {
                        setIsLoading(false)
                        toast.error(res.data.message, {
                            autoClose: 1000,
                        })
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    toast.error('Axios Error', {
                        autoClose: 1000,
                    })
                    console.log(err)
                })
        }

        }
    }


    // handleSelect
    const handleSelect = (schoolcode) => {
        if (sessionStorage.getItem('token')) {
            setShowSchoolModal(false)
            navigate('/myinformation', { state: { schoolcode: schoolcode } })
        }
        else {
            setShowSchoolModal(false);
            setShowRegModal(true);
        }
    }

    // recaptcha function
    function onClickCaptcha(value) {
        setVerified(!verified);
    };

    // Get School Information
    const getSchoolInfo = (event) => {
        event.preventDefault();
        setIsLoading(true)
        axios.post(`${api_url}/getSchoolInfo`, school_Code)
            .then((res) => {
                if (res.data.success === 1) {
                    setIsLoading(false)
                    // console.log(res.data);
                    setSchoolData(res.data.data);
                    // inputRef2.current.style.display = 'block';
                    setShow(true);
                } else {
                    setIsLoading(false)
                    setErr(res.data.message);
                }
            })
            .catch((err) => {
                setIsLoading(false)
                console.log(err);
            });
    }


    // Handle Inputs
    const handleInputs = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        setSchool_Code(prev => ({ ...prev, [key]: value }));
        setStudentRegisterData(prev => ({ ...prev, [key]: value }));
        setOtpData(prev => ({ ...prev, [key]: value }));
    }

    return (
        <>
            <section id="popup">
                {
                    sessionStorage.getItem('school_code') === null ?
                        <button id="myschool_btn" type="button" className="btn btn-danger  d-md-block d-none align-items-center justify-content-center d-md-flex d-none flex-column" onClick={() => setShowSchoolModal(true)}>
                       My School 
                        </button>
                        :
                        <button onClick={()=>navigate('/myschool')} id="myschool_btn" type="button" className="btn btn-danger d-md-block d-none">
                            <Link to='/myschool' className='text-decoration-none text-white align-items-center justify-content-center d-md-flex d-none flex-column'><span>My School</span> </Link>
                        </button>
                }

                {/* <!-- Modal - 1 (My School Modal) --> */}
                <Modal show={showSchoolModal} onHide={() => setShowSchoolModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="schoolmodallabel">My School</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form >
                            <Form.Group className="mb-3 position-relative" controlId="school_code">
                                <Form.Label>
                                    Enter School Code<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control type="text" ref={inputRef} disabled={show} className='' name='school_code' value={school_Code.school_code} onChange={handleInputs} placeholder="Enter School Code" />
                                {show &&
                                    schoolData !== null ?

                                    <div ref={inputRef2} id="school-info" className='position-absolute end-0 top-50 ' >
                                        <button className="btn btn-sm text-danger " onClick={(e) => { handleChange(e); setsetbtn(false) }} type='button'><i className="ti ti-edit" />
                                        </button>


                                    </div> : ''
                                }
                            </Form.Group>

                            {
                                show &&
                                    schoolData !== null ? '' : <Button variant="danger" className="w-100 text-light fw-bold" onClick={(e) => { getSchoolInfo(e); }} >
                                    Search
                                </Button>
                            }

                            {show &&
                                schoolData !== null ?

                                <div ref={inputRef2} id="school-info" className="card">
                                    {
                                        schoolData.school_banner !== null ?
                                            <img src={`${img_url}/${schoolData.folder}/${schoolData.school_banner}`} alt="school" className="img-fluid mb-2 w-100" />
                                            :
                                            <img src={require('../assets/images/school_banner_2.jpeg')} alt="school" className="img-fluid mb-2 w-100" />
                                    }

                                    <h6 className="text-danger fw-bold">{schoolData.school_name}</h6>
                                    <Button variant="danger" className="w-100 text-light fw-bold" onClick={() => handleSelect(school_Code.school_code)}>
                                        Confirm School
                                    </Button>
                                    <div className='my-2'>Already have student account ? <Link to={'/login'} className='text-danger fw-bold' onClick={() => {window.scrollTo({ top: 0, behavior: 'instant' });setShowSchoolModal(false)}}>Login here</Link></div>
                                    {/* <button className="btn border-2 border-danger text-danger btn-light w-100 mt-2" onClick={(e) => { handleChange(e); setsetbtn(false) }} type='button'>Edit</button> */}

                                </div> : <h3 id="schoolname" className="text-danger text-center">{err && err}</h3>
                            }

                        </Form>
                    </Modal.Body>
                </Modal>

                {/* <!-- Modal - 2 (Register model)--> */}
                <Modal show={showRegModal} onHide={() => setShowRegModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <button className='bg-transparent border-0 p-0' onClick={() => { setShowRegModal(false); setShowSchoolModal(true) }}>
                                <i className="ti ti-circle-arrow-left fa-solid fs-3 me-2"></i>
                            </button>
                            Register As Student
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Form>
                            <Form.Group className="mb-3 text-start" controlId="sch-code-cfm">
                                <Form.Label>
                                    School Code<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control type="text" value={studentRegisterData.school_code} readOnly disabled placeholder="630083" />
                            </Form.Group>

                            <Form.Group className="mb-3 text-start" controlId="cfm-num">
                                <Form.Label>
                                    Mobile Number<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control type="tel" onChange={handleInputs} maxLength={10} name='phone_no' value={studentRegisterData.phone_no} placeholder="Mobile number" />
                                <div className="text-danger">{stuRegErrors.phone_no}</div>

                            </Form.Group>

                            <div className="row">
                                <div className="col-lg-6 text-start">
                                    <Form.Group className="mb-3" controlId="fname">
                                        <Form.Label className="text-secondary">
                                            First Name<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control type="text" onChange={handleInputs} name='first_name' value={studentRegisterData.first_name} placeholder="First name" />
                                        <div className="text-danger">{stuRegErrors.first_name}</div>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 text-start">
                                    <Form.Group className="mb-3" controlId="lname">
                                        <Form.Label className="text-secondary">
                                            Last Name<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control type="text" onChange={handleInputs} name='last_name' value={studentRegisterData.last_name} placeholder="Last name" />
                                        <div className="text-danger">{stuRegErrors.last_name}</div>

                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 text-start">
                                    <Form.Group className="mb-3" controlId="father-name">
                                        <Form.Label className="text-secondary">
                                            Father's Name<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control onChange={handleInputs} name='fathers_name' value={studentRegisterData.fathers_name} placeholder="Father's name" />
                                        <div className="text-danger">{stuRegErrors.fathers_name}</div>

                                    </Form.Group>

                                </div>
                                <div className="col-lg-6 text-start">
                                    <Form.Group className="mb-3" controlId="mail">
                                        <Form.Label className="text-secondary">
                                            Email<span className="text-danger"></span>
                                        </Form.Label>
                                        <Form.Control type="email" onChange={handleInputs} name='email' value={studentRegisterData.email} placeholder="Enter your email" />
                                        <div className="text-danger">{stuRegErrors.email}</div>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 text-start">
                                    <Form.Group className="mb-3" controlId="c-pass">
                                        <Form.Label className="text-secondary">
                                            Create Password<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control type="password" onChange={handleInputs} name='password' value={studentRegisterData.password} placeholder="Must have atleast 6 characters" />
                                        <div className="text-danger">{stuRegErrors.password}</div>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 text-start">
                                    <Form.Group className="mb-3" controlId="con-pass">
                                        <Form.Label className="text-secondary">
                                            Confirm Password<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control type="password" onChange={handleInputs} name='confirm_password' value={studentRegisterData.confirm_password} placeholder="Must have atleast 6 characters" />
                                        <div className="text-danger">{stuRegErrors.confirm_password}</div>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 text-start">
                                    <Form.Group className="mb-4" name='state_name' controlId="sel-ste">
                                        <Form.Label>
                                            Select State<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select onChange={(e) => handleState(e)} name='state' value={studentRegisterData.state}>
                                            <option>Select State</option>
                                            {
                                                stateData.map((state, i) => {
                                                    return (
                                                        <option key={i} value={state.state_name}>{state.state_name}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <div className="text-danger mb-3">{stuRegErrors.state}</div>

                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 text-start">
                                    <Form.Group className="mb-4" controlId="sel-dis">
                                        <Form.Label>
                                            Select District<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select onChange={handleInputs} name='district' value={studentRegisterData.district}>
                                            <option>Select District</option>
                                            {
                                                districts.map((district, i) => {
                                                    return (
                                                        <option key={i} value={district}>{district}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                        <div className="text-danger mb-3">{stuRegErrors.district}</div>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="my-1">
                           
                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                        </div>
                            <Form.Check
                                type="checkbox"
                                className='text-start'
                                id="flexCheckDefault"
                                defaultChecked
                                
                                label={
                                    <>
                                        I agree to accept the terms and conditions  <span className='cursor-pointer text-decoration-none text-danger' onClick={() => setShowReadModal(true)}> READ.</span>
                                        {/* <span
                                            className="cursor-pointer text-decoration-none text-danger"
                                            data-bs-toggle="modal"
                                            data-bs-target="#ReadMoreModal"
                                        >
                                            READ.
                                        </span> */}
                                    </>
                                }
                            />

                            <Button variant="danger" onClick={(e) => registerStudent(e)} className="w-100 text-light fw-bold" type="submit">
                                Register
                            </Button>
                        </Form>
                      
                        <div className='my-2 text-start'>Already have student account ? <Link to={'/login'} className='text-danger fw-bold' onClick={() => {window.scrollTo({ top: 0, behavior: 'instant' });setShowRegModal(false)}}>Login here</Link></div>
                    </Modal.Body>
                </Modal>
                <TermsConditions />

                {/* <!-- Modal - 3 (Otp modal)--> */}
                <Modal backdrop="static" size="sm" autoFocus={false} centered show={showOtpModal} onHide={() => setShowOtpModal(false)}>
                    <Modal.Header className='bg-danger text-white' closeButton>
                        <Modal.Title><h5>Enter OTP for Verification</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <div className="form-floating mb-3">
                                <input type="text" autoFocus className="form-control" onChange={handleInputs} name='otp' value={otpData.otp} id="register_otp" placeholder="" />
                                <label htmlFor="register_otp">Enter OTP<span className="text-danger">*</span></label>
                            </div>
                            <button type="button" className="btn btn-pink w-100" >Verify OTP</button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* <!-- Modal - 4 (Read more) --> */}
                <Modal size="lg" autoFocus={false} centered show={showReadModal} onHide={() => setShowReadModal(false)}>
                    <Modal.Header className='bg-danger text-white' closeButton>
                        <Modal.Title><h5>Terms and Condition</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <p style={{ textAlign: 'left' }}>
                                All information, products and services displayed on the <u><b><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com</a></b></u>
                                website constitute an invitation to offer. Your order for purchase constitutes your
                                offer which shall be subject to the terms and conditions as listed below.<br />
                                <b>V4 MASTERS e-vyapari</b> has the right to accept or reject your offer without assigning any
                                reason thereof.<br /><br />
                                In order to use the <b><u><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com </a></u></b>website belonging to V4 MASTERS e-
                                vyapari., you have accepted the following terms and conditions given below.<br />
                                V4 MASTERS e-vyapari reserves the right to add, delete, alter or modify these terms and
                                conditions at any time.<br /><br />
                                You are therefore advised to read carefully these terms and conditions every time you use
                                the <u><b><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com</a></b></u> website.<br /><br />
                                <b>1.</b> We have made every effort to display the color of our products that appear on the site as
                                accurately as possible. However, as the actual colors you see will depend on your monitor,
                                we cannot guarantee that your monitor's display of color will be accurate.<br /><br />
                                <b>2.</b> The User certifies that he/she is at least 18 (eighteen) years of age <b>OR</b> has the consent of
                                a parent or legal guardian.<br /><br />
                                <b>3. V4 MASTERS e-vyapari</b>  will not be responsible for any damage suffered by users from
                                use of the services on evyapari.com. This without limitation includes loss of
                                revenue or data resulting from delays, non-deliveries, missed deliveries, or service
                                interruptions as may occur because of any act / omission of the vendor. This disclaimer of
                                liability also applies to any damages or injury caused by any failure of performance,
                                negligence, defect, deletion, error, omission, interruption, delay in operation or
                                transmission, computer virus, communication line failure, theft or destruction
                                or unauthorized access to, alteration of, or use of record, whether for breach of contract,
                                tortuous behavior, or under any other cause of action.<br /><br />
                                4. <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><b><u>www.evyapari.com</u></b></a> will take "Full Responsibility" of your Order, if the Order has
                                been shipped to any of the major cities, where our sellers use “Courier Companies" (e.g.
                                Trackon, Fed Ex, Delhivery, First Flight, India post or hand to hand pick up delivery) for
                                your order.<br /><br />
                                5. The prices and availability of products are subject to change without prior notice at the
                                sole discretion of <b>V4 MASTERS e-vyapari </b>or the Seller.<br /><br />
                                6. Request for cancellations of orders once placed on www.evyapari.com shall not
                                be entertained.<br /><br />
                                7.  <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><b><u>www.evyapari.com</u></b></a>  reserves the right to refuse or cancel any order placed for a
                                product that is listed at an incorrect price or for any other reason. This shall be regardless.<br /><br />
                            </p>
                        </div>
                    </Modal.Body>
                </Modal>
            </section >

            <ToastContainer />
        </>
    )
}

export default MySchoolPopup
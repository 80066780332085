import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'

const Privacypolicy = () => {
    return (

        <div>
            <Header />
            <main className='mt-5 pt-5'>
                <div className="container">
                    <div className="border rounded-2 border-dark shadow-md  border-2">
                        <h2 className='bg-danger rounded-2 py-2 text-white fw-bold ps-2'>Privacy Policy </h2>
                        <p className='p-2' style={{ textAlign: 'left' }}>
                            -- Your privacy is critically important to us.<br />
                            V4 MASTERS e-vyapari is located at:<br />
                            <b>V4 MASTERS e-vyapari&lt;<br />
                                SHOP NO. 148, BHAGAT COMPLEX<br />
                                VPO NADAUN HAMIRPUR 177033<br />
                                HIMACHAL PRADESH, INDIA<br />
                                9817475121</b><br /><br />
                            It is V4 MASTERS e-vyapari's policy to respect your privacy regarding any information we may
                            collect
                            while operating our website. This Privacy Policy applies to evyapari.com (hereinafter,
                            "us", "we",
                            or “www.evyapari.com "). We respect your privacy and are committed to protecting personally
                            identifiable information you may provide us through the Website. We have adopted this privacy
                            policy
                            ("Privacy Policy") to explain what information may be collected on our Website, how we
                            use this
                            information, and under what circumstances we may disclose the information to third parties. This
                            Privacy Policy applies only to information we collect through the Website and does not apply to
                            our
                            collection of information from other sources.<br /><br />
                            This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the
                            general
                            rules and policies governing your use of our Website. Depending on your activities when visiting
                            our
                            Website, you may be required to agree to additional terms and conditions.<br /><br /><br />
                            <b>- Website Visitors</b><br /><br />
                            Like most website operators, V4 MASTERS e-vyapari collects non-personally-identifying
                            information of
                            the sort that web browsers and servers typically make available, such as the browser type,
                            language
                            preference, referring site, and the date and time of each visitor request. V4 MASTERS
                            e-vyapari's
                            purpose in collecting non-personally identifying information is to better understand how V4
                            MASTERS e-
                            vyapari's visitors use its website. From time to time, V4 MASTERS e-vyapari may release
                            non-personally-
                            identifying information in the aggregate, e.g., by publishing a report on trends in the usage of
                            its
                            website.<br /><br />
                            V4 MASTERS e-vyapari also collects potentially personally-identifying information like Internet
                            Protocol
                            (IP) addresses for logged in users and for users leaving comments on http://evyapari.com blog
                            posts. V4 MASTERS e-vyapari only discloses logged in user and commenter IP addresses under the
                            same
                            circumstances that it uses and discloses personally-identifying information as described
                            below.<br /><br /><br /><br />
                            <b>- Gathering of Personally-Identifying Information</b><br /><br />
                            Certain visitors to V4 MASTERS e-vyapari's websites choose to interact with V4 MASTERS
                            e-vyapari in
                            ways that require V4 MASTERS e-vyapari to gather personally-identifying information. The amount
                            and
                            type of information that V4 MASTERS e-vyapari gathers depends on the nature of the interaction.
                            For
                            example, we ask visitors who sign up for a blog at <u>http://evyapari.com</u>to provide a
                            username
                            and email address.<br /><br />
                            <b>- Security</b><br /><br />
                            The security of your Personal Information is important to us, but remembers that no method of
                            transmission over the Internet, or method of electronic storage is 100% secure. While we strive
                            to use
                            commercially acceptable means to protect your Personal Information, we cannot guarantee its
                            absolute
                            security.<br />
                            <b>- Advertisements</b><br /><br />
                            Ads appearing on our website may be delivered to users by advertising partners, who may set
                            cookies.
                            These cookies allow the ad server to recognize your computer each time they send you an online
                            advertisement to compile information about you or others who use your computer. This information
                            allows ad networks to, among other things, deliver targeted advertisements that they believe
                            will be of
                            most interest to you. This Privacy Policy covers the use of cookies by V4 MASTERS e-vyapari and
                            does
                            not cover the use of cookies by any advertisers.<br /><br /><br /><br />
                            <b>- Links to External Sites</b><br /><br />
                            Our Service may contain links to external sites that are not operated by us. If you click on a
                            third party
                            link, you will be directed to that third party's site. We strongly advise you to review the
                            Privacy Policy
                            and terms and conditions of every site you visit.<br /><br />
                            We have no control over, and assume no responsibility for the content, privacy policies or
                            practices of
                            any third party sites, products or services.<br /><br />
                            <b>- evyapari.com uses Google Ad Words for remarketing</b><br /><br />
                            evyapari.com uses the remarketing services to advertise on third party websites (including
                            Google) to previous visitors to our site. It could mean that we advertise to previous visitors
                            who haven't
                            completed a task on our site, for example using the contact form to make an enquiry. This could
                            be in
                            the form of an advertisement on the Google search results page, or a site in the Google Display
                            Network. Third-party vendors, including Google, use cookies to serve ads based on someone's
                            past
                            visits. Of course, any data collected will be used in accordance with our own privacy policy and
                            Google's
                            privacy policy.<br /><br />
                            You can set preferences for how Google advertises to you using the Google Ad Preferences page,
                            and if
                            you want to you can opt out of interest-based advertising entirely by cookie settings or
                            permanently
                            using a browser plug-in.<br /><br /><br />
                            <b>-Protection of Certain Personally-Identifying Information</b><br /><br />
                            V4 MASTERS e-vyapari discloses potentially personally-identifying and personally-identifying
                            information
                            only to those of its employees, contractors and affiliated organizations that<br /><br />
                            <b>(i) </b> need to know that information in order to process it on V4 MASTERS e-vyapari's
                            behalf or to
                            provide services available at V4 MASTERS e-vyapari's website, and<br /><br />
                            <b>(ii) </b>That has agreed not to disclose it to others. Some of those employees, contractors
                            and affiliated
                            organizations may be located outside of your home country; by using V4 MASTERS e-vyapari's
                            website, you consent to the transfer of such information to them. V4 MASTERS e-vyapari will
                            not rent or sell potentially personally-identifying and personally-identifying information to
                            anyone. Other than to its employees, contractors and affiliated organizations, as described
                            above, V4 MASTERS e-vyapari discloses potentially personally-identifying and personally-
                            identifying information only in response to a subpoena, court order or other governmental
                            request, or when V4 MASTERS e-vyapari believes in good faith that disclosure is reasonably
                            necessary to protect the property or rights of V4 MASTERS e-vyapari, third parties or the public
                            at large.<br /><br /><br />
                            If you are a registered user of http://evyapari.com and have supplied your email address, V4
                            MASTERS e-vyapari may occasionally send you an email to tell you about new features, solicit
                            your
                            feedback, or just keep you up to date with what's going on with V4 MASTERS e-vyapari and our
                            products. We primarily use our blog to communicate this type of information, so we expect to
                            keep this
                            type of email to a minimum. If you send us a request (for example via a support email or via one
                            of our
                            feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond
                            to your
                            request or to help us support other users. V4 MASTERS e-vyapari takes all measures reasonably
                            necessary to protect against the unauthorized access, use, alteration or destruction of
                            potentially
                            personally-identifying and personally-identifying information.<br /><br /><br />
                            <b>- Aggregated Statistics</b><br /><br />
                            V4 MASTERS e-vyapari may collect statistics about the behavior of visitors to its website. V4
                            MASTERS e-
                            vyapari may display this information publicly or provide it to others. However, V4 MASTERS
                            e-vyapari
                            does not disclose your personally-identifying information.<br /><br />
                            <b>- Affiliate Disclosure</b><br /><br />
                            This site uses affiliate links and does earn a commission from certain links. This does not
                            affect your
                            purchases or the price you may pay.<br /><br /><br />
                            <b>-Cookies</b><br /><br />
                            To enrich and perfect your online experience, V4 MASTERS e-vyapari uses "Cookies",
                            similar
                            technologies and services provided by others to display personalized content, appropriate
                            advertising
                            and store your preferences on your computer.<br /><br />
                            A cookie is a string of information that a website stores on a visitor's computer, and that
                            the visitor's
                            browser provides to the website each time the visitor returns. V4 MASTERS e-vyapari uses cookies
                            to
                            help V4 MASTERS e-vyapari identify and track visitors, their usage of http://evyapari.com, and
                            their website access preferences. V4 MASTERS e-vyapari visitors who do not wish to have cookies
                            placed
                            on their computers should set their browsers to refuse cookies before using V4 MASTERS
                            e-vyapari's
                            websites, with the drawback that certain features of V4 MASTERS e-vyapari's websites may not
                            function
                            properly without the aid of cookies.<br /><br />
                            By continuing to navigate our website without changing your cookie settings, you hereby
                            acknowledge
                            and agree to V4 MASTERS e-vyapari's use of cookies.<br /><br />
                            <b>- E-commerce</b><br /><br />
                            Those who engage in transactions with V4 MASTERS e-vyapari – by purchasing V4 MASTERS
                            e-vyapari's
                            services or products, are asked to provide additional information, including as necessary the
                            personal
                            and financial information required to process those transactions. In each case, V4 MASTERS
                            e-vyapari
                            collects such information only insofar as is necessary or appropriate to fulfill the purpose of
                            the visitor's
                            interaction with V4 MASTERS e-vyapari. V4 MASTERS e-vyapari does not disclose
                            personally-identifying
                            information other than as described below. And visitors can always refuse to supply personally-
                            identifying information, with the caveat that it may prevent them from engaging in certain
                            website-
                            related activities.<br /><br />
                            <b>- Business Transfers</b><br /><br />
                            If V4 MASTERS e-vyapari, or substantially all of its assets, were acquired, or in the unlikely
                            event that V4
                            MASTERS e-vyapari goes out of business or enters bankruptcy, user information would be one of
                            the
                            assets that is transferred or acquired by a third party. You acknowledge that such transfers may
                            occur,
                            and that any acquirer of V4 MASTERS e-vyapari may continue to use your personal information as
                            set
                            forth in this policy.<br /><br />
                            <b>Privacy Policy Changes</b><br /><br />
                            Although most changes are likely to be minor, V4 MASTERS e-vyapari may change its Privacy Policy
                            from
                            time to time, and in V4 MASTERS e-vyapari's sole discretion. V4 MASTERS e-vyapari encourages
                            visitors
                            to frequently check this page for any changes to its Privacy Policy. Your continued use of this
                            site after
                            any change in this Privacy Policy will constitute your acceptance of such change.<br /><br />
                        </p>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Privacypolicy
import React, { useEffect, useState } from 'react'
import Data from '../data/Data.json'
import ProfileCards from '../components/ProfileCards'
import Header from '../layout/Header';
import { useGlobalContext } from '../contexts/GlobalContext';
import profileimg from '../assets/images/uploadimg.svg'
import { Link } from 'react-router-dom';
import logo from '../assets/images/main-logo.svg';
import { useInventoryContext } from '../contexts/InventoryContext';
import { toast } from 'react-toastify';
import axios from 'axios';

const MyProfile = () => {
   const { profiledata } = Data;
   const { getUserInformation, userInformation, img_url, api_url ,setIsLoading , isLoading } = useGlobalContext()
   const { updateProfileDetails, profileDetails, setProfileDetails } = useInventoryContext()
   const [form, setForm] = useState({})
   const [changeimage, setChangeImage] = useState(false)
   const [imagePreview, setImagePreview] = useState(null);

   const handleinput = (e) => {
      const { files } = e.target;
      setForm(e.target.files[0])

      const file = files[0];



      setChangeImage(true)
      const reader = new FileReader();
      reader.onloadend = () => {
         setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);

      console.log(form)

   }
   const triggerFileInput = () => {
      document.getElementById('fileInput').click();
   };
   useEffect(() => {
      getUserInformation()

   }, [])
   const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true)
      console.log(form)
      
      const formData = new FormData();

      formData.append('profile_image', form)
      formData.append('user_id', sessionStorage.getItem('user_id'))


      console.log('Review', formData)
      // const errors = ReviewValidation();
      // setReviewErrors(errors);

      // if (Object.keys(errors).length === 0) {
      try {
         const res = await axios.post(`${api_url}/updateProfileInformation`, formData)
         if (res.data.success === 1) {
            toast.success(res.data.message, {
               autoClose: 1500,
               theme: "colored",
            });
            console.log(res.data)
            setIsLoading(false)
            setTimeout(() => {
               getUserInformation()
               
               setChangeImage(false)
               // window.location.reload()
            }, 2000);
         } else {
            toast.error('Something Went Wrong', {
               autoClose: 1500,
               theme: "colored",
            });
            setIsLoading(false)


         }
      } catch (error) {
         toast.error("Axios Error", {
            autoClose: 1500,
            theme: "colored",
         });
         setIsLoading(false)

      }
      // }
   }


   return (
      <>
      {/* {isLoading ? <Loading/>} */}
         <Header />
         <main id='main' className='mt-5 pt-5 space-top'>
            <div className="eVyapari-content w-100">
               <div className="text-center  mb-4 d-block d-md-none">
                  <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
               </div>
            </div>
            {/* {JSON.stringify(userInformation)} */}
            <section id="profile-bnr">
               <h2 className="text-center pt-4 fw-bold d-sm-block d-none">Your Account</h2>
            </section>
            <section id="myprofile" className="pb-4">
               <div className="container">
                  <div className="row g-4 mt-sm-4 mt-2">
                     <div className="col-md-4">
                        <div className="rounded-5 bg-white border-section mx-auto">
                           <div className="text-center ">
                              {/* <i className="ti ti-camera fs-1 mb-5 " /> */}
                              {/* {JSON.stringify(userInformation.profile)} */}

                              <div className='text-center'>
                                 {changeimage ? <img
                                    src={imagePreview}
                                    alt="image-preview"
                                    className="rounded-5  w-100 object-fit-fill"
                                    height={250}
                                    style={{ cursor: 'pointer', maxWidth: "300px" }}
                                    onClick={triggerFileInput}

                                 /> : <img
                                    src={userInformation?.profile ? `${img_url}/${userInformation?.folder}/${userInformation?.profile}` : profileimg} // Use the preview or default image
                                    alt="image-preview"
                                    className="rounded-5 w-100 object-fit-contain"
                                    height={250}
                                    style={{ cursor: 'pointer', maxWidth: "300px" }} // Make the image look clickable
                                    onClick={triggerFileInput}

                                 />}
                                 {/* <i className="ti ti-camera fs-1 mb-5 " /> */}
                                 {/* <img
                                                src={imagePreview}
                                                alt="image-preview"
                                                className="rounded-5"
                                                height={250}
                                                style={{ cursor: 'pointer', maxWidth: "300px" }}
                                                onClick={triggerFileInput}

                                            /> */}
                                 <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    name='image'
                                    onChange={handleinput}
                                 />
                              </div>

                              {/* <img src={`${img_url}/${userInformation?.folder}/${userInformation?.profile}`|| profileimg} alt="" /> */}
                              {/* <p><label htmlFor="formFile" className="form-label">upload Your Profile Pic</label></p> */}
                              {/* <input className="form-control" type="file" id="formFile"> */}

                           </div>
                        </div>
                        {changeimage ? <button className='btn btn-danger mt-2 w-100' onClick={(e) => handleSubmit(e)}>Upload</button> : ''}
                     </div>
                     <div className="col-md-8">
                        {
                           profiledata.map((card, i) => {
                              return (
                                 <ProfileCards
                                    key={i}
                                    imgsrc={card.image}
                                    title={card.title}
                                    text={card.text}
                                    link={card.link}
                                 />
                              )
                           })
                        }
                     </div>
                  </div>
               </div>
            </section>
         </main>
      </>
   )
}

export default MyProfile
import React from 'react'
import Cartimg from '../assets/images/cart-img.png'
import { useNavigate } from 'react-router-dom'

const ProductCart = () => {
    const navigate=useNavigate()
    return (
        <div>
            <main>
                <section id="cart-top" className="py-5">
                    <div className="container">
                        <div className="row g-4 d-flex justify-content-between">
                            <div className="col-md-4">
                                <h5 className="fw-bold fs-3 my-cart">Your Cart</h5>
                            </div>
                            <div className="col-md-3">
                                <div className="d-flex justify-content-center gap-2">
                                    <a href="#" className="btn wishlist btn-light shadow-btm px-4 rounded-2 border border-1 border-dark"> <i className="fa-regular fa-heart text-dark me-2" />Wishlist</a>
                                    <div className>
                                        <a href="#" className="btn cart-btn btn-dark shadow-btm  px-2 rounded-2 border border-1 border-dark">
                                            <i className="fa-solid fa-cart-shopping text-light me-2" />Cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="cart-item-list" className="py-5 clearfix">
                    <div className="container">
                        <div className="float-md-start list-area text-sm-start text-center">
                            <div className="card border-0 shadow rounded-4 px-2 mb-3">
                                <div className="card-body d-flex align-items-center flex-wrap gap-2">
                                    <img className="rounded-4 mx-sm-0 mx-auto" src={Cartimg} alt="cart-img" />
                                    <div className="mx-2">
                                        <h5>JMD Computer Science</h5>
                                        <p className="mb-0">Short Cut To Sure Success</p>
                                    </div>
                                    <button type="button" className="btn btn-outline-dark rounded-3 border border-2 border-dark px-4"><span>-</span><span>1</span><span> +</span></button>
                                    <a href="#" className="btn btn-outline-dark border-2 text-danger"><i className="fa-solid fa-trash-can" /></a>
                                    <a href="#" className="btn btn-dark text-light"><i className="fa-regular fa-heart" /></a>
                                    <div className="price ms-auto fs-3 pe-4 fw-bold">₹240</div>
                                </div>
                            </div>
                            <div className="card border-0 shadow rounded-4 px-2 mb-3">
                                <div className="card-body d-flex align-items-center flex-wrap gap-2">
                                    <img className="rounded-4 mx-sm-0 mx-auto" src={Cartimg} alt="cart-img" />
                                    <div className="mx-2">
                                        <h5>JMD Computer Science</h5>
                                        <p className="mb-0">Short Cut To Sure Success</p>
                                    </div>
                                    <button type="button" className="btn btn-outline-dark rounded-3 border border-2 border-dark px-4"><span>-</span><span>1</span><span> +</span></button>
                                    <a href="#" className="btn btn-outline-dark border-2 text-danger"><i className="fa-solid fa-trash-can" /></a>
                                    <a href="#" className="btn btn-dark text-light"><i className="fa-regular fa-heart" /></a>
                                    <div className="price ms-auto fs-3 pe-4 fw-bold">₹240</div>
                                </div>
                            </div>
                            <div className="card border-0 shadow rounded-4 px-2 mb-3">
                                <div className="card-body d-flex align-items-center flex-wrap gap-2">
                                    <img className="rounded-4 mx-sm-0 mx-auto" src={Cartimg} alt="cart-img" />
                                    <div className="mx-2">
                                        <h5>JMD Computer Science</h5>
                                        <p className="mb-0">Short Cut To Sure Success</p>
                                    </div>
                                    <button type="button" className="btn btn-outline-dark rounded-3 border border-2 border-dark px-4"><span>-</span><span>1</span><span> +</span></button>
                                    <a href="#" className="btn btn-outline-dark border-2 text-danger"><i className="fa-solid fa-trash-can" /></a>
                                    <a href="#" className="btn btn-dark text-light"><i className="fa-regular fa-heart" /></a>
                                    <div className=" price ms-auto fs-3 pe-4 fw-bold">₹240</div>
                                </div>
                            </div>
                            <div className="card border-0 shadow rounded-4 px-2 mb-3">
                                <div className="card-body d-flex align-items-center flex-wrap gap-2">
                                    <img className="rounded-4 mx-sm-0 mx-auto" src={Cartimg} alt="cart-img" />
                                    <div className="mx-2">
                                        <h5>JMD Computer Science</h5>
                                        <p className="mb-0">Short Cut To Sure Success</p>
                                    </div>
                                    <button type="button" className="btn btn-outline-dark rounded-3 border border-2 border-dark px-4"><span>-</span><span>1</span><span> +</span></button>
                                    <a href="#" className="btn btn-outline-dark border-2 text-danger"><i className="fa-solid fa-trash-can" /></a>
                                    <a href="#" className="btn btn-dark text-light"><i className="fa-regular fa-heart" /></a>
                                    <div className=" price ms-auto fs-3 pe-4 fw-bold">₹240</div>
                                </div>
                            </div>
                        </div>
                        {/*--------------chekhout---------*/}
                        <div className="float-md-end bill-details">
                            <div className=" bg-white border border-1 p-4 rounded-4 border border-dark">
                                <div className="d-flex justify-content-between bg-white">
                                    <button onClick={(e)=>navigate('/cart')} className='border-0 bg-transparent'><i className="fa-solid fa-circle-arrow-left fs-4" /></button>
                                    <div>
                                        <p className="font-cart">Your Information</p>
                                    </div>
                                </div>
                                <form className="bg-white">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="fname" className="form-label text-secondary">First Name</label>
                                                <input type="fname" className="form-control text-black" id="fname" placeholder="Joe" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="lname" className="form-label text-secondary">Last Name</label>
                                                <input type="email" className="form-control text-black" id="lname" placeholder="Rana" />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label text-secondary">Email</label>
                                            <input type="email" className="form-control text-black" id="email" placeholder="joerana123@gmail.com" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="number" className="form-label text-secondary">Contact Number</label>
                                            <input type="tel" className="form-control text-black" id="number" placeholder="+91  789456321" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label text-secondary">Create Password</label>
                                            <input type="password" className="form-control text-black" id="password" placeholder="********" />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="state" className="form-label text-secondary">Select State</label>
                                                <input type="text" className="form-control" id="state" placeholder="Himachal Pradesh" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="district" className="form-label text-secondary">Select
                                                    District</label>
                                                <input type="text" className="form-control" id="district" placeholder="Shimla" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="post-office" className="form-label text-secondary">Podt
                                                    Office</label>
                                                <input type="text" className="form-control text-black" id="post-office" placeholder="Himachal Pradesh" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="pin-code" className="form-label text-secondary">Pin Code</label>
                                                <input type="number" className="form-control text-black" id="pin-code" placeholder="Shimla" />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="landmark" className="form-label text-secondary">landmark house number
                                                building name</label>
                                            <input type="text" className="form-control text-black" id="landmark" placeholder="Type Here" />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="ward" className="form-label text-secondary">Village/Ward No.</label>
                                                <input type="text" className="form-control text-black" id="ward" placeholder="Sainj" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="city" className="form-label text-secondary ">City</label>
                                                <input type="text" className="form-control text-black" id="city" placeholder="Shimla" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="p-1">
                                                <button type="button" className="btn btn-white border border-1 text-black">Back</button>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="d-grid mx-auto">
                                                <button className="btn btn-danger" type="button">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default ProductCart
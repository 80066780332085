import React, { useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext';

const Step2 = () => {
    const { registerData, changeHandler, setCurrentStep, districts, stateData, handleStateChange } = useGlobalContext();
    //Validation
    const { phone_no, state, district } = registerData;
    const step2_errors = {};
    const [errors, setErrors] = useState({});
    var phoneRegex = /^\d{10}$/;

    if (phone_no === '') {
        step2_errors.phone_no = "Mobile Number Required";
    } else if (!(phoneRegex.test(phone_no))) {
        step2_errors.phone_no = "Enter 10 Digit Number";
    }
    if (state === '') {
        step2_errors.state = "State Required";
    }
    if (district === '') {
        step2_errors.district = "District Required";
    }

    const handleState = (e) => {
        handleStateChange(e);
        changeHandler(e);
    }

    const handleClick = () => {
        setErrors(step2_errors);

        if (Object.keys(step2_errors).length === 0) {
            setCurrentStep(3)
        }
    };

    return (
        <>
            <div >
                <div className='text-start'>
                    <div className="form-group text-start">
                        <label htmlFor="number">Mobile Number</label>
                        <input type="tel" className="form-control" id="number" placeholder="Mobile Number" value={registerData.phone_no} name='phone_no' onChange={(e) => changeHandler(e)} maxlength="10" minlength="10" />
                        <div className='text-danger s-font2'>{errors.phone_no}</div>
                    </div>
                    <div className="form-group pt-3 text-start">
                        <label htmlFor="state">Select State</label>
                        <select className="form-control" id='state' onChange={(e) => handleState(e)} name='state' value={registerData.state}>
                            <option value="" disabled selected>--Select State--</option>
                            {
                                stateData.map((state, i) => {
                                    return (
                                        <option key={i} value={state.state_name}>{state.state_name}</option>
                                    )
                                })
                            }
                        </select>

                        <div className="text-danger mb-3">{errors.state}</div>
                    </div>
                    <div className="form-group pt-3 text-start">
                        <label htmlFor="district">Select District</label>
                        <select className="form-control" id="district" onChange={(e) => changeHandler(e)} name='district' value={registerData.district}>
                            <option value="" disabled selected>--Select District--</option>
                            {
                                districts.map((district, i) => {
                                    return (
                                        <option key={i} value={district}>{district}</option>
                                    )
                                })
                            }
                        </select>

                        <div className="text-danger mb-3">{errors.district}</div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <button type="button" className="mt-4 btn border-dark fw-bold w-100" id="prevBtn" onClick={() => setCurrentStep(1)}>Back</button>
                        </div>
                        <div className="col">
                            <button type="button" className="mt-4 btn btn-danger w-100 " id="nextBtn" onClick={handleClick}>Next</button>
                        </div>
                    </div>
                </div>

                {/* <div className="form-floating my-3">
                    <input type="tel" id="phone_no" name="phone_no" value={registerData.phone_no} onChange={(e) => changeHandler(e)} maxlength="10" minlength="10" className="form-control" placeholder='' />
                    <label className="form-label" for="phone_no">Mobile Number</label>
                    <div className='text-danger s-font2'>{errors.phone_no}</div>
                </div>
                <div className="form-floating my-3">
                    <select className="form-select py-3 mb-3" onChange={(e) => handleState(e)} name='state' value={registerData.state} aria-label="Default select example">
                        <option value="Select State">--Select State--</option>
                        {
                            stateData.map((state, i) => {
                                return (
                                    <option key={i} value={state.state_name}>{state.state_name}</option>
                                )
                            })
                        }
                    </select>
                    <div className="text-danger mb-3">{errors.state}</div>
                </div>
                <div className="form-floating my-3">
                    <select className="form-select py-3" onChange={(e) => changeHandler(e)} name='district' value={registerData.district} aria-label="Default select example">
                        <option>--Select District--</option>
                        {
                            districts.map((district, i) => {
                                return (
                                    <option key={i} value={district}>{district}</option>
                                )
                            })
                        }
                    </select>
                    <div className="text-danger mb-3">{errors.district}</div>
                </div> */}
            </div>

            {/* <div className="overflow-auto">
                <div className="float-end">
                    <button type="button" className="btn btn-danger me-2" id="prevBtn" onClick={() => setCurrentStep(1)}>Previous</button>
                    <button type="button" className="btn btn-danger" id="nextBtn" onClick={handleClick}>Next</button>
                </div>
            </div> */}
        </>
    )
}

export default Step2
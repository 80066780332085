import React from 'react'
import { useGlobalContext } from '../contexts/GlobalContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useInventoryContext } from '../contexts/InventoryContext';

const WishItem = (props) => {
    const { product_name, product_id, image, alt, discounted_price, mrp } = props;
    const { handleAddToCart, api_url, userId, setCartLength, setIsLoading, setWishlistLength, sessionType } = useGlobalContext();
    const { setPageInventory, setHomeInventory, setAllInventory } = useInventoryContext();

    const moveToCart = async (e, pid) => {
        const cartData = {
            "user_id": userId,
            "product_id": pid,
            "session_type": sessionType,
            "qty": 1,
            "move_to_cart": 1,
        };

        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Removing"

        try {
            setIsLoading(true);
            const res = await axios.post(`${api_url}/addCartProduct`, cartData);
            if (res.data.success === 1) {
                toast.success(res.data.message, {
                    autoClose: 2000,
                })
                setCartLength((prev) => prev + 1);
                setWishlistLength((prev) => prev - 1);
                thisClicked.closest("#wishlist_item").remove();
            }
            else {
                toast.error(res.data.message, {
                    autoClose: 3000,
                })
                thisClicked.innerText = "Add to Cart"
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }
    const handlewish = (product_id) => {


        setHomeInventory(cartItems =>
            cartItems.map(CartItem =>
                CartItem.id === product_id ? { ...CartItem, itemExistInWishlist: true } : CartItem
            )
        );
        setAllInventory(cartItems =>
            cartItems.map(CartItem =>
                CartItem.id === product_id ? { ...CartItem, itemExistInWishlist: true } : CartItem
            )
        );
        setPageInventory(cartItems =>
            cartItems.map(CartItem =>
                CartItem.id === product_id ? { ...CartItem, itemExistInWishlist: true } : CartItem
            )
        );

        setWishlistLength((prev) => prev - 1);

    }
    const removeWishlistItem = async (e, product_id) => {
        const data = {
            user_id: userId,
            product_id: product_id
        }
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Removing"
        try {
            const res = await axios.post(`${api_url}/removeWishlistItem`, data);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 3000,
                    theme: 'colored'
                })
                thisClicked.closest("#wishlist_item").remove();
                setHomeInventory(cartItems =>
                    cartItems.map(CartItem =>
                        CartItem.id === product_id ? { ...CartItem, itemExistInWishlist: false } : CartItem
                    )
                );
                setAllInventory(cartItems =>
                    cartItems.map(CartItem =>
                        CartItem.id === product_id ? { ...CartItem, itemExistInWishlist: false } : CartItem
                    )
                );
                setPageInventory(cartItems =>
                    cartItems.map(CartItem =>
                        CartItem.id === product_id ? { ...CartItem, itemExistInWishlist: false } : CartItem
                    )
                );

                setWishlistLength((prev) => prev - 1);
            } else {
                toast.error(res.data.message, {
                    autoClose: 3000
                })
                thisClicked.innerText = "Remove From List"
            }
        } catch (error) {
            toast.error('Axios Error', {
                autoClose: 3000
            });
        }
    }

    return (
        <>




            <div id='wishlist_item'>
                <div className="card border-0 shadow rounded-4 px-2 mb-2" >
                    {/* {JSON.stringify(item)} */}
                    <div className="card-body d-flex align-items-md-end flex-wrap gap-2  justify-content-start">
                        <img className="rounded-4 mx-sm-0 my-auto" src={image} height={100} width={76} alt="cart-img" />
                        <div className="ms-2">
                            <Link to={`/product/${product_id}`} className='text-decoration-none text-dark'>
                                <h5 className='text-start'>{product_name.length > 20 ? `${product_name.slice(0, 21)}...` : product_name}&nbsp;</h5>
                            </Link>
                            <p>Short Cut To Sure Success</p>
                            <button onClick={(e) => {
                                removeWishlistItem(e, product_id);

                            }} className="btn btn-light d-none d-md-block px-4 rounded-3 border border-1 border-dark mt-2 btn-font-size">
                                Remove from Wishlist </button>
                        </div>
                        <div className="mx-3 text-md-end text-start">
                            <div className=" "><span className="text-decoration-line text-secondary me-2 fw-bold fs-4">₹
                                {mrp}</span> <span className="fw-bold fs-4 "> ₹{discounted_price} </span> </div>
                            <button onClick={(e) => moveToCart(e, product_id)} className="btn w-100 btn-danger  rounded-3 mt-2 btn-font-size">Add to Cart </button>
                        </div>
                        <button onClick={(e) => {
                            removeWishlistItem(e, product_id);

                        }} className="btn btn-light d-md-none d-block  rounded-3 border border-1 border-dark mt-2 btn-font-size h-100 mb-0 mt-auto">
                            <i className='ti ti-trash'></i> </button>
                    </div>

                    <div className="float-md-end bill-details">
                    </div>
                </div>
            </div>


            {/* <div id='wishlist_item' className="mb-5">
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-2 col-md-3">
                                <div className="wishlist-img-contain">
                                    <img src={image} className="d-block img-fluid mx-auto" alt={alt} />
                                </div>
                            </div>
                            <div className="col-10 col-md-6">
                                <div className="ms-5">
                                    <Link to={`/seeallfeature/${product_id}`} className="text-decoration-none text-dark"><h4>{product_name}</h4></Link>
                                    <p className='me-3'><del className='h5 text-secondary'>₹{mrp}</del><span className='h4'> ₹{discounted_price}</span></p>
                                    <button className="btn btn-pink rounded-5 px-5 mb-3 mt-4 mt-sm-0 w-100" onClick={(e) => moveToCart(e, product_id)}>Add to Cart</button>
                                    <button className="btn btn-outline-pink rounded-5 px-5 w-100" onClick={(e) => removeWishlistItem(e, product_id)}>Remove From List</button>
                               
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}
        </>
    )
}

export default WishItem
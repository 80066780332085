import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: 'black',
        boxShadow: '0px 0px 5px black',
        right: ' -5px'
      }}
      onClick={onClick}
    />
  );
}
function Arrow2(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: 'black',
        boxShadow: '0px 0px 5px black',
        left: '-4px',
        zIndex: ' 1'
      }}
      onClick={onClick}
    />
  );
}

function CarouselSlider(props) {
  const { items, title, auto_play, display_dots } = props;
  var settings = {
    dots: display_dots,
    infinite: true,
    speed: 500,
    autoplay: auto_play,
    slidesToShow: 4,
    slidesToScroll: 1,
    // arrows:false,
    nextArrow: <Arrow />,
    prevArrow: <Arrow2 />,

    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 1,
        }
      },

    ]
  };
  const carouselItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  };
  const sliderRef = useRef(null);
  const { pathname } = useLocation()
  useEffect(() => {
    const calculateHeight = () => {
      if (sliderRef.current) {
        const itemElements = sliderRef.current.innerSlider.list.querySelectorAll('.carousel-item');
        const maxHeight = Array.from(itemElements).reduce((max, item) => {
          return Math.max(max, item.clientHeight);
        }, 0);

        itemElements.forEach((item) => {
          item.style.height = `${maxHeight}px`;
        });
      }
    };

    // Set a timeout to ensure the DOM updates are complete
    const timeoutId = setTimeout(calculateHeight, 100); // Adjust timeout as needed

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, [items, pathname]);
  // useEffect(() => {
  //   if (sliderRef.current) {
  //     const itemElements = sliderRef.current.innerSlider.list.querySelectorAll('.carousel-item');
  //     const itemElements2 = sliderRef.current.innerSlider.list.querySelectorAll('.slick-list ');
  //     const maxHeight = Array.from(itemElements).reduce((max, item) => {
  //       return Math.max(max, item.clientHeight);
  //     }, 0);

  //     itemElements.forEach((item) => {
  //       item.style.height = `${maxHeight}px`;
  //     });
  //     itemElements2.forEach((item) => {
  //       item.style.height = `${maxHeight}px !important`;
  //     });
  //   }
  // }, [items,pathname]);
  return (
    <section id="most-order" className="py-5 pt-2">
      <div className="container">
        <h4 className="mb-5 text-center text-light">{title}</h4>
        <Slider {...settings} ref={sliderRef} >
          {
            items.map((item, i) => {
              return (
                <div key={i} className="carousel-item">
                  {item}
                </div>

              )
            })
          }
        </Slider>
      </div>

    </section>

  );
}

export default CarouselSlider;

import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useGlobalContext } from '../contexts/GlobalContext';

const BottomNavigationBar = () => {
    const { setShowNavbarOffCanvas, cartLength, isAuthenticated, schools, getSchools, convertToPlain, setShowSchoolModal, getAllInventory, allInventory } = useGlobalContext();
    const { pathname } = useLocation()
    return (
        <>
            <div className='clear p-4 p-md-0 bg-danger mt-1'></div>

            <div className="poition-fixed fixed-bottom mobile-navbar d-block d-md-none">

                <div className="container">
                    <div className="bg-white shadow-navbar py-2 rounded-4 px-2">
                        <ul className="bottom-nav d-flex m-0 px-0">
                            <li className="item flex-fill text-center">
                                <Link to={'/'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none text-dark d-flex flex-column'>
                                    <div className={` ${pathname === '/' ? ' bg-danger rounded-pill' : ''} `}>
                                        <i className={`ti ti-home footicon ${pathname === '/' ? '  text-white ' : 'text-dark'} `} />
                                    </div>
                                    Home
                                </Link>
                            </li>

                            <li className="item flex-fill text-center">
                                <Link to={'/marketplace'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none text-dark  d-flex flex-column'>
                                    <div className={` ${pathname === '/marketplace' ? ' bg-danger rounded-pill' : ''} `}>
                                        <i className={`ti ti-category footicon ${pathname === '/marketplace' ? '  text-white ' : 'text-dark'} `} />
                                    </div>
                                    Marketplace
                                </Link>
                            </li>

                            <li className={`item flex-fill text-center school-navbar  position-relative ${pathname === '/myschool' ? ' ' : 'active'} `}>
                                {
                                    sessionStorage.getItem('school_code') === null ?
                                        <Link onClick={() => setShowSchoolModal(true)} className='text-decoration-none   d-flex flex-column active text-white  position-absolute school-box'>
                                            <div className={` d-flex flex-column justify-content-start mobile-school `}>
                                                {/* <i className={`ti ti-school footicon text-danger  px-1 rounded-2`} /> */}
                                                <span>
                                                    My School
                                                </span>
                                            </div>

                                        </Link>
                                        :
                                        <Link to={'/myschool'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className={`text-decoration-none   d-flex flex-column text-dark  position-absolute school-box ${pathname === '/myschool' ? '' : 'active'}`}>
                                            <div className={` ${pathname === '/myschool' ? ' text-danger' : 'active text-white'} d-flex flex-column justify-content-start mobile-school `}>
                                                {/* <i className={`ti ti-school footicon    ${pathname === '/myschool' ? ' text-white' : 'text-danger'}`} /> */}
                                                <span>

                                                    My School
                                                </span>
                                            </div>

                                        </Link>

                                }
                            </li>

                            <li className="item flex-fill text-center">
                                <Link to={'/orderhistory '} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none text-dark  d-flex flex-column'>
                                    <div className={` ${pathname === '/orderhistory' ? ' bg-danger rounded-pill' : ''} `}>
                                        <i className={`ti ti-package footicon ${pathname === '/orderhistory' ? '  text-white ' : 'text-dark'} `} />
                                    </div>
                                    Order
                                </Link>
                            </li>
                            <li className="item flex-fill text-center">

                                <Link to={'/cart '} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none text-dark  d-flex flex-column '>
                                    <div className={` ${pathname === '/cart' ? ' bg-danger rounded-pill' : ''} `}>
                                        <span className='position-relative'>
                                            <div className={cartLength > 0 ? 'mobile-cart-animation position-absolute top-0 end-0 bg-danger rounded-circle' : 'd-none'}>
                                                <div className={cartLength > 0 ? 'cart-icon' : ''} data-totalitems={cartLength}>
                                                    {cartLength}

                                                </div>
                                            </div>

                                            <i className={`ti ti-shopping-cart footicon ${pathname === '/cart' ? '  text-white ' : 'text-dark'} `} />
                                        </span>
                                    </div>
                                    Cart
                                </Link>
                            </li>
                            {/* <li className="item flex-fill text-center">
                                <Link to={'/cart'} className="btn   btn-white border border-dark cart-btn py-1 ">
                                    <div className='d-flex flex-column position-relative h-100'>
                                        <div className={cartLength > 0 ? 'cart-animation position-absolute top-0 end-0 bg-danger rounded-circle' : 'd-none'}>
                                            <div className={cartLength > 0 ? 'cart-icon' : ''} data-totalitems={cartLength}>
                                                {cartLength}

                                            </div>
                                        </div>
                                        <div className='d-flex flex-column justify-content-center align-items-center h-100'>

                                            <i className="ti ti-shopping-cart" /> Cart
                                        </div>
                                    </div>
                                </Link>
                                <Link to={'/cart '} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none text-dark  d-flex flex-column'>
                                    <div className={` ${pathname === '/cart' ? ' bg-danger rounded-pill' : ''} `}>
                                        <i className={`ti ti-shopping-cart footicon ${pathname === '/cart' ? '  text-white ' : 'text-dark'} `} />
                                    </div>
                                    Cart
                                </Link>
                            </li> */}
                            {/* <li className="item flex-fill text-center">
                                {isAuthenticated ?
                                    <Link to={'/myprofile'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none text-dark  d-flex flex-column'>
                                        <div className={` ${pathname === '/myprofile' ? ' bg-danger rounded-pill' : ''} `}>
                                            <i className={`ti ti-user footicon ${pathname === '/myprofile' ? '  text-white ' : 'text-dark'} `} />
                                        </div>
                                        Profile
                                    </Link>
                                    :
                                    <Link to={'/login'} onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} className='text-decoration-none text-dark  d-flex flex-column'>
                                        <div className={` ${pathname === '/login' ? ' bg-danger rounded-pill' : ''} `}>
                                            <i className={`ti ti-user footicon ${pathname === '/login' ? '  text-white ' : 'text-dark'} `} />
                                        </div>
                                        Profile
                                    </Link>

                                }
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div id="bottom_navigation_bar" className='footmobile overflow-hidden fixed-bottom bg-light '>
                <div className="row g-4 mt-1 ">
                    <div className="col-12 mt-0">
                        <ul className='list-inline d-flex justify-content-around text-white mb-0'>
                            <li className='list-inline-item  bottom_navigation_icons'>
                                <NavLink to='/' className='text-decoration-none'>
                                    <div className="d-flex flex-column align-items-center">
                                        <div><i className="ti ti-home footicon" />
                                            <i className="bi bi-house fs-1 text-white footicon"></i></div>
                                        <div className="foothome text-dark">Home</div>
                                    </div>
                                </NavLink>
                            </li>
                            <li className='list-inline-item bottom_navigation_icons'>
                                <div className="d-flex flex-column align-items-center" onClick={() => setShowNavbarOffCanvas(true)}>
                                    <div><i className="ti ti-category footicon" />  </div>
                                    <div className="foothome text-dark">Categories</div>
                                </div>
                            </li>
                        <li className='list-inline-item bottom_navigation_icons'>
                                {
                                    isAuthenticated ?
                                        <NavLink to='/myorders' className='text-decoration-none'>
                                            <div className="d-flex flex-column align-items-center">
                                                <div><i className="bi bi-box-seam fs-1 text-white footicon"></i></div>
                                                <div className="foothome text-dark">My Order</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <NavLink to='/login' className='text-decoration-none'>
                                            <div className="d-flex flex-column align-items-center">
                                                <div><i className="bi bi-box-seam fs-1 text-white footicon"></i></div>
                                                <div className="foothome text-dark">My Order</div>
                                            </div>
                                        </NavLink>

                                }

                            </li> 
                            <li className='list-inline-item bottom_navigation_icons'>
                                <NavLink to='/cart' className='text-decoration-none'>
                                    <div className="d-flex flex-column align-items-center">
                                        <div><i className="ti ti-shopping-cart footicon"></i></div>
                                        <div className="foothome text-dark">My Cart</div>
                                    </div>
                                </NavLink>
                                {/* <Link to="/cart">
                                    <div className="d-flex flex-column align-items-center">
                                        <div className={cartLength > 0 ? 'cart-animation' : ''}>
                                            <div className={cartLength > 0 ? 'cart-icon' : ''} data-totalitems={cartLength}>
                                                <i className="bi bi-cart headicon"></i>
                                            </div>
                                        </div>
                                        <div className="login">Cart</div>
                                    </div>
                                </Link> 
                            </li>
                            <li className='list-inline-item bottom_navigation_icons'>
                                {
                                    isAuthenticated ?
                                        <NavLink to='/myprofile' className='text-decoration-none'>
                                            <div className="d-flex flex-column align-items-center">
                                                <div>
                                                <i className="ti ti-user footicon" />
                                                </div>
                                                <div className="foothome text-dark">Profile</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <NavLink to='/login' className='text-decoration-none'>
                                            <div className="d-flex flex-column align-items-center">
                                                <div><i className="bi bi-person fs-1 text-white footicon"></i></div>
                                                <div className="foothome text-dark">Profile</div>
                                            </div>
                                        </NavLink>

                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default BottomNavigationBar
import React, { useEffect } from 'react'
import OrderItem from '../components/OrderItem'
import Header from '../layout/Header'
import { Link } from 'react-router-dom'
import { useOrderContext } from '../contexts/OrderContext'

const MyOrders = () => {
   const {orders, getMyOrders} = useOrderContext();
   
   useEffect(() => {
      getMyOrders();
   }, []);

   return (
      <>
         <Header />
         <main id='main' className='mt-5 pt-5'>
            {/* {JSON.stringify(orders)} */}
            <section id="my-orders" className="">
               <div className="container-fluid">
                  <div className='mt-2'>
                     <Link to="/myprofile" className='text-decoration-none text-danger'>My Profile</Link>
                     <span> <i className="ti ti-chevron-right"></i> My Orders</span>
                  </div>
                  <div className='mt-4'>
                     <div className="row">
                        <div>
                           {
                              // !orders ?
                                 <div className="col-md-9">
                                    <div className='py-4'>
                                       <div className="input-group mb-4">
                                          <input type="text" className="form-control" aria-label="Dollar amount (with dot and two decimal places)" placeholder="Search your orders here" />
                                          <button className="input-group-text bg-pink text-white"><i className="bi bi-search me-2"></i>Search Oders</button>
                                       </div>


                                       <div className="my-order-products">
                                          {
                                             orders.map((item, i) => {
                                                const { invoice_number, order_total, grand_total, order_status, order_time,tracking_status } = item;
                                                return (
                                                   <OrderItem
                                                      key={i}
                                                      srno={i + 1}
                                                      invoice_number={invoice_number}
                                                      grand_total={grand_total}
                                                      order_status={order_status}
                                                      order_date={order_time}
                                                      tracking_status={tracking_status}
                                                   />
                                                )
                                             })
                                          }
                                       </div>
                                    </div>
                                 </div>
                                 // :
                                 // <div className="col-md-9">
                                 //    <div className='text-center'>
                                 //       <img src={require('../assets/images/no_orders.png')} height={400} alt="" />
                                 //       <p className='h3 text-danger'>No Order Found</p>
                                 //       <p className='h5'>Looks Like You Haven't made Your Order Yet</p>
                                 //       <Link to={'/marketplace'} className='btn btn-pink py-2 px-4'>Order Now</Link>
                                 //    </div>
                                 // </div>
                           }
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </main>
         {/* <Footer/> */}
      </>
   )
}

export default MyOrders
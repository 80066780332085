import React from 'react';

export const CustomPrevArrow = ({ className, onClick }) => {
    return (
        <div className={`${className} custom-arrow prev-arrow`} onClick={onClick}>
            <span>&lt;</span> {/* You can replace this with an icon */}
        </div>
    );
};

export const CustomNextArrow = ({ className, onClick }) => {
    return (
        <div className={`${className} custom-arrow next-arrow`} onClick={onClick}>
            <span>&gt;</span> {/* You can replace this with an icon */}
        </div>
    );
};